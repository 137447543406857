import { NavItem } from '../../../../../components/navigation/dashboard-route.type';
import WorkspaceSubscription from './workspace-subscription';

export const planRoute: NavItem = {
  title: 'Plan',
  route: 'plan',
  component: WorkspaceSubscription,
  showInNavMenu: ['workspace-settings-nav'],
  featureFlag: 'navPlan',
};
