import { NavItem } from '../../../../../components/navigation/dashboard-route.type';
import WorkspaceInvites from './workspace-invites';

export const inviteSettingsRoute: NavItem = {
  title: 'Invites',
  route: 'invites',
  component: WorkspaceInvites,
  showInNavMenu: ['workspace-settings-nav'],
  icon: 'send',
};
