import { ObColorType } from '../../../tokens/colors/colors';
import { IconSystemKeys } from '../../../tokens/icons/icons';

export const ChangeStatusValues = [
  'unpublished',
  'rejected',
  'ready-to-launch',
  'publishing-blocked-due-to-issues',
  'publishing-blocked-due-ai-generation',
] as const;

export type ChangeStatusType = (typeof ChangeStatusValues)[number];

export interface ChangeManagementBarContent {
  title: string;
  subtitle: string;
  icon: IconSystemKeys;
  backgroundColor: string;
  iconColor: ObColorType;
  primaryButtonText: string;
  secondaryButtonText?: string;
}
export const changeManagementBarContent: Record<
  ChangeStatusType,
  ChangeManagementBarContent
> = {
  'publishing-blocked-due-to-issues': {
    title: 'Your campaign has issues that need attention',
    subtitle: 'Resolve the issues in order to publish your campaign',
    icon: 'alertTriangle',
    iconColor: 'warning',
    backgroundColor: '#C46520',
    primaryButtonText: 'View Issues',
  },
  'publishing-blocked-due-ai-generation': {
    title: 'Your campaign is being generated',
    subtitle: 'Please wait while your creatives are being generated',
    icon: 'magicWand',
    iconColor: 'cobalt',
    backgroundColor: '#C46520',
    primaryButtonText: 'View Creative',
  },
  unpublished: {
    title: 'Your campaign has unpublished changes',
    subtitle:
      'Once you are happy with your changes submit them and we will get them published!',
    icon: 'suggestion',
    backgroundColor: '#054A70',
    iconColor: 'cobalt',
    primaryButtonText: 'Publish',
  },
  rejected: {
    title: 'Campaign Deployment Failed',
    subtitle: 'There are issues with your campaign that must be resolved',
    icon: 'alertTriangle',
    backgroundColor: '#C46520',
    iconColor: 'warning',
    primaryButtonText: 'View Issues',
  },
  'ready-to-launch': {
    title: 'Your campaign is ready to be published',
    subtitle: 'Publish your campaign to Google today!',
    icon: 'nominal',
    backgroundColor: '#1BA37B',
    iconColor: 'positive',
    primaryButtonText: 'Publish',
  },
};
