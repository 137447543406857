import {
  CampaignChannel,
  CampaignPausedByUserState,
  CampaignServingState,
  CampaignServingStateReason,
  OutboundCampaignGoal,
} from '@outbound/types';
import { cx } from 'class-variance-authority';
import { ReactNode } from 'react';
import { ObChannelAvatar } from '../../avatars';
import { ObCardSurface } from '../../cards/components/card-surface/ob-card-surface';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { CardVariantProps } from '../card.type';
import { ObGoalAvatar } from './components/campaign-goal/campaign-goal';
import { ServingStateBadge } from './components/campaign-status-badge';
import { CampaignIntegrationBadge } from './components/channel-integration-status-badge';

export interface CampaignCardCtaProps {
  onNavigateCallback: (campaignObrn: string) => void;
  onSaveNameClickedCallback?: (newName: string) => void;
}
export interface CampaignCardProps
  extends CardVariantProps,
    CampaignCardCtaProps {
  obrn: string;
  name: string;
  channel: CampaignChannel;
  pausedByUserState: CampaignPausedByUserState;
  servingState: CampaignServingState;
  servingStateReason: CampaignServingStateReason;
  servingStateLastCalculatedAtTimestamp: string;
  goal: OutboundCampaignGoal;
  integrationXConfigurationSlot?: ReactNode;
  isLaunched?: boolean;
}

type CampaignCardImplementationProps = Omit<CampaignCardProps, 'variant'>;

export const CampaignCard = ({ variant, ...props }: CampaignCardProps) => {
  switch (variant) {
    case 'grid':
      return <GridCard {...props} />;
    case 'table':
      return <TableCard {...props} />;
    case 'micro':
    case 'mini':
      return <ObTypography color='negative'>Not Implemented</ObTypography>;
  }
};

const TableCard = ({
  obrn,
  channel,
  name,
  goal,
  integrationXConfigurationSlot,
  servingState,
  servingStateReason,
  servingStateLastCalculatedAtTimestamp,
  onNavigateCallback,
}: CampaignCardImplementationProps) => {
  return (
    <tr
      className='group rounded-lg hover:cursor-pointer bg-dark/background/surface focus-visible:shadow-interactive focus:outline-none'
      tabIndex={0}
      onClick={() => onNavigateCallback(obrn)}
      role={'button'}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onNavigateCallback(obrn);
        }
      }}
    >
      <td className='py-2 px-4 border-l  border-t border-b border-transparent group-hover:dark:border-dark/action/primary-v2/normal rounded-l-lg bg-dark/background/surface transition duration-300 w-1/3'>
        <div className='flex flex-1 flex-row gap-2 items-start'>
          <div className='pt-2'>
            <ObChannelAvatar
              channel={channel}
              size={'medium'}
            />
          </div>
          <div>
            <ObTypography
              variant='h3'
              className='line-clamp-2'
            >
              {name}
            </ObTypography>
            <ObGoalAvatar goal={goal} />
          </div>
        </div>
      </td>
      <td className='py-2 px-4 border-t border-b border-transparent group-hover:dark:border-dark/action/primary-v2/normal  transition duration-300'>
        <ServingStateBadge
          servingState={servingState}
          servingStateReason={servingStateReason}
          servingStateLastCalculatedAtTimestamp={
            servingStateLastCalculatedAtTimestamp
          }
        />
      </td>
      <td className='py-2 px-4 border-r border-t border-b border-transparent group-hover:dark:border-dark/action/primary-v2/normal transition duration-300 rounded-r-lg'>
        <CampaignIntegrationBadge
          integrationXConfigurationSlot={integrationXConfigurationSlot}
        />
      </td>
      {/* <td className='py-2 px-4 border-r border-t border-b  border-transparent group-hover:dark:border-dark/action/primary-v2/normal rounded-r-lg transition duration-300'>
        <ObCxStatus
          status={isLaunched ? 'nominal' : 'customer-action-required'}
          size={'medium'}
        />
      </td> */}
    </tr>
  );
};

const GridCard = ({
  obrn,
  name,
  channel,
  servingState,
  servingStateReason,
  servingStateLastCalculatedAtTimestamp,
  goal,
  integrationXConfigurationSlot,
  onNavigateCallback,
}: CampaignCardImplementationProps) => {
  return (
    <ObCardSurface isClickable={true}>
      <div
        className='min-h-[180px] hover:cursor-pointer focus-visible:shadow-interactive focus:outline-none flex flex-col justify-between'
        tabIndex={0}
        onClick={() => onNavigateCallback(obrn)}
        role={'button'}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onNavigateCallback(obrn);
          }
        }}
      >
        <div
          className={cx(
            'flex flex-row gap-2 p-3 justify-between items-stretch flex-1'
          )}
        >
          <div className=' flex flex-col justify-between flex-1'>
            <div className='flex flex-row justify-between'>
              <div className='flex flex-row gap-2 items-center'>
                <ObChannelAvatar
                  channel={channel}
                  size={'medium'}
                />
                <div>
                  <ObTypography
                    variant='h3'
                    className='line-clamp-2'
                  >
                    {name}
                  </ObTypography>
                  <ObGoalAvatar goal={goal} />
                </div>
              </div>
            </div>
            <div className='flex flex-col gap-2'>
              <ServingStateBadge
                servingState={servingState}
                servingStateReason={servingStateReason}
                servingStateLastCalculatedAtTimestamp={
                  servingStateLastCalculatedAtTimestamp
                }
              />
              <CampaignIntegrationBadge
                integrationXConfigurationSlot={integrationXConfigurationSlot}
              />
            </div>
          </div>
        </div>
      </div>
    </ObCardSurface>
  );
};
