import { ObDivider } from '@outbound/design-system';
import { cva, cx } from 'class-variance-authority';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { useWindowDimensions } from '../../../../hooks/use-window-dimensions';
import {
  filterOutUnreleasedNavItems,
  getRoutesForNavMenuLocation,
} from '../../../../navigation/navigation-utils';
import { dashboardRoute } from '../../../../pages/dashboard/routes';
import { DrawerBottom } from '../drawer-bottom/drawer-bottom';
import { DrawerItemList } from '../drawer-item-list/drawer-item-list';
import { MainDrawerHeader } from '../main-drawer-header/main-drawer-header';
import { DrawerProps } from '../types';

interface MainDrawerProps extends DrawerProps {
  isSubDrawerShown: boolean;
}

export const MainDrawer: FC<MainDrawerProps> = ({
  isDrawerExpanded,
  toggleDrawerExpansion,
}) => {
  const flags = useFlags();
  const { isSmallOrLargerViewport: isDesktopView } = useWindowDimensions();
  const styles = cva('transition-all duration-300 ease-in-out opacity-100', {
    variants: {
      isDesktopView: {
        // DESKTOP DRAWER COLLAPSED WIDTH: 64px;
        // DESKTOP DRAWER EXPANDED WIDTH = 260px;
        // MOBILE DRAWER COLLAPSED HEIGHT: 65px;
        true: cx(
          'h-screen',
          isDrawerExpanded
            ? 'w-[260px] min-w-[260px] max-w-[260px]'
            : 'w-[64px] min-w-[64px] max-w-[64px]'
        ),
        false: cx(
          'w-screen',
          isDrawerExpanded
            ? 'h-screen min-h-screen max-h-screen'
            : 'h-[65px] min-h-[65px] max-h-[65px]'
        ),
      },
    },
  });

  const mainNavItems = getRoutesForNavMenuLocation(
    dashboardRoute,
    'workspace-side-nav'
  );

  return (
    <div
      data-testid='main-navigation-drawer'
      className={cx(
        'flex flex-col justify-between content-center bg-light/background/default dark:bg-dark/background/default dark:border-dark/border/default/normal border-r border-light/border/default/normal border-opacity-[0.16] overflow-hidden',
        styles({ isDesktopView })
      )}
    >
      <div>
        <MainDrawerHeader
          isDrawerExpanded={isDrawerExpanded}
          toggleDrawerExpansion={toggleDrawerExpansion}
        />
        <ObDivider />
        <DrawerItemList
          navigationItems={filterOutUnreleasedNavItems(mainNavItems, flags)}
          isDrawerExpanded={isDrawerExpanded}
          onItemClick={(e) => {
            if (!isDesktopView) {
              /**
               * Since the menu is closed when we click on the item, it should no longer have focus.
               * This was added to remove a blu border around the item when it is clicked.
               */
              e.currentTarget.blur();
              if (isDrawerExpanded) {
                toggleDrawerExpansion();
              }
            }
          }}
        />
      </div>
      <DrawerBottom
        isDrawerExpanded={isDrawerExpanded}
        toggleDrawerExpansion={toggleDrawerExpansion}
      />
    </div>
  );
};
