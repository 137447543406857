import { FormDefinition, FormFieldType } from '@outbound/design-system';

export type WorkspaceMemberInviteFormDataSchema = {
  inviteByEmail: string[];
};

export const WORKSPACE_MEMBER_INVITE_FORM_DEF: FormDefinition = {
  id: 'invite-member-form',
  heading: '',
  sections: [
    {
      id: 'inviteMembersSection',
      heading: '',
      subHeading: '',
      fields: [
        {
          id: 'inviteByEmail',
          label: 'Invite Owners By Email',
          helperText: 'Enter up to five emails separated by a comma',
          type: FormFieldType.TEXT_LIST_EMAIL,
          validationSchema: {
            isRequired: true,
            min: 1,
            max: 5,
          },
          fieldTypeSettings: {},
        },
      ],
    },
  ],
  subHeading: '',
};
