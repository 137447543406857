/* solid/general/calendar-filled */
export const CalendarFilled = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 17 16'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 -0.25C11.4142 -0.25 11.75 0.0857864 11.75 0.5V1.25122C12.1066 1.25367 12.4183 1.26104 12.6889 1.28315C13.1104 1.31759 13.498 1.39151 13.862 1.57698C14.4265 1.8646 14.8854 2.32354 15.173 2.88803C15.3585 3.25203 15.4324 3.63956 15.4669 4.06113C15.5 4.46702 15.5 4.96529 15.5 5.56901V11.931C15.5 12.5347 15.5 13.033 15.4669 13.4389C15.4324 13.8604 15.3585 14.248 15.173 14.612C14.8854 15.1765 14.4265 15.6354 13.862 15.923C13.498 16.1085 13.1104 16.1824 12.6889 16.2169C12.283 16.25 11.7847 16.25 11.181 16.25H4.81901C4.21529 16.25 3.71702 16.25 3.31113 16.2169C2.88956 16.1824 2.50203 16.1085 2.13803 15.923C1.57354 15.6354 1.1146 15.1765 0.826981 14.612C0.641513 14.248 0.56759 13.8604 0.533146 13.4389C0.499983 13.033 0.499991 12.5347 0.5 11.931V5.56903C0.499991 4.9653 0.499983 4.46702 0.533146 4.06113C0.56759 3.63956 0.641513 3.25203 0.826981 2.88803C1.1146 2.32354 1.57354 1.8646 2.13803 1.57698C2.50203 1.39151 2.88956 1.31759 3.31113 1.28315C3.58174 1.26104 3.8934 1.25367 4.25 1.25122V0.5C4.25 0.0857864 4.58579 -0.25 5 -0.25C5.41421 -0.25 5.75 0.0857864 5.75 0.5V1.25H10.25V0.5C10.25 0.0857864 10.5858 -0.25 11 -0.25ZM4.25 2.75132V3.5C4.25 3.91421 4.58579 4.25 5 4.25C5.41421 4.25 5.75 3.91421 5.75 3.5V2.75H10.25V3.5C10.25 3.91421 10.5858 4.25 11 4.25C11.4142 4.25 11.75 3.91421 11.75 3.5V2.75132C12.0841 2.75367 12.3465 2.76017 12.5667 2.77816C12.8955 2.80503 13.0637 2.85372 13.181 2.91349C13.4632 3.0573 13.6927 3.28677 13.8365 3.56902C13.8963 3.68631 13.945 3.85447 13.9718 4.18328C13.9994 4.52085 14 4.95757 14 5.6V5.75H2V5.6C2 4.95757 2.00058 4.52085 2.02816 4.18328C2.05503 3.85447 2.10372 3.68631 2.16349 3.56902C2.3073 3.28677 2.53677 3.0573 2.81902 2.91349C2.93632 2.85372 3.10447 2.80503 3.43328 2.77816C3.65347 2.76017 3.91585 2.75367 4.25 2.75132Z'
        fill='currentColor'
      />
    </svg>
  );
};
