import {
  CampaignCard,
  CampaignCardProps,
  ObTypography,
} from '@outbound/design-system';

import { CardVariant } from '@outbound/design-system/src/business-objects/card.type';
import { observer } from 'mobx-react-lite';
import { useAppNavigation } from '../../hooks/use-app-navigation';
import Campaign from '../../state/mobx-experiment/campaign/campaign';
import { IntegrationXConfigurationModelCard } from '../integration-x-configuration/integration-x-configuration';

export interface CampaignCardObserverProps {
  variant: CardVariant;
  campaign: Campaign | null;
  overrideProps?: Partial<CampaignCardProps>;
}

/**
 * Used on the Web application to render a Campaign Card from a Mobx Campaign Object.
 */
export const CampaignCardObserver = observer(
  ({ variant, campaign, overrideProps = {} }: CampaignCardObserverProps) => {
    const { navigateToCampaignByObrn } = useAppNavigation();

    /**
     * If a campaign is deleted or not found, render a message to the user.
     * This should most likely be filtered out by the parent component but this is a safety net.
     */
    if (campaign == null) {
      return (
        <ObTypography
          variant='body3'
          color='secondary'
        >
          Campaign Not Found
        </ObTypography>
      );
    }

    return (
      <CampaignCard
        obrn={campaign.obrn}
        name={campaign.name}
        channel={campaign.channel}
        pausedByUserState={campaign.pausedByUserState}
        servingState={campaign.servingState}
        servingStateReason={campaign.servingStateReason}
        servingStateLastCalculatedAtTimestamp={
          campaign.servingStateLastCalculatedAtTimestamp
        }
        goal={campaign.goal}
        variant={variant}
        onNavigateCallback={(obrn: string) => {
          if (overrideProps?.onNavigateCallback) {
            overrideProps.onNavigateCallback(obrn);
          } else {
            navigateToCampaignByObrn(obrn);
          }
        }}
        integrationXConfigurationSlot={
          <IntegrationXConfigurationModelCard
            integrationXWorkspace={campaign.primaryIntegrationConfiguration!}
          />
        }
      />
    );
  }
);
