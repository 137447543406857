import { useEffect, useRef } from 'react';
import {
  ObAiGeneratingLoadingIndicator,
  ObButton,
  ObCreativePlayerV2,
} from '../..';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { CreativeSelector } from './components/creative-selector/creative-selector';
import {
  CreativePreviewSideBar,
  CreativePreviewSideBarProps,
} from './components/side-bar/side-bar';

export interface CreativePreviewDialogProps
  extends CreativePreviewSideBarProps {
  onEditClicked: (creativeId: string) => void;
  onApproveClicked: (creativeId: string) => void;
  onCloseDialogClicked: () => void;
  setSelectedCreativeId: (creativeId: string | null) => void;
}

/**
 * Creative Dialog Preview
 * @param param0
 * @returns
 */
export const CreativePreviewDialog: React.FC<CreativePreviewDialogProps> = ({
  creatives,
  selectedCreativeId,
  onEditClicked,
  onCloseDialogClicked,
  setSelectedCreativeId,
}: CreativePreviewDialogProps) => {
  const activeCreative = creatives.find((c) => c.id === selectedCreativeId);

  const creativeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = creativeRef.current;
    if (!el) return;

    // Remove transform to get the “natural” width
    el.style.transform = 'none';

    // If viewport is >= 420, do nothing extra
    if (window.innerWidth >= 750) return;

    // Measure the content’s natural width
    const { width: contentWidth } = el.getBoundingClientRect();
    if (contentWidth > 0) {
      // Scale factor to fit viewport exactly in width dimension
      const scaleFactor = window.innerWidth / contentWidth;

      // Apply the transform
      el.style.transformOrigin = '50% 50%';
      el.style.transform = `scale(${scaleFactor})`;
    }
  }, [selectedCreativeId]);

  return (
    <div className='flex flex-col md:flex-row h-[98vh] self-stretch dark:bg-dark/background/default rounded-xl shadow-2xl w-screen '>
      <div className='hidden md:flex h-full max-w-[440px]'>
        <CreativePreviewSideBar
          creatives={creatives}
          selectedCreativeId={selectedCreativeId}
        />
      </div>
      <div className='flex p-6 flex-col flex-1 self-stretch '>
        <div className='flex flex-row self-stretch justify-between items-center'>
          <ObTypography variant='h3'>
            {creatives?.find((c) => c.id === selectedCreativeId)?.name ??
              'Please Select Creative'}
          </ObTypography>
          <ObButton
            onClick={onCloseDialogClicked}
            iconLeft='close'
            label='Close Creative Preview'
            variant='ghost'
            buttonType='icon'
            size='xlarge'
          />
        </div>
        <div className='flex flex-1 justify-center items-center'>
          {(activeCreative &&
            (activeCreative.lifecycleStatus === 'ACTIVE' ? (
              <div ref={creativeRef}>
                <ObCreativePlayerV2
                  template={activeCreative.template}
                  settings={activeCreative.settings}
                  isLoading={false}
                  mobileView={true}
                  estimatedPercentComplete={100}
                />
              </div>
            ) : (
              <div className='flex flex-col justify-center items-center gap-4'>
                <ObAiGeneratingLoadingIndicator
                  variant={'spinner'}
                  size={120}
                />
                <ObTypography
                  variant='body2'
                  color='secondary'
                >
                  AI Creative Generation In Progress. This make a few minutes.
                </ObTypography>
              </div>
            ))) ??
            'Please Select Creative to View Preview'}
        </div>
        <div className='flex flex-row justify-center items-center gap-3'>
          {/* TEMPORARILY REMOVED TILL WE CAN IMPLEMENT THIS FEATURE */}
          {/* <ObButton
            label={
              activeCreative?.isReviewed ? 'Remove Review' : 'Mark as Reviewed'
            }
            disabled={!selectedCreativeId}
            size='medium'
            iconLeft='checkCircle'
            onClick={() => onApproveClicked(selectedCreativeId ?? '')}
          /> */}
          <ObButton
            label='Edit'
            disabled={!selectedCreativeId}
            iconLeft='pencil01'
            variant='outline'
            size='medium'
            onClick={() => {
              onEditClicked(selectedCreativeId ?? '');
            }}
          />
        </div>
      </div>
      <CreativeSelector
        creatives={creatives}
        selectedCreativeId={selectedCreativeId}
        onCreativeSelected={(creativeId) => {
          console.log('Creative ID Selected', creativeId);
          if (creativeId) {
            setSelectedCreativeId(creativeId);
          }
        }}
      />
    </div>
  );
};
