import {
  FullPageActionHeader,
  ObBrand,
  ObFormRenderer,
  ObLoadingSpinner,
  ObTypography,
} from '@outbound/design-system';
import { PostWorkspaceRequestResource } from '@outbound/types';
import { useMemo, useState } from 'react';
import { useAuth0Extended } from '../../auth/use-auth0-extended';
import { LinkTermsOfService } from '../../components/elements/link-customer-terms-of-service';
import { LinkPrivacyPolicy } from '../../components/elements/link-privacy-policy';
import { createWorkspaceForm } from '../../components/forms/workspace-creation-form';
import LegalAgreementCheckbox from '../../components/organisms/legal-agreement-checkbox/legal-agreement-checkbox';
import { useCreateWorkspaceForUserMutation } from '../../query/workspace/use-workspace-endpoints';

export const SetupWorkspacePage = () => {
  const heading = 'Setup your workspace';
  const subHeading = undefined;
  const { isAuthenticated, isLoading, switchOrganization } = useAuth0Extended();

  const [userTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/New_York'
  );

  /**
   * Tracks if the checkbox is checked or not
   */
  const [isChecked, setIsChecked] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
    setErrorMessage('');
  };

  const { mutateAsync } = useCreateWorkspaceForUserMutation();

  const submitNewWorkspaceForm = async (
    values: PostWorkspaceRequestResource
  ) => {
    setSubmitting(true);
    if (!isChecked) {
      setErrorMessage(
        `You must agree to the Terms Of Service and Privacy Policy in order to use Outbound.com's services.`
      );
      setSubmitting(false);
      throw new Error(
        `You must agree to the Terms Of Service and Privacy Policy in order to use Outbound.com's services.`
      );
    }
    const workspace = await mutateAsync({ ...values, timezone: userTimezone });
    if (isAuthenticated) {
      /**
       * Since we are authenticated we don't need to confirm the email address so we will redirect them
       */
      await switchOrganization(workspace.auth0OrganizationId, workspace.id);
    }
    return workspace;
  };

  const workspaceForm = useMemo(
    () => createWorkspaceForm(isAuthenticated),
    [isAuthenticated]
  );

  if (isLoading) {
    return (
      <div className='h-screen flex items-center w-full justify-center'>
        <ObLoadingSpinner includeBrand={true} />
      </div>
    );
  }
  return (
    <div className='flex flex-col h-screen justify-between dark:bg-black'>
      <header className='flex justify-center items-center pt-8'>
        <ObBrand
          variant='icon'
          size='medium'
        />
      </header>

      <main className='flex flex-col justify-center items-center flex-grow w-full'>
        <FullPageActionHeader
          heading={heading}
          subHeading={subHeading}
        />
        <div className='min-w-[400px]'>
          <ObFormRenderer
            formDefinition={workspaceForm}
            onSubmitCallback={submitNewWorkspaceForm}
          />
          <LegalAgreementCheckbox
            checked={isChecked}
            onChange={toggleCheckbox}
            label={'I agree'}
            disabled={submitting}
            errorMessage={''}
            labelNode={
              <ObTypography
                variant='body2'
                color='secondary'
              >
                By checking this box I agree to Outbound Technologies, Inc.'s{' '}
                <LinkTermsOfService /> and <LinkPrivacyPolicy /> on behalf of
                myself and on behalf of the owner of the website entered above.
              </ObTypography>
            }
          />
          <div className='min-h-[36px]'>
            {errorMessage && (
              <ObTypography
                color='negative'
                variant='body2'
              >
                {errorMessage}
              </ObTypography>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};
