/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CreativePreviewDialog,
  useDialogService,
} from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useAppNavigation } from '../../../../hooks/use-app-navigation';
import { useRootStore } from '../../../../state/mobx-experiment/use-root-store';

type OpenCreativeContext = {
  openToCreativeId: string;
};

/**
 * Hook that exposes the ability to open a lightbox for a campaign creative.
 */
export const useCampaignCreativeLightbox = (campaignId: string) => {
  const { openDialog } = useDialogService();

  /**
   * Opens the campaign creative lightbox and optionally opens to a specific slot.
   * If no slot is provided, the lightbox will open to the creative first slot in the list
   */
  const openCampaignCreativeLightbox = (
    openCreativeContext?: OpenCreativeContext
  ) => {
    openDialog({
      title: 'Campaign Creative',
      lightbox: true,
      dialogContent: (
        <CreativeDialogWrapper
          campaignId={campaignId}
          openToCreativeId={openCreativeContext?.openToCreativeId}
        />
      ),
    });
  };
  return {
    openCampaignCreativeLightbox,
  };
};

interface CreativeDialogWrapperProps {
  campaignId: string;
  openToCreativeId?: string;
}

interface CampaignCreativeSlotPreview {
  slotId: string;
  label: string;
  isReviewed: boolean;
  creative: any;
  creativePreviewContextOptions: any[];
}

export const CreativeDialogWrapper = observer(
  ({ campaignId, openToCreativeId }: CreativeDialogWrapperProps) => {
    const { popDialog } = useDialogService();
    const { navigateToCreativeBuilder } = useAppNavigation();

    const { campaignStore } = useRootStore();

    /**
     * Currently the creative lightbox is hard-coded to only show creatives for a single campaign
     * If for some reason this changes in the future we will need to rework this.
     */
    const campaign = campaignStore.getById(campaignId!);

    const [selectedCreativeId, setSelectedCreativeId] = useState<string | null>(
      openToCreativeId ?? null
    );

    return (
      <CreativePreviewDialog
        onCloseDialogClicked={() => {
          popDialog();
        }}
        onEditClicked={(creativeId: string) => {
          navigateToCreativeBuilder(creativeId);
          popDialog();
        }}
        onApproveClicked={(creativeId: string) => {
          const creativeToReview = campaign?.allCreatives.find(
            (c) => c.id === creativeId
          );
          if (creativeToReview) {
            creativeToReview.isReviewed = !creativeToReview.isReviewed;
          }
        }}
        selectedCreativeId={selectedCreativeId}
        setSelectedCreativeId={setSelectedCreativeId}
        creatives={
          campaign?.allCreatives?.map((c) => {
            return {
              id: c.id,
              name: c.name,
              lifecycleStatus: c.lifecycleStatus,
              medium: c.medium,
              isValid: c.isValid,
              isReviewed: c.isReviewed,
              validationErrors: c.validationErrors,
              template: c.template,
              settings: c.settings,
              onViewSpecificErrorCallback: (erroredSettingId: string) => {
                navigateToCreativeBuilder(c.id, {
                  settingIdToFocus: erroredSettingId,
                });
              },
              onNavigateToEditorCallback: (id: string) => {
                navigateToCreativeBuilder(id);
                popDialog();
              },
              onNavigateToPreviewCallback: () => {
                setSelectedCreativeId(c.id);
              },
            };
          }) ?? []
        }
      />
    );
  }
);

export interface CampaignCreativeLightboxDialogProps {
  campaignId: string;
}
