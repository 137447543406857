import { ObButton } from '@outbound/design-system';
import { PlaybookPageContent } from '../_common/playbook-page-content';
import { PlaybookPageHeading } from '../_common/playbook-page-heading';
import { CUSTOMER_PERSONAS_PAGE_DEF } from './customer-personas.def';
import { useCustomerPersonaCreateDrawer } from './drawer/customer-persona-create-drawer';
import { CustomerPersonasSection } from './sections/customer-personas-section';

export const CustomerPersonas = () => {
  const { openNewCustomerPersonaDrawer } = useCustomerPersonaCreateDrawer();
  return (
    <article className='flex flex-col gap-8 flex-grow '>
      <PlaybookPageHeading
        title={CUSTOMER_PERSONAS_PAGE_DEF.title}
        controls={
          <ObButton
            size='medium'
            variant='outline'
            buttonType='text'
            label='New Customer Profile'
            onClick={openNewCustomerPersonaDrawer}
          />
        }
      />
      <PlaybookPageContent>
        <CustomerPersonasSection />
      </PlaybookPageContent>
    </article>
  );
};
