import { ObButton, ObTypography } from '@outbound/design-system';
import { FC } from 'react';
import { useInviteForm } from './use-invite-form';
import { WorkspaceInvitesDataGrid } from './workspace-invites-data-grid';

const WorkspaceInvites: FC<{}> = () => {
  const { openInviteFormDialog } = useInviteForm();

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-col gap-2'>
        <ObTypography variant='h2'>Invites</ObTypography>
        <ObTypography
          variant='body2'
          color='secondary'
        >
          Invite new team members to your workspace
        </ObTypography>
      </div>

      <div className='flex flex-col gap-8'>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-col justify-center'>
            <ObTypography variant='h4'>Pending Invites</ObTypography>
            <ObTypography
              variant='body2'
              color='secondary'
            >
              Manage your existing invites
            </ObTypography>
          </div>
          <ObButton
            variant='outline'
            size='medium'
            onClick={openInviteFormDialog}
            label='New Invite'
          />
        </div>
      </div>
      <WorkspaceInvitesDataGrid />
    </div>
  );
};

export default WorkspaceInvites;
