import { WorkspaceTaskResource } from '@outbound/types';
import { cx } from 'class-variance-authority';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useAuth0Extended } from '../../auth/use-auth0-extended';
import { BasePageLayout } from '../../components/layouts/base-page';
import { FullPageLoadingSpinner } from '../../components/layouts/full-page-loading-spinner';
import { ErrorFallback } from '../../components/organisms/error-fallback/error-fallback';
import { NavigationDrawer } from '../../components/organisms/navigation-drawer/navigation-drawer';
import { useWindowDimensions } from '../../hooks/use-window-dimensions';
import { router } from '../../main';
import { useFetchWorkspaceTasks } from '../../query/workspace/use-workspace-tasks-endpoints';

type RouteParams = {
  workspaceSlug: string;
};

const needsToResumeOnboardingTask = (task: WorkspaceTaskResource) => {
  return task.type === 'COMPLETE_ONBOARDING' && task.status === 'ASSIGNED';
};

const DashboardLayout = () => {
  const [
    isCompleteOnboardingTaskAssigned,
    setIsCompleteOnboardingTaskAssigned,
  ] = useState<boolean | null>(null);
  const { isSmallOrLargerViewport: isDesktopView } = useWindowDimensions();

  const { data: workspaceTasks } = useFetchWorkspaceTasks();

  const { workspaceSlug } = useParams<RouteParams>();

  const navigate = useNavigate();

  const { isAuthenticatedWithWorkspace, authenticatedWorkspaceId } =
    useAuth0Extended();

  useEffect(() => {
    // Avoid infinite spinner
    if (workspaceTasks?.items?.length === 0) {
      setIsCompleteOnboardingTaskAssigned(false);
    } else {
      // Process tasks data
      workspaceTasks?.items.forEach((task) => {
        if (needsToResumeOnboardingTask(task)) {
          setIsCompleteOnboardingTaskAssigned(true);
        }
      });
    }
  }, [workspaceTasks]);

  useEffect(() => {
    /**
     * This is here to handle a case where the workspace slug is set to the string "undefined"
     * This has been observed in prod and qa but cannot be reproduced. This is an attempt to catch
     * the issue and fix it if it occurs.
     */
    if (workspaceSlug === 'undefined' && isAuthenticatedWithWorkspace) {
      if (authenticatedWorkspaceId != null) {
        const originalPath = window.location.pathname;
        const correctedPath = window.location.pathname.replace(
          'undefined',
          authenticatedWorkspaceId
        );
        //Log for DD to investigate
        console.error(
          'Undefined is set as the workspace slug. Correcting to Authenticated Workspace ID',
          { correctedPath, originalPath }
        );
        navigate(correctedPath, { replace: true });
      }
      //Replace undefined with the authenticated workspace id
    }
  });

  if (isCompleteOnboardingTaskAssigned) {
    const resumeOnboardingRoute = '/onboarding/questionnaire';
    router.navigate(resumeOnboardingRoute, { replace: true });
  }

  if (workspaceSlug === 'undefined') {
    return <></>;
  }

  return (
    <BasePageLayout
      contentPosition='center-top'
      fullWidth
      background='scrollable-gradient-fixed'
      fullScreen
    >
      {isCompleteOnboardingTaskAssigned == null && (
        <FullPageLoadingSpinner reason='workspace-tasks-loading' />
      )}
      {!isCompleteOnboardingTaskAssigned && (
        <div
          data-testid='dashboard-page'
          className='flex flex-row dark min-h-screen w-full'
        >
          <NavigationDrawer />
          <main className={cx('flex grow  p-0', !isDesktopView && 'pt-[65px]')}>
            <div className='flex flex-col w-full '>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Outlet />
              </ErrorBoundary>
            </div>
          </main>
        </div>
      )}
    </BasePageLayout>
  );
};
export default DashboardLayout;
