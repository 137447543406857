import {
  CampaignCreateDrawer,
  useDrawerService,
} from '@outbound/design-system';
import {
  CampaignChannelType,
  CreateCampaignRequestResource,
} from '@outbound/types';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useSettings } from '../../../../contexts/settings-context';
import { useAppNavigation } from '../../../../hooks/use-app-navigation';
import { useFeatureUpSell } from '../../../../in-app-marketing/use-feature-up-sell';
import { useCreateCampaignMutation } from '../../../../query/campaigns/use-campaigns-endpoints';
import { useFetchPlaybookSettings } from '../../../../query/playbook/use-playbook-endpoints';
import { useRootStore } from '../../../../state/mobx-experiment/use-root-store';
import { useCustomerPersonaCreateDrawer } from '../../playbookv2/pages/customer-personas/drawer/customer-persona-create-drawer';
import { useBrickAndMortarCreateDrawer } from '../../playbookv2/pages/locations/drawer/brick-and-mortar-create-drawer';
import { useServiceAreaCreateDrawer } from '../../playbookv2/pages/locations/drawer/service-area-create-drawer';
import { useServiceDetailDrawer } from '../../playbookv2/pages/products-and-services/drawer/use-service-details-drawer';

export const useCreateCampaignDrawer = () => {
  const drawerService = useDrawerService();

  const openCreateCampaignDrawer = () => {
    return drawerService.pushDrawer({
      title: 'Create New Campaign',
      description:
        'Fill out the information below to create a new ad campaign.',
      drawerContent: <CreateCampaignDrawerWrapper variant='drawer' />,
      size: 'medium',
    });
  };
  return {
    openCreateCampaignDrawer,
  };
};

export interface CreateCampaignDrawerWrapperProps {
  variant: 'full-screen' | 'drawer';
}

/**
 * Loads the data required to populate the create campaign drawer
 */
export const CreateCampaignDrawerWrapper = observer(
  ({ variant = 'drawer' }: CreateCampaignDrawerWrapperProps) => {
    const {
      isPhysicalLocationsEnabled,
      isServiceAreasEnabled,
      isServicesEnabled,
    } = useSettings();
    const drawerService = useDrawerService();

    const { showFeatureUpSellDialog } = useFeatureUpSell();

    const { pathname } = useLocation();

    const { data, isLoading } = useFetchPlaybookSettings();

    const { locationStore } = useRootStore();

    const physicalLocations = locationStore.getPhysicalLocations();

    const serviceAreas = locationStore.getServiceAreas();

    const { mutateAsync } = useCreateCampaignMutation();

    const { openNewCustomerPersonaDrawer } = useCustomerPersonaCreateDrawer();
    const { openNewBrickAndMortarLocationDrawer } =
      useBrickAndMortarCreateDrawer();
    const { openNewLocationDrawer } = useServiceAreaCreateDrawer();
    const { openCreateNewServiceDrawer } = useServiceDetailDrawer();

    const { navigateToCampaignById } = useAppNavigation();

    if (isLoading) {
      return <></>;
    }

    return (
      <CampaignCreateDrawer
        variant={variant}
        onAddNewCustomerProfileClickedCallback={openNewCustomerPersonaDrawer}
        onAddNewPhysicalLocationClickedCallback={
          openNewBrickAndMortarLocationDrawer
        }
        onAddNewServiceAreaClickedCallback={openNewLocationDrawer}
        onAddNewServiceClickedCallback={openCreateNewServiceDrawer}
        availableChannels={[
          CampaignChannelType.GOOGLE_SEARCH_ADS,
          CampaignChannelType.GOOGLE_DISPLAY_ADS,
          CampaignChannelType.FACEBOOK_ADS,
          CampaignChannelType.INSTAGRAM_ADS,
        ]}
        availableCustomerProfiles={data?.personas ?? []}
        availablePhysicalLocations={(physicalLocations as any) ?? []}
        availableServiceAreas={(serviceAreas as any) ?? []}
        availableServices={data?.services ?? []}
        onCreateCampaignCallback={async (formSubmission) => {
          const createCampaignRequest: CreateCampaignRequestResource = {
            name: formSubmission.campaignName,
            outboundCampaignGoal: formSubmission.campaignGoal,
            channelType: formSubmission.channel as CampaignChannelType,
            personaIds: [formSubmission.personaId],
            serviceIds: formSubmission.serviceId
              ? [formSubmission.serviceId]
              : [],
            physicalLocationIds: formSubmission.physicalLocationIds,
            serviceAreaIds: formSubmission.serviceAreaIds,
          };

          /**
           * Check for Enterprise only features and show the up-sell dialog otherwise create the campaign
           */
          switch (createCampaignRequest.channelType) {
            case CampaignChannelType.GOOGLE_DISPLAY_ADS: {
              await showFeatureUpSellDialog('GoogleDisplayAdManagement');
              return Promise.reject();
            }
            case CampaignChannelType.FACEBOOK_ADS: {
              await showFeatureUpSellDialog('FacebookAdManagement');
              return Promise.reject();
            }
            case CampaignChannelType.INSTAGRAM_ADS: {
              await showFeatureUpSellDialog('InstagramAdManagement');
              return Promise.reject();
            }
            default: {
              try {
                const campaignId = await mutateAsync(createCampaignRequest);
                /**
                 * If we are in a drawer, close the drawer before navigating to the campaign
                 */
                if (variant === 'drawer') {
                  drawerService.popDrawer(true, {
                    campaignId,
                  });
                }

                //When this is being used from the onboarding flow we want to hide the nav during campaign creation.
                const isOnboarding = pathname.includes('first-campaign');
                console.log('isOnboarding', isOnboarding, pathname);
                navigateToCampaignById(campaignId, undefined, isOnboarding);
              } catch (error) {
                console.error('Error creating campaign', error);
                throw error;
              }
            }
          }
        }}
        doesBusinessActivitySupportPhysicalLocations={
          isPhysicalLocationsEnabled
        }
        doesBusinessActivitySupportServiceAreas={isServiceAreasEnabled}
        doesBusinessActivitySupportServices={isServicesEnabled}
      />
    );
  }
);
