import { Fragment } from 'react';
import { CampaignDetail, CampaignDetailProps } from '../../business-objects';
import { ObButton } from '../../components/elements/ob-button/ob-button';
import { ObDivider } from '../../components/elements/ob-divider/ob-divider';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { BudgetSection, BudgetSectionProps } from './sections/budget';
import {
  CampaignHighlightSection,
  CampaignHighlightSectionProps,
} from './sections/campaign-highlights';
import {
  CustomerProfileSection,
  CustomerProfileSectionProps,
} from './sections/customer-profile';
import { LeadsSection } from './sections/leads';
import { LocationSection, LocationSectionProps } from './sections/location';

export interface CampaignDetailPageProps extends CustomerProfileSectionProps {
  campaignCardDetailHeader: CampaignDetailProps;
  alertSection: React.ReactNode;
  campaignLatestDeploymentSection: {
    /**
     * React Node that renders the latest Campaign Deployment Details
     */
    latestCampaignDeploymentNode: React.ReactNode;
  };
  campaignHighlightsSection: CampaignHighlightSectionProps;
  campaignLocationsSection: LocationSectionProps;
  budgetSectionProps: BudgetSectionProps;
  children?: React.ReactNode;
  campaignCreativeMessage: string;
  activeView: 'overview' | 'status';
  onActiveViewUpdatedCallback: (activeView: 'overview' | 'status') => void;
}

export const CampaignDetailLandingPage = ({
  campaignCardDetailHeader,
  alertSection,
  campaignLatestDeploymentSection,
  campaignHighlightsSection,
  campaignLocationsSection,
  customerProfileCardNode,
  customerProfileMissing,
  budgetSectionProps,
  children,
  campaignCreativeMessage,
  activeView,
  onActiveViewUpdatedCallback,
}: CampaignDetailPageProps) => {
  return (
    <div>
      <div className='max-w-screen-lg m-auto gap-[36px] flex flex-col p-6 '>
        <div className='flex flex-col gap-4'>
          <CampaignDetail {...campaignCardDetailHeader} />
          <div className='flex  flex-row gap-2 border-t dark:border-dark/border/default/normal pt-4'>
            <ObButton
              label='Overview'
              variant={activeView === 'overview' ? 'outline' : 'ghost'}
              size='medium'
              onClick={() => {
                onActiveViewUpdatedCallback('overview');
              }}
            />
            <ObButton
              label='Status'
              size='medium'
              variant={activeView === 'status' ? 'outline' : 'ghost'}
              onClick={() => {
                onActiveViewUpdatedCallback('status');
              }}
            />
          </div>
        </div>

        {renderActiveSection({
          campaignCardDetailHeader,
          alertSection,
          campaignLatestDeploymentSection,
          campaignHighlightsSection,
          campaignLocationsSection,
          customerProfileCardNode,
          customerProfileMissing,
          budgetSectionProps,
          children,
          campaignCreativeMessage,
          activeView,
        })}
      </div>
    </div>
  );
};

const renderActiveSection = ({
  campaignCardDetailHeader,
  alertSection,
  campaignLatestDeploymentSection,
  campaignHighlightsSection,
  campaignLocationsSection,
  customerProfileCardNode,
  customerProfileMissing,
  budgetSectionProps,
  children,
  campaignCreativeMessage,
  activeView,
}: Omit<CampaignDetailPageProps, 'onActiveViewUpdatedCallback'>) => {
  return activeView === 'overview' ? (
    <>
      {alertSection}
      {campaignCardDetailHeader.goal === 'LEAD_GEN_FOR_SERVICE' && (
        <LeadsSection
          currentWorkspacePlan={'free'}
          isAttributionAvailableForCampaign={false}
        />
      )}
      <ObDivider />

      <CampaignHighlightSection {...campaignHighlightsSection} />
      <ObDivider />
      <LocationSection {...campaignLocationsSection} />
      <ObDivider />
      <CustomerProfileSection
        customerProfileMissing={customerProfileMissing}
        customerProfileCardNode={customerProfileCardNode}
      />
      <ObDivider />
      <BudgetSection {...budgetSectionProps} />
      {children}
    </>
  ) : (
    <>
      <div className='flex flex-col gap-3'>
        <ObTypography variant='h2'>Published Status</ObTypography>
        {!campaignLatestDeploymentSection?.latestCampaignDeploymentNode && (
          <div className='flex flex-shrink border rounded-md border-dotted border-dark/border/default/normal px-2 py-[1px] max-w-fit'>
            <ObTypography
              variant='h4'
              color='tertiary'
            >
              Not Published
            </ObTypography>
          </div>
        )}
        {campaignLatestDeploymentSection.latestCampaignDeploymentNode}
      </div>

      <div className='flex flex-col gap-3'>
        <ObTypography variant='h2'>Creative Status</ObTypography>
        <ObTypography
          variant='body1'
          color={
            campaignCreativeMessage === 'Your creative is ready to publish!'
              ? 'positive'
              : 'negative'
          }
        >
          {/* Attribute: Campaign Creative Overview Message  */}
          {campaignCreativeMessage}
        </ObTypography>
        {campaignHighlightsSection.campaignHighlights.map((highlight) => {
          return (
            <Fragment key={highlight.highlightedObjectId}>
              {highlight.associatedCreativesSlot}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};
