import { cx } from 'class-variance-authority';
import { FC } from 'react';
import { ObIcon } from '../../../..';
import {
  DisabledProps,
  StateManagedByParentInput,
} from '../../../../base-component-props.type';
import { formatCurrency } from '../../../../utilities/format-utilities';

interface InputAdjustSideButtonProps extends DisabledProps {
  onClick: () => void;
  mode: 'increase' | 'decrease';
}

const InputAdjustSideButton: FC<InputAdjustSideButtonProps> = ({
  mode,
  onClick,
  isDisabled,
}: InputAdjustSideButtonProps) => {
  const buttonId = `${mode}-button`;
  return (
    <>
      <label
        id={buttonId}
        className='sr-only'
      >
        {mode === 'decrease' ? 'Decrease Budget' : 'Increase Budget'}
      </label>
      <button
        disabled={isDisabled}
        aria-labelledby={buttonId}
        className={cx(
          'flex justify-center items-center px-3 py-5 bg-dark/background/surface focus:outline-none focus-visible:shadow-interactive hover:dark:bg-dark/action/neutral/hover active:dark:bg-dark/action/neutral/active',
          mode === 'decrease' ? 'rounded-l-md' : 'rounded-r-md'
        )}
        onClick={onClick}
      >
        <ObIcon
          icon={mode === 'decrease' ? 'minus' : 'plus'}
          size={'medium'}
        />
      </button>
    </>
  );
};
interface ObBudgetInputProp
  extends StateManagedByParentInput<number>,
    DisabledProps {
  minValue?: number;
  maxValue: number;
}

/**
 * This component serves two purposes one to visually see the budget as the slider is adjusting it
 * and to allow the user to input a budget directly if they choose to do so
 * @param param0
 * @returns
 */
export const ObBudgetInput: FC<ObBudgetInputProp> = ({
  value,
  onValueChangedCallback,
  minValue = 0, //Default to 0 if not provided
  maxValue,
  isDisabled,
}: ObBudgetInputProp) => {
  return (
    <div className='flex flex-1 flex-row flex-nowrap gap-1'>
      <InputAdjustSideButton
        isDisabled={isDisabled}
        onClick={() =>
          onValueChangedCallback(Math.max(value - 5, minValue), undefined)
        }
        mode={'decrease'}
      />

      <label
        htmlFor='budget-input'
        className='sr-only'
      >
        Ad Campaign Budget
      </label>
      <input
        name='budget-input'
        disabled={isDisabled}
        onFocus={(event) => event.target.select()}
        inputMode='numeric' //Show the numeric keyboard on mobile
        value={formatCurrency(value, {
          roundToWholeNumber: true,
        })}
        onChange={(event) => {
          onValueChangedCallback(
            //Make sure that the value is within the bounds of the slider minValue and maxValue and that it is a number and undefined or empty string is treated as 0
            Math.min(
              Math.max(Number(event.target.value.replace(/\D/g, '')), minValue),
              maxValue
            ),

            undefined
          );
        }}
        className='flex-1 focus:outline-none focus:shadow-none focus-visible:shadow-interactive bg-dark/background/surface text-dark/text/primary text-2xl sm:text-3xl md:text-5xl p-3 text-center hover:dark:bg-dark/action/neutral/hover active:dark:bg-dark/action/neutral/active text-dark/content/primary'
      />
      <InputAdjustSideButton
        isDisabled={isDisabled}
        onClick={() =>
          onValueChangedCallback(Math.min(value + 5, maxValue), undefined)
        }
        mode={'increase'}
      />
    </div>
  );
};
