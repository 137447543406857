import { Plan } from '@outbound/types';

export const formatLowestPlanLabel = (plan: Array<Plan>) => {
  let lowestPlanLabel = 'All plans';
  if (plan == null || plan.length === 0) {
    return lowestPlanLabel;
  }

  if (plan.includes('ENTERPRISE')) {
    lowestPlanLabel = 'Enterprise Plan';
  }

  if (plan.includes('PRO')) {
    lowestPlanLabel = 'Pro Plan';
  }

  if (plan.includes('STARTER')) {
    lowestPlanLabel = 'Starter Plan';
  }

  return lowestPlanLabel;
};
