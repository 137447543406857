import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';
import { CampaignDetailProps } from '../campaign-detail';

export const CampaignLatestDeploymentBadge = ({
  latestCampaignDeploymentSlot,
}: Pick<CampaignDetailProps, 'latestCampaignDeploymentSlot'>) => {
  return (
    <div className='flex flex-col'>
      <ObTypography
        variant='subtitle3'
        color='tertiary'
      >
        Published Status
      </ObTypography>
      {latestCampaignDeploymentSlot && (
        <div className='flex flex-shrink'>{latestCampaignDeploymentSlot}</div>
      )}
      {!latestCampaignDeploymentSlot && (
        <div className='flex flex-shrink border rounded-md border-dotted border-dark/border/default/normal px-2 py-[1px]'>
          <ObTypography
            variant='body2'
            color='tertiary'
          >
            Not Published
          </ObTypography>
        </div>
      )}
    </div>
  );
};
