import { ObCheckbox } from '@outbound/design-system';

interface LegalAgreementCheckboxProps {
  checked: boolean;
  onChange: () => void;
  label: string;
  disabled: boolean;
  errorMessage: string;
  labelNode: React.ReactNode;
}

const LegalAgreementCheckbox = ({
  checked,
  onChange,
  labelNode,
  label = 'I agree',
  disabled = false,
  errorMessage = '',
}: LegalAgreementCheckboxProps) => {
  return (
    <div className='w-full flex flex-row p-4 sm:p-4 justify-start items-start max-w-[700px]'>
      <div className='pt-1'>
        <ObCheckbox
          tabindex={2}
          disabled={disabled}
          checked={checked ? 'checked' : 'unchecked'}
          label={label}
          onChange={onChange}
          labelNode={labelNode}
        />
      </div>
      {errorMessage && <div className='error-message'>{errorMessage}</div>}
    </div>
  );
};

export default LegalAgreementCheckbox;
