import {
  ObIcon,
  ObInputListbox,
  ObMenuItem,
  ObNavigationSidenav,
  ObTypography,
} from '@outbound/design-system';
import { useCallback } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { ListboxButton } from '@headlessui/react';
import { usePlaybookDownloads } from './hooks/use-playbook-downloads';
import { playbookPageSections } from './playbook-page-section.def';

export const PlaybookV2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { workspaceSlug } = useParams();

  const {
    generateAndDownloadHandout,
    isHandoutGenerating,
    handoutLabel,
    isHandoutReady,
  } = usePlaybookDownloads();

  const onNavigationItemClicked = useCallback(
    (nav: any) => {
      // This should be moved to app navigation at some point
      navigate(`/${workspaceSlug}/playbook/${nav.item}`);
    },
    [navigate, workspaceSlug]
  );
  // 259 + 1156 -260
  return (
    <div className=' flex flex-row justify-start w-full h-full'>
      <div className='fixed hidden lg:block border-r border-borderDefaultNormalDark h-full'>
        <div className='flex flex-col justify-between h-full'>
          <div>
            <div className='pt-6'></div>
            <ObNavigationSidenav
              onClickCallback={onNavigationItemClicked}
              sections={playbookPageSections}
              activeId={location.pathname}
            />
          </div>

          <div className='border-t border-dark/border/default/normal'>
            {/* Random padding here to align the height of the export handout button with the primary nav border */}
            <div className='px-4 py-[8.75px]'>
              <ObMenuItem
                isActive={false}
                leftIcon={isHandoutReady ? 'check' : 'download'}
                isProcessing={isHandoutGenerating}
                size='small'
                kind='tertiary'
                disabled={isHandoutGenerating}
                onClickCallback={() =>
                  !isHandoutGenerating && generateAndDownloadHandout()
                }
              >
                {handoutLabel}
              </ObMenuItem>
            </div>
          </div>
        </div>
      </div>
      <div className='lg:ml-[260px] w-full min-w-0 xl:min-w-[637px] p-6'>
        <div className='max-w-3xl w-full m-auto h-full'>
          <div className='flex lg:hidden flex-row justify-between items-center'>
            <ObInputListbox
              listBoxButton={
                <ListboxButton className={'flex-1 flex'}>
                  <div className='flex flex-row justify-start items-center gap-2 hover:bg-dark/action/ghost/hover active:bg-dark/action/ghost/active focus-visible:shadow-interactive flex-1 py-3 rounded-md'>
                    <ObTypography
                      variant='h3'
                      as='h1'
                    >
                      {playbookPageSections[0].pages.find((page) => {
                        console.log(
                          'Testing ',
                          page,
                          location.pathname.endsWith(page.id)
                        );
                        return location.pathname.endsWith(page.id);
                      })?.title ?? ''}
                    </ObTypography>
                    <div className='pt-1'>
                      <ObIcon
                        icon='chevronDown'
                        size={'medium'}
                      />
                    </div>
                  </div>
                </ListboxButton>
              }
              inputId={'playbook-nav'}
              isErrored={false}
              size={'medium'}
              isDisabled={false}
              value={
                playbookPageSections[0].pages.find(
                  (page) => page.id === location.pathname
                )?.title ?? ''
              }
              options={
                playbookPageSections[0].pages.map((page) => ({
                  key: page.id,
                  label: page.title,
                  value: page.id,
                  description: '',
                  data: page,
                })) ?? []
              }
              onValueChangedCallback={(option) => {
                onNavigationItemClicked({ item: option });
              }}
              isLoading={false}
            />

            <ObMenuItem
              iconOnly={true}
              isActive={false}
              leftIcon={isHandoutReady ? 'check' : 'download'}
              isProcessing={isHandoutGenerating}
              size='small'
              kind='tertiary'
              disabled={isHandoutGenerating}
              onClickCallback={() =>
                !isHandoutGenerating && generateAndDownloadHandout()
              }
            >
              {handoutLabel}
            </ObMenuItem>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
