import { datadogRum } from '@datadog/browser-rum';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from 'react-router-dom';

import { LDEvaluationDetail } from 'launchdarkly-js-client-sdk';

import Logout from './components/authentication/Logout';
import ProtectedRoute from './components/authentication/ProtectedRoute';
import ProtectedWorkspaceRoute from './components/authentication/ProtectedWorkspaceRoute';
import AppRedirect from './pages/app-redirect/app-redirect';
import WorkspaceLauncherPage from './pages/workspace-launcher/workspace-launcher-page';

import { GlobalStyles, ThemeProvider } from '@mui/material';
import {
  REQUIRED_ACTIONS_ROUTE,
  RoutePaths,
} from './components/navigation/NavigationConstants';
import {
  ConfigurationProvider,
  useConfiguration,
} from './contexts/configuration-context';
import { createTheme } from './theme';

import {
  DialogServiceProvider,
  DrawerServiceProvider,
  NotificationServiceProvider,
  ObFormRendererProvider,
} from '@outbound/design-system';
import RequiredActionsPage from './pages/required-actions/required-actions-page';

import { LaunchDarklyIdentity } from './feature-flag/launch-darkly-identity';
import { CompleteProfilePage } from './pages/sign-up/complete-profile-page';
import { SetupWorkspacePage } from './pages/sign-up/setup-workspace-page';
import { BrandOnboardingWizardPage } from './pages/wizard/brand-onboarding-wizard-page';
import { WebsiteCapturePage } from './pages/wizard/website-capture-cta/website-capture-cta-page';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Auth0ExtendedProvider } from './auth/auth0-extended-provider';
import { Auth0ProviderWithRedirectCallback } from './auth0-provider';
import { renderRoutes } from './components/navigation/render-routes';
import { SettingsProvider } from './contexts/settings-context';
import { DataDog } from './datadog/datadog';
import { DataDogIdentify } from './datadog/datadog-identify';
import { LAUNCH_DARKLY_CLIENT_ID } from './environment';
import { Auth0Playground } from './pages/auth0-playground/auth0-playground';
import { MarketingPlanBuildingPage } from './pages/campaign-wizard/components/campaign-wizard-steps/steps/step-marketing-plan/marketing-plan-building-page';
import { MarketingPlanPage } from './pages/campaign-wizard/components/campaign-wizard-steps/steps/step-marketing-plan/marketing-plan-page';
import { CreativeBuilderPage } from './pages/creative-builder/creative-builder';
import { dashboardRoute } from './pages/dashboard/routes';
import { CompletedConfirmationPage } from './pages/onboarding/completed-confirmation-page/completed-confirmation-page';
import { FirstCampaignPage } from './pages/onboarding/first-campaign-page/first-campaign-page';
import { QuestionnairePage } from './pages/onboarding/questionnaire-page/questionnaire-page';
import { SubscriptionSelectionPage } from './pages/onboarding/subscription-selection-page/subscription-selection-page';
import { ResumeOnboardingRedirect } from './pages/resume-onboarding-redirect/resume-onboarding-redirect';
import { Auth0AxiosProvider } from './services/auth0-axios-provider';
import { RootStoreProvider } from './state/mobx-experiment/root-store-provider';

const queryClient = new QueryClient();

const App = () => {
  useEffect(() => {
    console.log('Outbound Web App Initialized');
  }, []);

  return (
    <Routes>
      <Route
        path={RoutePaths.WORKSPACE_CREATION_PAGE}
        element={<ProtectedRoute component={() => <SetupWorkspacePage />} />}
      />
      <Route
        path='/auth0-playground'
        element={<Auth0Playground />}
      />
      <Route
        path='/signup/profile'
        element={<CompleteProfilePage />}
      />
      <Route
        path={REQUIRED_ACTIONS_ROUTE}
        element={<RequiredActionsPage />}
      />

      {/* Newest Vision for Dynamic Dashboard Routes */}
      {renderRoutes([dashboardRoute])}

      <Route
        path='/creative-builder/:creativeId'
        element={
          <ProtectedWorkspaceRoute component={() => <CreativeBuilderPage />} />
        }
      />

      <Route
        path='/login'
        element={<AppRedirect />}
      />
      <Route
        path='/logout'
        element={<Logout />}
      />
      <Route
        path='/get-started'
        element={<WebsiteCapturePage />}
      />

      {/* Route to ask additional questions and get user confirmation during onboarding process */}
      <Route
        path='/onboarding/questionnaire'
        element={
          <ProtectedWorkspaceRoute component={() => <QuestionnairePage />} />
        }
      />
      <Route
        path='/onboarding/:workspaceSlug/first-campaign'
        element={
          <ProtectedWorkspaceRoute component={() => <FirstCampaignPage />} />
        }
      />
      <Route
        path='/onboarding/building-your-plan'
        element={
          <ProtectedWorkspaceRoute
            component={() => <MarketingPlanBuildingPage />}
          />
        }
      />
      {/* Present the marketing plan to the user. (Also includes the loading indicator for the marketing plan) */}
      <Route
        path='/onboarding/your-marketing-plan'
        element={
          <ProtectedWorkspaceRoute component={() => <MarketingPlanPage />} />
        }
      />
      {/* Provide an Opportunity for the user to pick their subscription */}
      <Route
        path='/onboarding/pick-subscription'
        element={
          <ProtectedWorkspaceRoute
            component={() => <SubscriptionSelectionPage adBudget={'61'} />}
          />
        }
      />
      {/* Onboarding Complete Confirmation Screen */}
      <Route
        path='/onboarding/completed-confirmation'
        element={<CompletedConfirmationPage />}
      />
      <Route
        path='/onboarding-wizard'
        element={<BrandOnboardingWizardPage />}
      />

      <Route
        path='/onboarding/:onboardingId'
        element={<ResumeOnboardingRedirect />}
      />
      <Route
        path='/my-workspaces'
        element={<ProtectedRoute component={WorkspaceLauncherPage} />}
      />

      <Route
        path='*'
        element={<AppRedirect />}
      />
    </Routes>
  );
};

export const router = createBrowserRouter([
  {
    path: '*',
    Component() {
      const { configuration } = useConfiguration();
      return (
        <QueryClientProvider client={queryClient}>
          <Auth0ProviderWithRedirectCallback>
            <ReactQueryDevtools position='bottom-right' />
            <ThemeProvider
              theme={createTheme({
                mode: configuration.theme,
                direction: configuration.direction,
              })}
            >
              <Auth0ExtendedProvider>
                <Auth0AxiosProvider>
                  <LaunchDarklyIdentity>
                    <DataDogIdentify>
                      <NotificationServiceProvider>
                        <RootStoreProvider>
                          <SettingsProvider>
                            <ObFormRendererProvider>
                              <DialogServiceProvider>
                                <DrawerServiceProvider>
                                  <App />
                                </DrawerServiceProvider>
                              </DialogServiceProvider>
                            </ObFormRendererProvider>
                          </SettingsProvider>
                        </RootStoreProvider>
                      </NotificationServiceProvider>
                    </DataDogIdentify>
                  </LaunchDarklyIdentity>
                </Auth0AxiosProvider>
              </Auth0ExtendedProvider>
            </ThemeProvider>
          </Auth0ProviderWithRedirectCallback>
        </QueryClientProvider>
      );
    },
  },
]);

export const AppWithRouter = () => {
  return (
    <React.StrictMode>
      <GlobalStyles styles={{}} />
      <ConfigurationProvider>
        <RouterProvider router={router} />
      </ConfigurationProvider>
    </React.StrictMode>
  );
};

const AppWithLaunchDarkly = withLDProvider({
  clientSideID: LAUNCH_DARKLY_CLIENT_ID,
  options: {
    inspectors: [
      {
        type: 'flag-used',
        name: 'dd-inspector',
        method: (key: string, detail: LDEvaluationDetail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value);
        },
      },
    ],
  },
  //Context is set in launch-darkly-identity.tsx based on authentication events
})(AppWithRouter);

document.body.innerHTML = '<div id="root"></div>';
const root = createRoot(document.getElementById('root')!);
root.render(
  <DataDog>
    <AppWithLaunchDarkly />
  </DataDog>
);
