import { FC } from 'react';

import {
  FormDefinition,
  FormFieldType,
  FormSectionDefinition,
  ObTypography,
} from '@outbound/design-system';
import { SettingCardRow } from '@outbound/design-system/src/components/organisms/ob-setting-card-row/setting-card-row';
import { SettingCardRowWithInlineForm } from '../../../../../components/molecules/setting-card/setting-card-row-with-inline-form';
import WorkspaceDetailsDanger from './workspace-details-danger';

import RequiresRole, {
  ROLE,
} from '../../../../../components/utils/requires-role/requires-role';
import {
  useFetchWorkspaceDetails,
  usePatchWorkspaceMutation,
} from '../../../../../query/workspace/use-workspace-endpoints';

const SETTING_WORKSPACE_NAME_FORM_DEFINITION: FormDefinition = {
  id: 'workspace-settings-name-form',
  heading: '',
  subHeading: '',
  sections: [
    {
      id: 'section1',
      heading: '',
      subHeading: '',
      fields: [
        {
          id: 'name',
          hideLabel: true,
          label: 'Workspace Name',
          helperText: 'Enter the name of your Workspace',
          type: FormFieldType.TEXT,
          autofocus: true,
          validationSchema: {
            isRequired: true,
            minLength: 1,
            maxLength: 150,
          },
          fieldTypeSettings: {},
        },
      ],
    } as FormSectionDefinition,
  ],
};

const WorkspaceDetails: FC = () => {
  const { data: workspace, isLoading } = useFetchWorkspaceDetails();
  const { mutateAsync } = usePatchWorkspaceMutation();
  /**
   * Commenting out methods for workspace icon image upload
   */

  // const { mutateAsync: requestIconUploadUrl } =
  //   useGetUploadUrlForWorkspaceIcon();
  // const { mutateAsync: setWorkspaceIcon } = useUpdateWorkspaceIcon();
  // // const { mutateAsync: deleteWorkspaceIcon } = useRemoveWorkspaceIcon();

  // const getPreSignedURL = async (
  //   file: File
  // ): Promise<{ url: string; filename: string }> => {
  //   const {
  //     workspaceIconPresignedUrl,
  //     workspaceIconFilename,
  //   }: PostWorkspaceIconGenerateUploadUrlResponseResource =
  //     await requestIconUploadUrl({ mimeType: file.type });
  //   return Promise.resolve({
  //     url: workspaceIconPresignedUrl,
  //     filename: workspaceIconFilename,
  //   });
  // };

  // const uploadProfileImage = async (file: File) => {
  //   const { url, filename } = await getPreSignedURL(file);
  //   if (url && filename) {
  //     await uploadContentUsingPreSignedURL(url, file);
  //     await setWorkspaceIcon({ iconFilename: filename });
  //   }
  // };

  // const onProfileImageSelectHandle = async ({
  //   croppedImage,
  // }: CroppedResult) => {
  //   const { type } = croppedImage;
  //   await uploadProfileImage(new File([croppedImage], '', { type }));
  // };

  const updateOrganizationName = async (name: string) => {
    return mutateAsync({ name });
  };
  // const onProfileImageRemove = async () => {
  //   await deleteWorkspaceIcon();
  // };

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-col gap-2'>
        <ObTypography variant='h2'>Workspace Settings</ObTypography>
        <ObTypography
          variant='body2'
          color='secondary'
        >
          General setting related to your workspace
        </ObTypography>
      </div>
      <div className='flex flex-col sm:flex-row gap-6 w-full'>
        <div className=' w-full'>
          <SettingCardRowWithInlineForm
            title='Workspace Name'
            editButtonAriaLabel={'Edit Workspace Name'}
            loading={isLoading}
            formDefinition={SETTING_WORKSPACE_NAME_FORM_DEFINITION}
            defaultValues={{ name: workspace?.name }}
            body={workspace?.name || ''}
            rolesWhoCanEdit={[ROLE.WORKSPACE_OWNER]}
            handleFormSubmitCallback={(formValues) =>
              updateOrganizationName(formValues.name)
            }
            editButtonType={'title-icon'}
            icon={'pencilLine'}
          />
        </div>
        <div className='flex flex-col w-full items-start'>
          <SettingCardRow
            body={workspace?.id || ''}
            title={'Workspace ID'}
            loading={isLoading}
          ></SettingCardRow>
        </div>
      </div>

      {/* 
      Removing the workspace icon upload for now

      <ObImageCropperUpload
        title='Workspace Icon'
        loading={isLoading}
        fileDescription='jpg or png. Max size of 500K'
        serverImage={workspace?.iconPublicUrl || ''}
        onSaveCallbackFunction={onProfileImageSelectHandle}
        onRemoveCallbackFunction={onProfileImageRemove}
      /> */}
      <RequiresRole roles={[ROLE.WORKSPACE_OWNER]}>
        <WorkspaceDetailsDanger
          workspaceName={workspace?.name || ''}
          loading={isLoading}
        />
      </RequiresRole>
    </div>
  );
};

export default WorkspaceDetails;
