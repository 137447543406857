import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import { cva, cx } from 'class-variance-authority';

import { useEffect, useRef, useState } from 'react';
import {
  BaseExposedTextInputProps,
  DisabledProps,
  InputSizeProps,
  StateManagedByParentInput,
  ValueIsOptionProps,
} from '../../../../base-component-props.type';
import { ObIcon } from '../../../../tokens/icons/ob-icon/ob-icon';
import { ObCheckboxUi } from '../../../elements/ob-checkbox-ui/ob-checkbox-ui';

import { ObTag } from '../../../../indicators';
import { ObTypography } from '../../../elements/ob-typography/ob-typography';

export interface ObInputComboboxProps
  extends StateManagedByParentInput<Array<string>>,
    DisabledProps,
    InputSizeProps,
    BaseExposedTextInputProps,
    ValueIsOptionProps<ObInputComboboxOption> {
  inputBehavior?: 'wrap' | 'truncate';
  allowMultiple?: boolean;
}

export interface ObInputComboboxOption {
  id: string;
  label: string;
}

export const ObInputCombobox = ({
  inputId,
  value,
  onValueChangedCallback,
  isDisabled = false,
  options,
  placeholder,
  size = 'medium',
  inputBehavior = 'truncate',
  allowMultiple = true,
}: ObInputComboboxProps) => {
  if (value == null) {
    throw new Error(
      'Value cannot be null. Please pass an empty array instead.'
    );
  }
  /**
   * Utility function to filter the options based on the query
   * @param options
   * @param query
   * @returns
   */
  const filterAndSortOptions = (
    options: ObInputComboboxOption[],
    query: string
  ) =>
    (query === ''
      ? options
      : options.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        })
    ).sort(
      (a, b) =>
        (a.label ?? a.id).localeCompare(b.label ?? b.id, undefined, {
          sensitivity: 'base',
        }) //Case insensitive sort
    );

  /**
   * Tracks the value of the input field
   */
  const [filterQuery, setFilterQuery] = useState('');

  const [filteredOptions, setFilteredAndSortedOptions] = useState<
    ObInputComboboxOption[]
  >(filterAndSortOptions(options, filterQuery));

  useEffect(() => {
    setFilteredAndSortedOptions(filterAndSortOptions(options, filterQuery));
  }, [filterQuery, options]);

  /**
   * Tracks the state of the dropdown
   * This is needed because we are implementing a custom input button that shows the
   * currently selected options as tag. We need to update the styles of the dropdown
   * as the dropdown is opened and closed.
   */
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const listBoxButtonStyles = cva(
    [
      'flex flex-row  justify-between items-center gap-2 flex-nowrap w-full', //Layout
      'border  dark:border-dark/border/default/normal dark:hover:border-dark/border/default/hover ', //Border
      'data-[open=true]:rounded-t data-[open=false]:rounded', //Border Radius
      'bg-light/background/default dark:bg-dark/background/surface', //Background
      'focus:outline-none focus:shadow-interactive', //Focus
      'text-nowrap whitespace-nowrap', //Text Styles
    ],
    {
      variants: {
        inputBehavior: {
          wrap: '',
          truncate: '',
        },
        size: {
          /**
           * We remove padding because the chips themselves will need all the space to not expand the container.
           * With this setup the input maintains a consistent size regardless of the number of chips.
           * Expected sizes from the design system are: small: 34px, medium: 42px, large: 50px (Including border + padding)
           * https://www.figma.com/design/l1PsB4JNyhbHbnXedIkHJ4/Outbound-DS?node-id=335-13001&m=dev
           */
          small: [
            'px-3 data-[empty=true]:py-[5px] data-[empty=false]:py-[4px]',
          ],
          medium: [
            'px-4 data-[empty=true]:py-[9px] data-[empty=false]:py-[8px]',
          ],
          large: [
            'px-4 data-[empty=true]:py-[13px] data-[empty=false]:py-[12px]',
          ],
        },
        isDisabled: {
          true: 'opacity-[50%] pointer-events-none cursor-not-allowed',
          false: 'cursor-pointer',
        },
      },
      defaultVariants: {
        isDisabled: false,
      },
    }
  );

  /**
   * Reference used by the truncate input behavior to calculate the width of the input
   * from the container width
   */
  const inputRef = useRef<HTMLDivElement>(null);

  /**
   * Internal State that tracks which of the selected options are visible as chips
   * Used with the truncate input behavior to show only a few selected options as chips
   * and the remaining count as a label
   */
  const [visibleChips, setVisibleChips] = useState<ObInputComboboxOption[]>([]);
  /**
   * Internal State that tracks the number of selected options that are not visible as chips.
   * This is used to show a label indicating the number of options that are not visible.
   * Example: "+${remainingCount} more..."
   */
  const [remainingCount, setRemainingCount] = useState(0);

  /**
   * Side Effect that calculates which chips should be visible based on the input width and the width of the chips
   * Run each time the value (Which options are selected) changes
   * */
  useEffect(() => {
    const calculateVisibleChips = () => {
      if (!inputRef.current) return;

      const andMoreWidth = 80;
      const inputWidth = inputRef.current.offsetWidth - andMoreWidth;

      let totalWidth = 0;
      const chipWidths: number[] = [];
      const visible: ObInputComboboxOption[] = [];

      if (inputBehavior === 'truncate') {
        value.forEach((i) => {
          const option = options.find((option) => option.id === i);
          if (option) {
            const chip = document.createElement('div');
            chip.className = 'chip';
            chip.style.visibility = 'hidden';
            chip.style.position = 'absolute';
            chip.innerHTML = option.label;

            document.body.appendChild(chip);
            const width = chip.offsetWidth + 24; // Adjust 24 for padding and margins
            document.body.removeChild(chip);

            chipWidths.push(width);
          }
        });

        for (let i = 0; i < chipWidths.length; i++) {
          totalWidth += chipWidths[i];
          if (totalWidth <= inputWidth || i === 0) {
            visible.push(options.find((option) => option.id === value[i])!);
            setRemainingCount(0);
          } else {
            setRemainingCount(value.length - visible.length);
            break;
          }
        }
      } else {
        visible.push(
          ...value.map((i) => options.find((option) => option.id === i)!)
        );
      }
      setVisibleChips(visible);
    };

    calculateVisibleChips();
    window.addEventListener('resize', calculateVisibleChips);

    return () => window.removeEventListener('resize', calculateVisibleChips);
  }, [value, options, inputBehavior]);

  return (
    <div
      className='w-full relative '
      data-open={isDropdownOpen}
    >
      {/* Not Visible in the UI; 
         Used by the truncate inputBehavior logic to assess the width of the container
         Useful so we avoid styles of actual elements such as 
         flex-grow, flex-shrink from impacting the calculation
       */}
      <div
        data-input-behavior={inputBehavior}
        ref={inputRef}
        className='w-[calc(100%-40px)] h-full absolute invisible '
      />

      <div
        aria-labelledby={inputId}
        role='button'
        tabIndex={0}
        data-testid='ob-combobox'
        data-empty={value == null || value.length === 0}
        data-open={isDropdownOpen}
        onClick={() => setIsDropdownOpen((dropdownState) => !dropdownState)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setIsDropdownOpen((dropdownState) => !dropdownState);
          }
        }}
        className={listBoxButtonStyles({
          size,
          isDisabled,
          inputBehavior,
        })}
      >
        <div
          className={cx(
            'flex flex-row gap-1 overflow-hidden',
            inputBehavior === 'truncate' ? 'flex-nowrap' : 'flex-wrap'
          )}
        >
          {(value == null || value.length === 0) && (
            <div className='text-dark/content/placeholder'>
              <ObTypography
                variant='body2'
                color='inherit'
              >
                {placeholder ?? 'Select an option...'}
              </ObTypography>
            </div>
          )}
          {!allowMultiple && value?.length > 0 && (
            <ObTypography>
              {options.find((option) => option.id === value[0])?.label}
            </ObTypography>
          )}
          {allowMultiple && (
            <>
              {visibleChips
                .map((i) => options.find((option) => option.id === i.id))
                .map((item: ObInputComboboxOption | undefined) => {
                  if (item === undefined) return null;
                  return (
                    <ObTag
                      key={item.id}
                      content={item.label}
                      removable={true}
                      selectable={false}
                      onRemoveCallback={(e) => {
                        e.preventDefault(); //Prevents any parent form from submitting
                        e.stopPropagation();
                        onValueChangedCallback(
                          value.filter((v) => v !== item?.id),
                          undefined
                        );
                      }}
                    />
                  );
                })}
              {remainingCount > 0 && (
                <div className='pl-1 overflow-hidden'>
                  <ObTypography color='primaryV2'>
                    {visibleChips.length === 0
                      ? `${remainingCount} Selected`
                      : `+${remainingCount} more`}
                  </ObTypography>
                </div>
              )}
            </>
          )}
        </div>
        <div className='flex flex-shrink-0'>
          <ObIcon
            icon='chevronDown'
            size='small'
            color='tertiary'
          />
        </div>
      </div>

      {isDropdownOpen && (
        <div className='-mt-[1px] absolute w-full'>
          <Combobox<ObInputComboboxOption, true>
            {...(allowMultiple && {
              multiple: true,
            })}
            immediate
            value={value.map((v) => options.find((o) => o.id === v)!)}
            disabled={isDisabled}
            virtual={{ options: filteredOptions, disabled: () => false }}
            onClose={() => {
              setIsDropdownOpen(false);
              setFilterQuery(''); //Reset the query each time the combobox is closed
              setFilteredAndSortedOptions(options); //Reset the filtered options each time the combobox is closed
            }}
            onChange={(value) => {
              if (allowMultiple) {
                onValueChangedCallback(
                  value.map((v: ObInputComboboxOption) => v.id),
                  undefined
                );
              } else {
                /**
                 * The combobox value callback changes to a single value when allowMultiple is false.
                 * We have made the decision to always keep an array as the value to keep the API consistent.
                 **/
                const valueId = (value as unknown as ObInputComboboxOption).id;
                onValueChangedCallback([valueId], undefined);
              }
            }}
          >
            <div>
              <ComboboxInput
                autoFocus={true}
                className={cx(
                  'w-full dark:bg-dark/background/surface border dark:border-borderDefaultNormalDark border-borderDefaultNormalLight py-1.5 pr-8 pl-3 text-sm/6 dark:text-dark/content/primary   focus:outline-none focus:shadow-interactive-inset'
                )}
                placeholder='Type to search'
                onChange={(event) => setFilterQuery(event.target.value)}
              />

              <ComboboxButton className='group inset-y-0 right-0 px-2.5'>
                {value == null && (
                  <div className='dark:empty:text-dark/content/placeholder dark:text-dark/content/primary text-light/content/primary empty:text-light/content/placeholder'>
                    <ObTypography
                      variant='body2'
                      color='inherit'
                    >
                      Type to search
                    </ObTypography>
                  </div>
                )}
              </ComboboxButton>
            </div>

            <ComboboxOptions
              anchor='bottom'
              data-test-virtual
              portal={true}
              style={{ maxHeight: '240px' }}
              className='[--anchor-max-height:240px] w-[var(--input-width)] overflow-y-auto min-h-8 max-h-[100px] mt-[1px] rounded-b border dark:border-dark/border/default/normal dark:bg-dark/background/surface [--anchor-gap:var(--spacing-3)]  backdrop-blur p-1 empty:invisible z-50'
            >
              {({ option }) => (
                <ComboboxOption
                  value={option}
                  data-test-virtual //Used to in tests to calculate the height of the virtual list Thanks JSDOM...
                  className={
                    'w-full group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-dark/action/primary/on-subtle '
                  }
                >
                  {/* Show a Checkbox next to each item */}
                  {allowMultiple && (
                    <ObCheckboxUi
                      value={
                        value.includes((option as ObInputComboboxOption).id)
                          ? 'checked'
                          : 'unchecked'
                      }
                      isDisabled={false}
                      isErrored={false}
                    />
                  )}
                  {/* <CheckIcon className='invisible size-4 fill-white group-data-[selected]:visible' /> */}
                  <ObTypography
                    variant='body2'
                    color='primary'
                  >
                    {(option as ObInputComboboxOption).label}
                  </ObTypography>
                </ComboboxOption>
              )}
            </ComboboxOptions>
          </Combobox>
        </div>
      )}
    </div>
  );
};
