import { datadogRum } from '@datadog/browser-rum';
import { useDialogService } from '@outbound/design-system';
import { useAuth0Extended } from '../../../auth/use-auth0-extended';
import { useFetchBusinessDetails } from '../../../query/playbook/use-playbook-settings-business-details-endpoints';
import { useRootStore } from '../../../state/mobx-experiment/use-root-store';
import IntegrationConfigGoogleAds from '../../../state/mobx-experiment/workspace/integration-configuration/extended/integration-configuration-google-ads';
import { IntegrationXWorkspace } from '../../../state/mobx-experiment/workspace/types/integration-x-workspace.type';
import { SUPPORT_FORM_DEF, SupportFormSchema } from './support-form.def';

const portalId = '43275388';
const formGuid = '5e33095f-f4a1-44c3-aaeb-8e912e942a83';

export const useSupportForm = () => {
  const { openFormDialog } = useDialogService();

  const { data: businessDetails } = useFetchBusinessDetails();

  const { authenticatedWorkspaceId, user } = useAuth0Extended();
  const rootStore = useRootStore();

  const openSupportForm = () => {
    let integrations: Array<IntegrationXWorkspace> = [];
    let unableToLoadIntegrations = false;
    let integrationDetails = 'Unknown';
    try {
      try {
        integrations =
          rootStore.workspaceStore
            .getById(authenticatedWorkspaceId!)
            ?.getIntegrationsWithConfiguration() ?? [];
      } catch (error) {
        unableToLoadIntegrations = true;
      }

      if (!unableToLoadIntegrations) {
        const googleAdsIntegration = integrations.find((integration) => {
          return integration.integration.id === 'google-ads-manager';
        });

        if (
          googleAdsIntegration &&
          googleAdsIntegration.configuration != null
        ) {
          let googleAdsIntegrationConfig: IntegrationConfigGoogleAds =
            googleAdsIntegration.configuration as IntegrationConfigGoogleAds;
          integrationDetails = `
            Integration: Google Ads
            Status: ${googleAdsIntegration.configuration.lifecycleStatus}
            Configured At: ${googleAdsIntegration.configuration.configuredAtTimestamp}
            Configured By: ${googleAdsIntegration.configuration.configuredByUser.email}
            Google Customer Number: ${googleAdsIntegrationConfig.customerNumber}
            Google Ads Account Name: ${googleAdsIntegrationConfig.descriptiveName}
          `;
        } else {
          integrationDetails = `
            Google Ads Integration: Not Configured
            `;
        }
      }
    } catch (error) {
      console.error(
        'Could not load integration details for support form',
        error
      );
    }

    openFormDialog({
      formDefinition: SUPPORT_FORM_DEF,
      onFormSubmitCallback: async (data: SupportFormSchema) => {
        const userDetails = `
            ${data.message}
            ------------------------------------------------------
            Auto Generated Information Below this line
            ------------------------------------------------------
            App Details:
            first name: ${user?.given_name}
            last name: ${user?.family_name}
            emailVerified: ${user?.email_verified}
            email: ${user?.email}
            
            ------------------------------------------------------
            workspaceId: ${authenticatedWorkspaceId}
            workspaceName: ${rootStore.workspaceStore.getById(
              authenticatedWorkspaceId!
            )?.name}
            workspacePlan: ${rootStore.workspaceStore.getById(
              authenticatedWorkspaceId!
            )?.plan}
            auth0OrgId: ${rootStore.workspaceStore.getById(
              authenticatedWorkspaceId!
            )?.auth0OrganizationId}
            ------------------------------------------------------
            Integration Details
            ------------------------------------------------------
            ${integrationDetails}
            
            ------------------------------------------------------
            Page Details
            ------------------------------------------------------
            scheme: ${window.location.protocol}
            domain: ${window.location.hostname}
            path: ${window.location.pathname}


            DataDogUser: ${JSON.stringify(datadogRum.getUser(), null, 2)}
            Datadog Session ID: ${JSON.stringify(
              datadogRum.getInternalContext()?.session_id,
              null,
              2
            )}



            `;

        const body = new URLSearchParams({
          firstname: user?.given_name || 'unknown',
          lastname: user?.family_name || 'unknown',
          mobilephone: data.phone,
          email: user?.email || 'unknown',
          website: businessDetails?.website || 'unknown',
          'TICKET.subject': data.subject,
          'TICKET.content': userDetails,
        });

        const response = await fetch(
          `https://forms.hubspot.com/uploads/form/v2/${portalId}/${formGuid}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: body.toString(),
          }
        );

        return response;
      },
      title: 'Contact Support',
    });
  };

  return { openSupportForm };
};
