import { useAuth0 } from '@auth0/auth0-react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ObTypography, useDialogService } from '@outbound/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  CellActionDefinition,
  CellActions,
} from '../../../../../components/molecules/data-grid/cell-actions';
import { CellUserProfile } from '../../../../../components/molecules/data-grid/cell-user-profile';
import { ObDataGrid } from '../../../../../components/organisms/data-grid/ob-data-grid';
import {
  useFetchWorkspaceMembers,
  useRemoveMember,
  useUpdateMemberRole,
} from '../../../../../query/workspace/use-workspace-members-endpoints';

export const REMOVE_SELF_BODY_TEXT_1 =
  'You are unable to remove yourself from a workspace. If you no longer wish to have access to this workspace you should invite someone else and make them the owner and they can remove you.';
export const REMOVE_SELF_BODY_TEXT_2 =
  'Alternatively if you no longer need this workspace and want to remove it completely you can delete the workspace. This action will permanently delete the workspace for all users and is not reversible.';

const WorkspaceMembersDataGrid = () => {
  const { openAlert } = useDialogService();
  const { user } = useAuth0();
  const { nonOwnerRoleMembership: nonOwnerRoleMembershipFF } = useFlags();

  const { data: members, isLoading: isMembersLoading } =
    useFetchWorkspaceMembers();
  const { mutateAsync: updateMemberRole } = useUpdateMemberRole();
  const { mutateAsync: removeMember } = useRemoveMember();

  const removeMemberAction = (
    params: GridRenderCellParams<string>
  ): CellActionDefinition => {
    return {
      name: 'Remove Member',
      icon: 'delete',
      ariaLabel: `Remove ${params.row.user.email} from Workspace`,
      keyCommand: '',
      callback: () => removeMemberCallback(params.row.id),
    };
  };

  const addAsWorkspaceOwnerAction = (
    params: GridRenderCellParams<string>
  ): CellActionDefinition => {
    return {
      name: 'Change Role to Owner',
      icon: 'userSettings',
      ariaLabel: `Add ${params.row.user.email} as Workspace Owner`,
      keyCommand: '',
      callback: () =>
        updateMemberRole({ memberId: params.row.id, role: 'OWNER' }),
    };
  };

  const removeAsWorkspaceOwnerAction = (
    params: GridRenderCellParams<string>
  ): CellActionDefinition => {
    return {
      name: 'Change Role to Member',
      icon: 'userSettings',
      ariaLabel: `Remove ${params.row.inviteSentTo} as Workspace Owner`,
      keyCommand: '',
      isDisabled: params.row.id === user?.sub,
      callback: () =>
        updateMemberRole({ memberId: params.row.id, role: 'MEMBER' }),
    };
  };

  const defaultActions = (params: GridRenderCellParams<string>) => [
    removeMemberAction(params),
  ];

  const getActions = (
    params: GridRenderCellParams<string>
  ): Array<CellActionDefinition> => {
    let cellActions = defaultActions(params);
    /**
     * We only allow role changes once we release the non owner role membership feature
     * (Since we do not have permissions setup for non owners we are holding back on this feature)
     */
    if (nonOwnerRoleMembershipFF) {
      if (params.row.role === 'OWNER') {
        cellActions.push(removeAsWorkspaceOwnerAction(params));
      } else {
        cellActions.push(addAsWorkspaceOwnerAction(params));
      }
    }

    return cellActions;
  };

  const removeMemberCallback = (memberUserId: string) => {
    console.log('Remove Member with ID: ', memberUserId);

    // Do NOT allow to remove yourself
    if (memberUserId === user?.sub) {
      openAlert({
        body: [REMOVE_SELF_BODY_TEXT_1, REMOVE_SELF_BODY_TEXT_2],
        title: 'Unable to Remove Yourself',
      });
    } else {
      return removeMember(memberUserId);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Member',
      minWidth: 248,
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <CellUserProfile
            givenName={params.row.user.givenName}
            familyName={params.row.user.familyName}
            emailAddress={params.row.user.email}
          />
        );
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 248,
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <div className='px-1 rounded-md bg-dark/background/surface+1 border border-dark/border/default/normal'>
            <ObTypography variant='body3'>{params.row.role}</ObTypography>
          </div>
        );
      },
    },
    {
      field: 'id2',
      headerName: 'Actions',
      type: 'actions',
      hideable: false,
      filterable: false,
      minWidth: 72,
      editable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CellActions
            ariaActionsFor={params.row.email}
            actions={getActions(params)}
          />
        );
      },
    },
  ];

  return (
    <ObDataGrid
      rows={members?.items || []}
      columns={columns}
      loading={isMembersLoading}
      noContentCtaHeading={'No Members In Workspace'}
      noContentCtaSubHeading={
        'It appears you have no members in your workspace. If this issue persists contact support.'
      }
    />
  );
};

export default WorkspaceMembersDataGrid;
