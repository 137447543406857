import { CampaignHealthTodo, useDialogService } from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';
import { useAppNavigation } from 'src/hooks/use-app-navigation';
import Campaign, {
  CampaignHealthTodo as TodoModel,
} from 'src/state/mobx-experiment/campaign/campaign';
import Workspace from 'src/state/mobx-experiment/workspace/workspace';
import { useSetupIntegrationDialog } from '../../settings/workspace/integration/use-setup-integration-dialog';
import { tryToPublishCampaign } from '../campaign-publish-cta';

const determineLabelForTodo = (todoType: string) => {
  switch (todoType) {
    case 'activate-campaign':
      return 'Enable';
    case 'integration-health-check-item':
      return 'Check Again';
    case 'publish-campaign':
      return 'Publish';
    case 'review-creative-errors':
      return 'See Errors';
    case 'integration-not-configured':
      return 'Setup Now';
    default:
      return undefined;
  }
};

interface CampaignHealthCheckTodoObserverProps {
  campaign: Campaign;
  todo: TodoModel;
  index: number;
  workspace: Workspace;
}

export const CampaignHealthCheckTodoObserver = observer(
  ({
    campaign,
    todo,
    index,
    workspace,
  }: CampaignHealthCheckTodoObserverProps) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { navigateToIntegrationConfigurationBySlug } = useAppNavigation();

    const { initiateConfigureIntegrationUserFlow } =
      useSetupIntegrationDialog();

    const dialogService = useDialogService();
    return (
      <CampaignHealthTodo
        key={todo.todoType}
        index={index}
        title={todo.title}
        description={todo.description}
        completionStatus={todo.completionStatus}
        actionLabel={determineLabelForTodo(todo.todoType)}
        documentationHref={todo.documentationHref}
        onActionButtonClicked={() => {
          switch (todo.todoType) {
            case 'integration-not-configured': {
              if (campaign.primaryIntegrationConfiguration?.integration?.slug) {
                initiateConfigureIntegrationUserFlow(
                  campaign.primaryIntegrationConfiguration?.integration,
                  workspace,
                  true
                );
              }
              break;
            }
            case 'integration-health-check-item': {
              if (campaign.primaryIntegrationConfiguration?.integration?.slug) {
                campaign.primaryIntegrationConfiguration?.configuration?.runHealthChecks();
                navigateToIntegrationConfigurationBySlug(
                  campaign.primaryIntegrationConfiguration?.integration?.slug,
                  true,
                  'Back to Campaign'
                );
              }
              break;
            }
            case 'activate-campaign':
              campaign.unPauseCampaign();
              break;
            case 'publish-campaign':
              tryToPublishCampaign(
                campaign,
                searchParams,
                dialogService,
                setSearchParams
              );
              break;
            case 'review-creative-errors':
              setSearchParams((current) => {
                return new URLSearchParams({
                  ...current,
                  view: 'status',
                });
              });
              break;

            default:
              console.error('Unhandled todoType');
          }
        }}
        healthStatus={campaign.health.status}
      />
    );
  }
);
