import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import BusinessIcon from '@mui/icons-material/Business';

import CircleIcon from '@mui/icons-material/Circle';

import ContentCopy from '@mui/icons-material/ContentCopy';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import DoneIcon from '@mui/icons-material/Done';
import FacebookIcon from '@mui/icons-material/Facebook';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import LightningIcon from '@mui/icons-material/FlashOn';
import HomeIcon from '@mui/icons-material/Home';
import InstagramIcon from '@mui/icons-material/Instagram';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LogoutIcon from '@mui/icons-material/Logout';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotesIcon from '@mui/icons-material/Notes';
import PauseIcon from '@mui/icons-material/Pause';
import PersonIcon from '@mui/icons-material/Person';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import Troubleshoot from '@mui/icons-material/Troubleshoot';
import TuneIcon from '@mui/icons-material/Tune';
import TwitterIcon from '@mui/icons-material/Twitter';
import VerifiedIcon from '@mui/icons-material/Verified';

import {
  ArrowDownTrayIcon,
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  ArrowUpTrayIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  Bars3Icon,
  BellAlertIcon,
  CalendarDaysIcon,
  CalendarIcon,
  ChatBubbleLeftIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ClockIcon,
  CursorArrowRippleIcon,
  DocumentIcon,
  DocumentMagnifyingGlassIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  ListBulletIcon,
  MinusIcon,
  PlayCircleIcon,
  PlusIcon,
  PresentationChartLineIcon,
  QuestionMarkCircleIcon,
  RocketLaunchIcon,
  ShoppingBagIcon,
  SquaresPlusIcon,
  TrophyIcon,
} from '@heroicons/react/24/solid';
import {
  Award,
  BarLineChart,
  Briefcase,
  Budget,
  Building,
  Building02,
  Building04,
  Building07,
  CheckBoxIcon,
  CheckBoxIntermediateIcon,
  CheckVerified03,
  CodeSnippet02,
  DataFlow,
  DragIcon,
  Email,
  FileSearch,
  GeneralSettings,
  ImageIndentLeft,
  ImageO3,
  Laptop02,
  Lock,
  LockUnlocked,
  MagicWand,
  MarkerPin04,
  MessageCircle01,
  Paint,
  Pencil01,
  Pencil02,
  PencilLine,
  Performance,
  Playbooks,
  ShoppingCart03,
  Target,
  Truck01,
  UserCircle,
  UserSquare,
  Users,
} from '../../assets/icons';
import { AlertTriangle } from '../../assets/icons/alert-triangle';
import { ArrowNarrowDown } from '../../assets/icons/arrow-narrow-down';
import { ArrowNarrowRight } from '../../assets/icons/arrow-narrow-right';
import { ArrowNarrowUp } from '../../assets/icons/arrow-narrow-up';
import { BarChart04 } from '../../assets/icons/bar-chart-04';
import { CampaignIcon } from '../../assets/icons/business-objects/campaign-icon';
import { CreativeIcon } from '../../assets/icons/business-objects/creative-icon';
import { LandingPageIcon } from '../../assets/icons/business-objects/landing-page-icon';
import { Check } from '../../assets/icons/check';
import { DotsVertical } from '../../assets/icons/dots-vertical';
import { GoogleGlobe } from '../../assets/icons/google-globe';
import { Heading01 } from '../../assets/icons/heading-01';
import { Heading02 } from '../../assets/icons/heading-02';
import { ThumbsDown } from '../../assets/icons/line/alerts/thumbs-down';
import { ThumbsUp } from '../../assets/icons/line/alerts/thumbs-up';
import { RefreshCw05 } from '../../assets/icons/line/arrows/refresh-cw-05';
import { Annotation } from '../../assets/icons/line/communication/annotation';
import { CircleCheck } from '../../assets/icons/line/general/circle-check';
import { Eye } from '../../assets/icons/line/general/eye';
import { XClose } from '../../assets/icons/line/general/x-close';
import { LinkExternal02 } from '../../assets/icons/link-external-02';
import { LoadingSpinner } from '../../assets/icons/loading-spinner';
import { Rocket01 } from '../../assets/icons/rocket-01';
import { Search } from '../../assets/icons/search';
import { SocialX } from '../../assets/icons/social/socialX';
import { Phone } from '../../assets/icons/solid/communication/phone';
import { CursorClick02 } from '../../assets/icons/solid/editor/cursor-click-02';
import { Certificate02 } from '../../assets/icons/solid/education/certificate-02';
import { ShoppingCart01 } from '../../assets/icons/solid/finance/shopping-cart-01';
import { CalendarFilled } from '../../assets/icons/solid/general/calendar-filled';
import { HelpCircle } from '../../assets/icons/solid/general/help-circle';
import { Settings01 } from '../../assets/icons/solid/general/settings-01';
import { SolidGeneralTrash04 } from '../../assets/icons/solid/general/trash-04';
import { Zap } from '../../assets/icons/solid/general/zap';
import { MarkerPin06 } from '../../assets/icons/solid/maps/marker-pin-06';
import { LightBulb02 } from '../../assets/icons/solid/media/light-bulb-02';
import { CircleDot } from '../../assets/icons/solid/shapes/circle-dot';
import { StarO1 } from '../../assets/icons/solid/shapes/star-01';
import { Star06 } from '../../assets/icons/solid/shapes/star-06';
import { Tag01 } from '../../assets/icons/tag-01';
import { TextNotification } from '../../assets/icons/text-notification';
import { Type01 } from '../../assets/icons/type-01';

/**
 * Icons sourced from https://heroicons.com/
 */

export const iconSystemMap = {
  home: HomeIcon,
  copy: ContentCopy,
  send: SendIcon,
  moreVert: MoreVertIcon,
  more: MoreHorizIcon,

  noData: Troubleshoot,
  add: AddIcon,
  up: KeyboardArrowUpIcon,
  down: KeyboardArrowDownIcon,
  left: KeyboardArrowLeftIcon,
  right: KeyboardArrowRightIcon,
  contacts: RecentActorsIcon,
  companies: BusinessIcon,
  settings: SettingsIcon,
  workspaceSettings: TuneIcon,
  userSettings: AccountCircleIcon,
  closeMenu: NotesIcon,

  logOut: LogoutIcon,
  darkMode: DarkModeIcon,
  feedback: FeedbackOutlinedIcon,
  support: SupportOutlinedIcon,
  socialLinkedIn: LinkedInIcon,
  socialTwitter: TwitterIcon,
  socialFacebook: FacebookIcon,
  socialInstagram: InstagramIcon,
  socialX: SocialX,
  sort: SwapVertIcon,
  megaphone: CampaignIcon,
  profile: PersonIcon,
  verified: VerifiedIcon,
  play: PlayArrowIcon,
  complete: DoneIcon,
  upload: ArrowUpTrayIcon,
  problem: ExclamationTriangleIcon,
  refresh: RefreshRoundedIcon,
  circle: CircleIcon,
  /* HERO ICONS ONLY BELOW THIS LINE (In the Process of Converting from MUI)*/
  checkCircle: CheckCircleIcon,
  errorMessage: ExclamationTriangleIcon,
  info: InformationCircleIcon,
  document: DocumentMagnifyingGlassIcon,
  hamburgerMenu: Bars3Icon,
  rocket: RocketLaunchIcon,
  squaresPlus: SquaresPlusIcon,
  plus: PlusIcon,
  minus: MinusIcon,
  playCircle: PlayCircleIcon,
  documentSimple: DocumentIcon,
  trophy: TrophyIcon,
  presentationLine: PresentationChartLineIcon,
  arrowUTurnRight: ArrowUturnRightIcon,
  arrowTrendingUp: ArrowTrendingUpIcon,
  arrowTrendingDown: ArrowTrendingDownIcon,
  chevronDown: ChevronDownIcon,
  chevronUp: ChevronUpIcon,
  chevronLeft: ChevronLeftIcon,
  chevronRight: ChevronRightIcon,
  check: Check,
  checkBadge: CheckBadgeIcon,
  clock: ClockIcon,
  shoppingBag: ShoppingBagIcon,
  listBullet: ListBulletIcon,
  chatLeft: ChatBubbleLeftIcon,
  helpCircle: QuestionMarkCircleIcon,
  doubleChevronLeft: ChevronDoubleLeftIcon,
  doubleChevronRight: ChevronDoubleRightIcon,
  notification: BellAlertIcon,
  calendar: CalendarIcon,
  calendarDays: CalendarDaysIcon,
  creditCard: CreditCardIcon,
  cursorArrow: CursorArrowRippleIcon,
  download: ArrowDownTrayIcon,
  undo: ArrowUturnLeftIcon,
  redo: ArrowUturnRightIcon,
  lightning: LightningIcon,
  pause: PauseIcon,
  /* MANUALLY ADDED SVG ICONS ONLY BELOW THIS LINE */
  alertTriangle: AlertTriangle,
  textNotification: TextNotification,
  delete: SolidGeneralTrash04,
  close: XClose,
  barLineChart: BarLineChart,
  briefcase: Briefcase,
  building: Building,
  building02: Building02,
  building04: Building04,
  building07: Building07,
  dataFlow: DataFlow,
  email: Email,
  fileSearch: FileSearch,
  generalSettings: GeneralSettings,
  settings01: Settings01,
  messageCircle01: MessageCircle01,
  pencil01: Pencil01,
  pencil02: Pencil02,
  playbooks: Playbooks,
  userCircle: UserCircle,
  userSquare: UserSquare,
  users: Users,
  pencilLine: PencilLine,
  checkboxIntermediate: CheckBoxIntermediateIcon,
  checkBox: CheckBoxIcon,
  target: Target,
  budget: Budget,
  paint: Paint,
  award: Award,
  performance: Performance,
  drag: DragIcon,
  magicWand: MagicWand,
  lock: Lock,
  lockUnlocked: LockUnlocked,
  assetLibrary: ImageO3,
  heading01: Heading01,
  heading02: Heading02,
  type01: Type01,
  barChart04: BarChart04,
  imageIndentLeft: ImageIndentLeft,
  codeSnippet02: CodeSnippet02,
  checkVerified03: CheckVerified03,
  arrowDown: ArrowNarrowDown,
  arrowUp: ArrowNarrowUp,
  arrowRight: ArrowNarrowRight,
  rocket01: Rocket01,
  externalLink: LinkExternal02,
  shoppingCart03: ShoppingCart03,
  truck01: Truck01,
  laptop02: Laptop02,
  loadingSpinner: LoadingSpinner,
  locations: MarkerPin04,
  dotsVertical: DotsVertical,
  googleGlobe: GoogleGlobe,
  search: Search,
  nominal: CircleCheck,
  context: HelpCircle,
  internalActionRequired: Zap,
  suggestion: LightBulb02,
  goodQuality: ThumbsUp,
  badQuality: ThumbsDown,
  phoneLead: Phone,
  formLead: Annotation,
  goalBrandAwareness: Star06,
  goalLeadGeneration: CursorClick02,
  goalPurchases: ShoppingCart01,
  tag01: Tag01,
  circleDot: CircleDot,
  star01: StarO1,
  MarkerPin06,
  Certificate02,
  review: Eye,
  RefreshCw05: RefreshCw05,
  LandingPageIcon,
  CreativeIcon,
  CampaignIcon,
  calendarFilled: CalendarFilled,
} as const;

export type IconSystemKeys = keyof typeof iconSystemMap;
