import {
  CampaignDeploymentRejectionReasonResource,
  CampaignDeploymentStatusType,
} from '@outbound/types';
import { ObCardSurface } from '../../cards/components/card-surface/ob-card-surface';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { timeAgoFromIsoString } from '../../utilities/datetime-utilities';
import { CardVariantProps } from '../card.type';
import { CampaignDeploymentStatusIcon } from './components/campaign-deployment-status-icon';

export interface CampaignDeploymentCardProps extends CardVariantProps {
  stage: CampaignDeploymentStatusType;
  rejectionReason: CampaignDeploymentRejectionReasonResource | null;
  /**
   * Date and time that the latest deployment was published
   */
  publishedAtTimestamp?: string;
  onNavigateCallback?: () => void;
}

type CampaignDeploymentCardImplementationProps = Omit<
  CampaignDeploymentCardProps,
  'variant'
>;

export const CampaignDeploymentCard = ({
  variant,
  ...props
}: CampaignDeploymentCardProps) => {
  switch (variant) {
    case 'grid':
      return <GridCard {...props} />;
    case 'micro':
      return <MicroCard {...props} />;
    case 'mini':
      return <MiniCard {...props} />;
    case 'table':
      return (
        <ObTypography color='negative'>
          Integration {variant.toLocaleUpperCase()} Not Implemented
        </ObTypography>
      );
  }
};

const GridCard = ({ stage }: CampaignDeploymentCardImplementationProps) => {
  return (
    <ObCardSurface>
      <CampaignDeploymentStatusIcon stage={stage} />
      <ObTypography variant='subtitle2'>
        January 1, 2024 10:00am EST
      </ObTypography>
    </ObCardSurface>
  );
};

const MicroCard = ({
  stage,
  onNavigateCallback,
}: CampaignDeploymentCardImplementationProps) => {
  let message = '';
  if (stage === 'FAILED') {
    message = 'Publishing Failed';
  } else if (stage === 'SUCCEEDED') {
    message = 'Successfully Published';
  } else {
    message = 'Publishing in Progress';
  }
  return (
    <div>
      <div
        onClick={onNavigateCallback}
        role='button'
        className='flex flex-row justify-start items-center gap-1  hover:bg-dark/action/neutral/hover active:bg-dark/action/neutral/active rounded-lg p-1 cursor-pointer'
      >
        <CampaignDeploymentStatusIcon stage={stage} />
        <ObTypography variant='subtitle1'>{message}</ObTypography>
      </div>
    </div>
  );
};

const MiniCard = ({
  stage,
  publishedAtTimestamp,
  rejectionReason,
}: CampaignDeploymentCardImplementationProps) => {
  let message = '';
  if (stage === 'FAILED') {
    message = 'Publishing Failed';
  } else if (stage === 'SUCCEEDED') {
    message = 'Successfully Published';
  } else {
    message = 'Publishing in Progress';
  }
  return (
    <div>
      <div className='flex flex-row justify-start items-center gap-2  rounded-lg p-1'>
        <div className='flex flex-row items-center gap-2'>
          <CampaignDeploymentStatusIcon stage={stage} />
          <ObTypography variant='body1'>{message}</ObTypography>
        </div>
        {['SUCCEEDED', 'FAILED'].includes(stage) && (
          <ObTypography variant='body2'>⋅</ObTypography>
        )}
        <ObTypography
          variant='subtitle2'
          color='secondary'
        >
          {timeAgoFromIsoString(publishedAtTimestamp ?? '')}
        </ObTypography>
      </div>
      {stage === 'FAILED' && rejectionReason !== null && (
        <div className='flex flex-row justify-start items-center gap-2  rounded-lg p-1'>
          <div
            key={rejectionReason.correlationId}
            className='flex flex-row items-center gap-2'
          >
            <ObTypography variant='subtitle3'>
              {rejectionReason.userMessage}
            </ObTypography>
          </div>
        </div>
      )}
    </div>
  );
};
