import { FormDefinition, FormFieldType } from '@outbound/design-system';

export const createWorkspaceForm = (
  isAuthenticated: boolean
): FormDefinition => {
  return {
    id: '1',
    heading: '',
    subHeading: '',
    sections: [
      {
        id: '1',
        heading: '',
        subHeading: '',
        fields: [
          {
            id: 'name',
            label: 'Company Name',
            type: FormFieldType.TEXT,
            helperText: '',
            validationSchema: {
              isRequired: true,
              maxLength: 300,
              minLength: 3,
            },
            fieldTypeSettings: {},
          },
          {
            id: 'email',
            label: 'Work Email',
            type: FormFieldType.EMAIL,

            helperText: '',
            validationSchema: {
              isRequired: true,
            },
            conditionallyHideFieldFunction: () => isAuthenticated,
            fieldTypeSettings: {
              isVisible: false,
            },
          },
        ],
      },
    ],
  };
};
