import { isValidTld, normalizeWebsiteUrl } from '@otbnd/utils';
import {
  ObBrand,
  ObSocialLink,
  ObThinkingButton,
  ObTypography,
} from '@outbound/design-system';
import { ObThinkingStatus } from '@outbound/design-system/src/components/elements/ob-thinking-button/ob-thinking-button';
import { cx } from 'class-variance-authority';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Particles from 'react-particles';
import { useNavigate } from 'react-router-dom';
import type { Engine } from 'tsparticles-engine';
import { loadSlim } from 'tsparticles-slim';
import { useAuth0Extended } from '../../../auth/use-auth0-extended';
import { LinkTermsOfService } from '../../../components/elements/link-customer-terms-of-service';
import { LinkPrivacyPolicy } from '../../../components/elements/link-privacy-policy';
import LegalAgreementCheckbox from '../../../components/organisms/legal-agreement-checkbox/legal-agreement-checkbox';
import { useWebsiteScanMutation } from '../../../query/onboarding/use-onboarding-endpoints';
import { generateParticlesConfigForButton } from './website-capture-cta-particles-config';
import './website-capture-cta.css';

export const WebsiteCapturePage: FC = () => {
  const {
    isLoading,
    isAuthenticated,
    isAuthenticatedWithWorkspace,
    isAuthenticatedWithWorkspaceCheckInProgress,
    isSwitchToUserOnlyAuthenticationInProgress,
    switchToUserOnlyAuthentication,
  } = useAuth0Extended();

  const { onboarding: onboardingEnabled } = useFlags();

  useEffect(() => {
    if (isLoading) {
      console.log('Auth0 SKD is initializing, waiting for completion');
      return;
    }
    if (isSwitchToUserOnlyAuthenticationInProgress) {
      console.log(
        'Switching to User Only Authentication in Progress. Waiting for Redirect'
      );
    }
    if (!isAuthenticated) {
      console.log('User is Not Authenticated. Proceeding with New User Flow');
      return;
    }

    if (isAuthenticatedWithWorkspaceCheckInProgress) {
      console.log(
        'Workspace Authentication Check in Progress, waiting for completion'
      );
      return;
    }
    if (isAuthenticatedWithWorkspace) {
      console.log(
        'User is Authenticated with Workspace, switching to User Only Authentication'
      );
      switchToUserOnlyAuthentication();
      return;
    }
    console.log(
      'User is Authenticated as a User Only. Proceeding with Existing User Flow'
    );
  }, [
    isLoading,
    isAuthenticated,
    isAuthenticatedWithWorkspaceCheckInProgress,
    isAuthenticatedWithWorkspace,
    switchToUserOnlyAuthentication,
    isSwitchToUserOnlyAuthenticationInProgress,
  ]);

  const blueParticles = useMemo(
    () => generateParticlesConfigForButton('#4A4AFA'),
    []
  );
  const purpleParticles = useMemo(
    () => generateParticlesConfigForButton('#00E9FC'),
    []
  );

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadSlim(engine);
  }, []);

  /**
   * Tracks if the checkbox is checked or not
   */
  const [isChecked, setIsChecked] = useState(false);

  const [isDomainInvalid, setIsDomainInvalid] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
    setErrorMessage('');
  };

  const navigate = useNavigate();

  const [domain, setDomain] = useState('');

  const [submitting, setSubmitting] = useState(false);

  const { mutateAsync: scanWebsite } = useWebsiteScanMutation();

  const submitWebsiteCaptureRequest = async (domain: string) => {
    setSubmitting(true);

    try {
      if (domain == null || domain.trim() === '') {
        setErrorMessage('Please enter your website');
        setSubmitting(false);
        setIsDomainInvalid(true);
        return;
      }

      if (domain.includes('@')) {
        setErrorMessage(
          'Did you enter an email? Please enter your business website instead.'
        );
        setSubmitting(false);
        setIsDomainInvalid(true);
        return;
      }

      try {
        const normalizedUrl = normalizeWebsiteUrl(domain);
        if (!isValidTld(normalizedUrl)) {
          const tld = normalizedUrl.hostname.split('.').pop();
          setErrorMessage(
            `Do you have a typo?. "${tld}" is not a recognized top-level domain. It should be something like .com, .org, .net,s etc.`
          );
          setSubmitting(false);
          setIsDomainInvalid(true);
          return;
        }
      } catch (error) {
        setErrorMessage(
          `Please enter a valid website. "${domain}" is not a valid web address.`
        );
        setSubmitting(false);
        setIsDomainInvalid(true);
        return;
      }

      setIsDomainInvalid(false); //If we get here, the domain is valid

      //Check if domain is valid

      if (!isChecked) {
        setErrorMessage(
          `You must agree to the Terms Of Service and Privacy Policy in order to use Outbound.com's services.`
        );
        setSubmitting(false);
        return;
      }
      const response = await scanWebsite({ domain });
      navigate(`/onboarding-wizard?token=${response.token}`);
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      throw error;
    }
  };

  if (
    isLoading ||
    isSwitchToUserOnlyAuthenticationInProgress ||
    isAuthenticatedWithWorkspaceCheckInProgress ||
    isAuthenticatedWithWorkspace
  ) {
    return <></>;
  }

  /**
   * If the flag is not found for some reason we don't want to default to disabling the onboarding
   * so we are strictly checking for false vs undefined
   */
  if (onboardingEnabled === false) {
    return (
      <div className='flex flex-col h-screen background-dark items-center justify-center '>
        <div className='max-w-[1100px] flex flex-col gap-8 items-center p-4'>
          <ObBrand
            variant={'logo'}
            size='medium'
          />

          <div className='bg-dark/background/subtle-informative border-dark/border/subtle-informative border p-4 rounded-lg max-w-2xl gap-3'>
            <ObTypography
              color='lime'
              variant='h4'
              as='h1'
            >
              Sign Ups Currently Paused
            </ObTypography>
            <ObTypography variant='body1'>
              Due to the extraordinarily high demand, sign ups are temporarily
              paused. Follow us on our socials to be the first to know when they
              are opened back up!
            </ObTypography>
          </div>
          <div className='flex flex-row gap-4'>
            <ObSocialLink
              className='border border-dark/border/default/hover p-2 rounded-md flex flex-row gap-2 items-center hover:bg-dark/action/neutral/hover active:bg-dark/action/neutral/active'
              socialMedia={'facebook'}
              handle={'JoinOutbound'}
              size='medium'
            />

            <ObSocialLink
              className='border border-dark/border/default/hover p-2 rounded-md flex flex-row gap-2 items-center hover:bg-dark/action/neutral/hover active:bg-dark/action/neutral/active'
              socialMedia={'instagram'}
              handle={'JoinOutbound'}
              size='medium'
            />

            <ObSocialLink
              className='border border-dark/border/default/hover p-2 rounded-md flex flex-row gap-2 items-center hover:bg-dark/action/neutral/hover active:bg-dark/action/neutral/active'
              socialMedia={'twitter'}
              handle={'JoinOutbound'}
              size='medium'
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className='flex flex-col h-screen background-dark items-center justify-center '>
      <div className='max-w-[1100px] flex flex-col gap-8 items-center p-4'>
        <ObBrand
          variant={'logo'}
          size='medium'
        />
        <ObTypography
          variant='h1'
          className='text-center'
        >
          Launch your marketing in minutes, not months.
        </ObTypography>

        <div className='w-full flex justify-center items-center'>
          <div
            className={cx(
              'dark:bg-bgSurfaceDark  flex flex-row  w-full p-2 sm:p-4 max-w-[700px] rounded-lg sm:rounded-2xl outline-4 sm:outline-8  outline shadow-[0_25px_120px_-15px_rgba(0,0,0,0.1)] shadow-actionPrimaryLight',
              isDomainInvalid
                ? 'outline-dark/border/negative/normal'
                : 'outline-[rgba(50,50,50,0.7)]'
            )}
          >
            <form
              id='brandOnboardingForm'
              className='flex flex-row  w-full'
              autoComplete='off'
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                submitWebsiteCaptureRequest(domain);
              }}
            >
              <label
                id='website-url'
                className='sr-only'
              >
                Your Business Website URL
              </label>
              <input
                aria-labelledby='website-url'
                autoFocus={true}
                tabIndex={1}
                // required
                title='Please enter a valid domain'
                disabled={submitting}
                placeholder='Enter your site to get started'
                type='text'
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                className='bg-opacity-0 dark:bg-bgSurfaceDark border-none focus:ring-0 focus:outline-none text-contentPrimaryDark flex-grow h-full text-xl sm:text-2xl placeholder:text-center sm:placeholder:text-left'
              />
              <div className='hidden sm:block relative'>
                <div className='absolute top-[-84px] left-[-30px] h-[240px] w-[240px] pointer-events-none'>
                  <Particles
                    className='absolute h-[240px] w-[240px] radial-gradient-mask animate-spin-particles '
                    id='tsparticles'
                    init={particlesInit}
                    options={blueParticles}
                  />
                  <Particles
                    className='absolute h-[240px] w-[240px] radial-gradient-mask animate-spin-particles'
                    id='tsparticles2'
                    init={particlesInit}
                    options={purpleParticles}
                  />
                </div>

                <ObThinkingButton
                  iconLeft='rocket'
                  size='xlarge'
                  form='brandOnboardingForm'
                  className='whitespace-nowrap p-4 text-md sm:text-xl'
                  type='submit'
                  label='Get Started'
                  forcedState={
                    submitting ? ObThinkingStatus.PENDING : undefined
                  }
                />
              </div>
            </form>
          </div>
        </div>
        <div className='w-full block sm:hidden'>
          <div className='relative w-full'>
            <div className='absolute  top-[-80px] h-[240px] w-full pointer-events-none overflow-hidden'>
              <Particles
                className='absolute left-[60px] h-[240px] w-[240px] radial-gradient-mask animate-spin-particles '
                id='tsparticles3'
                init={particlesInit}
                options={blueParticles}
              />
              <Particles
                className='absolute left-[140px] h-[240px] w-[240px] radial-gradient-mask animate-spin-particles'
                id='tsparticles4'
                init={particlesInit}
                options={purpleParticles}
              />
            </div>

            <ObThinkingButton
              iconLeft='rocket'
              tabIndex={3}
              size='xlarge'
              form='brandOnboardingForm'
              fullWidth='always'
              className='whitespace-nowrap p-4 text-md sm:text-xl sm:hidden'
              type='submit'
              label='Get Started'
              forcedState={submitting ? ObThinkingStatus.PENDING : undefined}
            />
          </div>
        </div>
      </div>

      <LegalAgreementCheckbox
        checked={isChecked}
        onChange={toggleCheckbox}
        label={'I agree'}
        disabled={submitting}
        errorMessage={''}
        labelNode={
          <ObTypography
            variant='body2'
            color='secondary'
          >
            By checking this box I agree to Outbound Technologies, Inc.'s{' '}
            <LinkTermsOfService /> and <LinkPrivacyPolicy /> on behalf of myself
            and on behalf of the owner of the website entered above.
          </ObTypography>
        }
      />
      <div className='min-h-[36px]'>
        {errorMessage && (
          <ObTypography
            color='negative'
            variant='body2'
          >
            {errorMessage}
          </ObTypography>
        )}
      </div>
    </div>
  );
};
