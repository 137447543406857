'use client';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { cx } from 'class-variance-authority';
import { Fragment, ReactNode } from 'react';

export interface ObActionMenuProps {
  /**
   * @deprecated Use menuOpenButton instead
   * The visual label for the button that opens the menu
   */
  label?: string | ReactNode;
  /**
   * @deprecated Use menuOpenButton instead
   * Provide a label for the button that opens the menu when it is different from the button itself
   * THis is helpful for accessibility in the context of grids or lists.
   * "Edit" may be the visual label on the button but the aria-label should be "Edit item 1"
   */
  ariaLabel?: string; //Should be made mandatory; Need to go back and update all the usages

  /**
   * The children of the component should be ObPopoverMenu.Item(s)
   */
  children: ReactNode;
  /**
   * Expects an ObPopoverMenu.Button
   * This allows the parent to have full control over the button that opens the menu
   * Using this prop will make ariaLabel and label props irrelevant
   *
   * Added this as an escape hatch for the parent to have full control over the button that opens the menu
   * because the current implementation is throwing react nested button errors when the button is
   * an ObButton which is a common use case.
   */
  menuOpenButton?: ReactNode;
}

/**
 * Renders a dropdown menu similar to what you see in the menu bar of macos
 * This intent of this is to be used as an application user interface element vs a dropdown in a form.
 *
 * It is expected that the parent will provide the menu items as children.
 */
export const ObActionMenu = ({
  children,
  menuOpenButton,
}: ObActionMenuProps) => {
  return (
    <Popover>
      <PopoverButton as={Fragment}>{menuOpenButton}</PopoverButton>

      {/* <PopoverOverlay className='fixed inset-0 cursor-default ' /> */}

      {/* This needs to be refactored to use a listbox or something similar to provided better accessibility with keyboard.
      For instance up and down arrow keys should be able to navigate the menu items. Currently tab works but that is not as intuitive
        */}
      <Transition
        enter='transition-opacity duration-200'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-150'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <PopoverPanel
          anchor={{ to: 'bottom end', gap: '4px', padding: '8px' }}
          focus={true}
          portal={true}
          className={cx(
            'dark:bg-dark/background/surface bg-light/background/default', //Background Styles
            'border rounded-lg border-light/border/default/normal dark:border-dark/border/default/normal', //Border Styles
            'px-1 py-1', //Padding Styles
            'shadow-lg', //Effect Styles
            'mx-4',
            'z-50'
          )}
        >
          {children}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};
