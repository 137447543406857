import {
  CampaignDeploymentRejectionReasonResource,
  CampaignDeploymentStatusType,
  ServerDelta,
} from '@outbound/types';
import { makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from '../../base-model';

import { parseObrn } from '@otbnd/utils';
import { RootStore } from '../../root-store';

interface CampaignDeploymentValue {
  tags: string[];
  stage: CampaignDeploymentStatusType;
  publishedAtTimestamp?: string;
  rejectionReason?: CampaignDeploymentRejectionReasonResource;
}

class CampaignDeployment extends BaseModel {
  static readonly paths = {
    ...BaseModel.paths,
    stage: '/stage',
    tags: '/tags',
    publishedAtTimestamp: '/publishedAtTimestamp',
    rejectionReason: '/rejectionReason',
  };

  private _tags: string[];
  private _stage: CampaignDeploymentStatusType;
  private _publishedAtTimestamp: string;
  private _rejectionReason: CampaignDeploymentRejectionReasonResource | null;

  constructor(
    rootStore: RootStore,
    id: string,
    obrn: string,
    value: CampaignDeploymentValue
  ) {
    const scope = parseObrn(obrn).scope;
    super(rootStore, 'campaign/deployment', '1', id, scope, obrn);
    this._id = id;
    this._tags = value.tags;
    this._stage = value.stage;
    this._publishedAtTimestamp = value.publishedAtTimestamp || '';
    /**
     * rejectionReason is optional, establishing a default value of null
     * Explicitly setting the value to null to avoid undefined values when presenting the data in the UI
     **/
    this._rejectionReason = value.rejectionReason ?? null;
  }

  protected makeObservableInternal(): void {
    makeObservable(this, {
      _stage: observable,
      _tags: observable,
      _publishedAtTimestamp: observable,
      _rejectionReason: observable,
    } as any);
  }

  applyPatch(patch: ServerDelta[]): void {
    runInAction(() => {
      for (const delta of patch) {
        if (delta.path === CampaignDeployment.paths.stage) {
          /**
           * Replace is the only supported operation. Stage is required.
           */
          if (delta.op === 'replace') {
            this._stage = delta.value as CampaignDeploymentStatusType;
          }
        } else if (delta.path === CampaignDeployment.paths.tags) {
          /**
           * Replace is the only supported operation for tags. Tags is required.
           * We don't support adding or removing tags. We will replace the entire array.
           **/
          if (delta.op === 'replace') {
            this._tags = delta.value as string[];
          }
        } else if (
          delta.path === CampaignDeployment.paths.publishedAtTimestamp
        ) {
          /**
           * Replace is the only supported operation for publishedAtTimestamp.
           *
           **/
          if (delta.op === 'replace') {
            this._publishedAtTimestamp = delta.value as string;
          }
        } else if (delta.path === CampaignDeployment.paths.rejectionReason) {
          /**
           * Replace is the only supported operation for rejectionReason.
           *
           **/
          if (delta.op === 'replace') {
            this._rejectionReason =
              delta.value as CampaignDeploymentRejectionReasonResource;
          }
        }
      }
    });
  }

  toJson(): Record<string, any> {
    const base = super.toJson();
    return {
      ...base,
      [CampaignDeployment.paths.stage]: this._stage,
      [CampaignDeployment.paths.tags]: this._tags,
      [CampaignDeployment.paths.publishedAtTimestamp]:
        this._publishedAtTimestamp,
      [CampaignDeployment.paths.rejectionReason]: this._rejectionReason,
    };
  }

  get stage(): CampaignDeploymentStatusType {
    return this._stage;
  }

  get publishedAtTimestamp(): string {
    return this._publishedAtTimestamp;
  }

  get rejectionReason(): CampaignDeploymentRejectionReasonResource | null {
    return this._rejectionReason;
  }

  get tags(): string[] {
    return this._tags;
  }

  get isLatest(): boolean {
    /**
     * Campaign Deployments have a docker like tag system.
     * The latest tag is a special tag that is always the latest deployment and helps
     * us to query for the latest deployment without having to query all deployments.
     */
    return this._tags.includes('latest');
  }
}

export default CampaignDeployment;
