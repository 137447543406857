import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CreativeBuilderHeader } from './components/creative-builder-header/creative-builder-header';

import { ColorResource, TemplateMedium } from '@outbound/types';
import { CreativeBuilderZoomableContent } from './components/creative-builder-zoomable-content/creative-builder-zoomable-content';

import {
  AnimatedTemplateProps,
  CreativeInitializingLoader,
  ObButton,
  ObLoadingSpinner,
  ObTypography,
} from '@outbound/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFetchBrandGuide } from '../../query/playbook/use-playbook-settings-brand-guide-endpoints';

import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../state/mobx-experiment/use-root-store';
import { FullPageErrorMessage } from '../dashboard/campaigns/components/full-page-error-message';
import { CreativeBuilderLeftPanel } from './components/creative-builder-left-panel/creative-builder-left-panel';
import { templateComponentMapping } from './template-component-mapping';
import { TemplateSettingsDisplayBuilder } from './template-display-section-builder';

const HEADER_HEIGHT_IN_PX = 64;

type CreativeBuilderPathParams = {
  creativeId: string;
};

const SETTING_QUERY_PARAM = 'setting-id';

/**
 * Shape of the color resource.
 * This type should come from the playbook resource not here.
 */
export type ColorPalette = {
  primaryColor: ColorResource;
  secondaryColor: ColorResource;
};

export const CreativeBuilderPage: FC = observer(() => {
  const {
    creativeRemixFeature: FF_enableRemixFeature,
    creativeUndoRedoFeature: FF_undoRedoFeature,
  } = useFlags();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { creativeStore } = useRootStore();

  /**
   * Pull the Creative ID To Load from the URL
   */
  const { creativeId } = useParams<CreativeBuilderPathParams>();

  /**
   * Get the Creative from the Store
   * (If it needs to be lazy loaded a promise will be thrown)
   */
  const creative = creativeStore.getById(creativeId!);

  console.log('Creative', creative, creative?.template);

  const [sectionExpansionState, setSectionExpansionState] = useState<
    Record<string, boolean>
  >(
    creative?.template?.builderSections.reduce(
      (acc, section) => {
        acc[section.id] = true;
        return acc;
      },
      {} as Record<string, boolean>
    ) ?? {}
  );

  const toggleSectionExpansion = (sectionId: string) => {
    setSectionExpansionState((prev) => {
      const newState = { ...prev };
      newState[sectionId] = !newState[sectionId];
      return newState;
    });
  };

  // Get a specific query parameter by name
  const settingIdToFocus = searchParams.get(SETTING_QUERY_PARAM);

  /**
   * Sonar complains useState call is not destructured into value + setter pair
   * due to not "Destructuring these values and naming them symmetrically"
   * We are capitalizing the first letter of "TemplateComponent" since it is a component
   */
  //NOSONAR
  const [TemplateComponent, setTemplateComponent] =
    useState<React.FC<AnimatedTemplateProps> | null>(null);

  //Access a query parameter called setting-id using react-router-dom

  /**
   * State used to track if the creative is playing or paused
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [playbackValue, _setPlaybackValue] = useState<'play' | 'pause'>('play');

  /**
   * When we want to force a setting to be focused we set this state.
   * We need to be mindful of this states interactions with the focusedSettingId state.
   */
  const [settingToFocus, setSettingToFocus] = useState<string | null>(
    settingIdToFocus ?? null
  );

  /**
   *  Tracks the last setting that was focused by the user. We do not clear this on blur.
   */
  const [focusedSettingId, setFocusedSettingId] = useState<string | null>(null);

  /**
   * Takes an Input of field arrays and our section structure
   * and returns a combined data-model that simplifies rendering for the
   * frontend
   */
  const creativeBuilderSettingSections =
    creative?.template != null
      ? new TemplateSettingsDisplayBuilder(creative.template, creative)
      : null;

  /**
   * Future Implementation for Remix Feature
   * This was originally connected in a local only implementation.
   * When we migrated to mobx we disconnected this. We will at some point potentially
   * add this back in a more generative AI driven way.
   */
  const handleRemix = () => {
    console.log('Remix');
  };

  /**
   * Future Implementation for Undo/Redo Feature
   * We should implement this in MobX
   */
  const undo = () => {
    console.log('Undo');
  };

  /**
   * Future Implementation for Undo/Redo Feature
   * We should implement this in MobX
   */
  const redo = () => {
    console.log('Redo');
  };

  /**
   * This should be migrated to MobX
   * Fetch the brand colors from the playbook
   */
  const { data: brandColors } = useFetchBrandGuide();

  /**
   * This should be migrated to MobX
   */
  const [colorPalette, setColorPalette] = useState<ColorPalette>();

  /**
   * Sets up a local cache of the brand color palette for use by the creative builder
   */
  useEffect(() => {
    if (brandColors) {
      const palette = {
        primaryColor: brandColors.primaryColor,
        secondaryColor: brandColors.secondaryColor,
      };
      setColorPalette(palette as any); //TEMP: FIX TYPES
    }
  }, [brandColors]);

  /**
   * Local UI State, Tracks if the Left Panel is open or closed
   * Will not survive a page refresh. We should at some point move this down to a local store
   */
  const [showLeftPanel, setShowLeftPanel] = useState<boolean>(true);
  /**
   * Tracks the current width of the left panel (Does not indicate if the panel is open or closed)
   * In the future we may want to let the user set this width via dragging or we
   * may control it based on screen size etc.
   */
  const [leftPanelOpenWidth] = useState<number>(350);
  const [totalPanelWidth, setTotalPanelWidth] =
    useState<number>(leftPanelOpenWidth);

  /**
   * Handle 'Command + shift + \' to toggle the left panel open and closed
   * @param event
   */
  const handleToggleLeftPanelKeyCommand = (event: KeyboardEvent) => {
    if (event.key === '\\' && event.shiftKey && event.metaKey) {
      setShowLeftPanel(!showLeftPanel);
      event.preventDefault(); // Prevent the default behavior
    }
  };

  const handleUndoKeyCommand = (event: KeyboardEvent) => {
    if (event.key === 'z' && event.metaKey && !event.shiftKey) {
      undo();
      event.preventDefault(); // Prevent the default behavior
    }
  };

  const handleRedoKeyCommand = (event: KeyboardEvent) => {
    if (event.key === 'z' && event.shiftKey && event.metaKey) {
      redo();
      event.preventDefault(); // Prevent the default behavior
    }
  };

  const handleRemixKeyCommand = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && event.metaKey) {
      handleRemix();
      event.preventDefault(); // Prevent the default behavior
    }
  };

  const registerAllKeyboardShortcuts = (event: KeyboardEvent) => {
    handleToggleLeftPanelKeyCommand(event);
    if (FF_enableRemixFeature) {
      handleRemixKeyCommand(event);
    }

    handleRedoKeyCommand(event);
    handleUndoKeyCommand(event);
  };

  useEffect(() => {
    if (
      creative?.template.renderComponent &&
      templateComponentMapping.hasOwnProperty(creative.template.renderComponent)
    ) {
      setTemplateComponent(() =>
        observer(templateComponentMapping[creative.template.renderComponent])
      );
    }
  }, [creative]);

  /**
   * Register Keyboard Shortcuts
   */
  useEffect(() => {
    document.addEventListener('keydown', registerAllKeyboardShortcuts);
    return () => {
      document.removeEventListener('keydown', registerAllKeyboardShortcuts);
    };
  });

  useEffect(() => {
    setTotalPanelWidth(showLeftPanel ? leftPanelOpenWidth : 0);
  }, [showLeftPanel, leftPanelOpenWidth]);

  /**
   * Prevent zooming and scrolling on the page
   */
  useEffect(() => {
    const preventZoomIds = [
      'ob-creative-builder__zoomable-content',
      'ob-creative-builder__header',
    ];
    const handleWheel = (e: WheelEvent) => {
      // Function to check if the event target is inside the specified element or is the element itself
      let target: EventTarget | null = e.target; // Adjust the type as needed

      let isInsideZoomableContent = false;

      // Traverse up the DOM tree to check if we're inside the zoomable content area
      while (target != null) {
        if (preventZoomIds.includes((target as HTMLElement).id)) {
          isInsideZoomableContent = true;
          break;
        }
        target = (target as HTMLElement).parentNode;
      }

      // If the wheel event occurred inside the zoomable content area, prevent the default action
      if (isInsideZoomableContent) {
        e.preventDefault();
      }
    };

    document.addEventListener('wheel', handleWheel, { passive: false });

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);

  /**
   * Handle the Not Found Case
   */
  if (!creative) {
    return (
      <FullPageErrorMessage
        heading={'Creative Not Found'}
        message={'This creative was not found in your workspace.'}
        actionLabel={'Go Back'}
        actionOnClick={() => navigate(-1)}
      />
    );
  }

  return (
    <main className='w-full h-screen background-dark'>
      <div className='flex sm:hidden flex-col gap-2 justify-center items-center p-8 h-full'>
        <ObTypography
          className='text-center'
          variant='h3'
          as='h1'
        >
          Display Too Small
        </ObTypography>
        <ObTypography
          className='text-center'
          variant='body2'
          color='secondary'
        >
          In order to edit your creative please expand the page or use a device
          with a larger display.
        </ObTypography>
        <ObButton
          label='Back'
          size='medium'
          fullWidth='mobile'
          onClick={() => navigate(-1)}
        />
      </div>
      <section className='hidden sm:flex flex-row '>
        {TemplateComponent &&
        creative &&
        creative.lifecycleStatus === 'ACTIVE' ? (
          <CreativeBuilderZoomableContent
            viewableAreaCoveredWithPanelsX={totalPanelWidth}
            viewableAreaCoveredWithPanelsY={HEADER_HEIGHT_IN_PX}
            floatingControlsSlot={<></>}
            leftControlsSlot={
              <>
                {FF_undoRedoFeature && (
                  <>
                    <ObButton
                      iconLeft='undo'
                      variant='outline'
                      buttonType='icon'
                      size='medium'
                      label='Undo'
                      disabled={false} //Disable this when we have no undo history
                      onClick={undo}
                    />
                    <ObButton
                      iconLeft='redo'
                      variant='outline'
                      buttonType='icon'
                      size='medium'
                      label='Redo'
                      disabled={false} //Disable this when we have no redo history
                      onClick={redo}
                    />
                  </>
                )}
              </>
            }
          >
            <TemplateComponent
              templateId={creative.template.id}
              templateName={creative.template.name}
              templateVersion={'1'}
              values={creative.settings}
              focusedSettingId={focusedSettingId}
              onSettingClicked={(settingId: string) => {
                setSettingToFocus(settingId);
              }}
              playbackControls={{
                animate: true,
                playback: playbackValue,
                loop: true,
              }}
            />
          </CreativeBuilderZoomableContent>
        ) : (
          <div className='absolute left-[50%] top-[50%]'>
            <div className='flex justify-center items-center flex-col gap-3'>
              {!creative && (
                <ObLoadingSpinner
                  includeBrand={true}
                  fadeIn={true}
                />
              )}
              {creative && creative.lifecycleStatus !== 'ACTIVE' && (
                <CreativeInitializingLoader estimatedPercentComplete={0} />
              )}
            </div>
          </div>
        )}
        {/* 
        At the bottom due to the fact that we are absolutely positioning these elements so that
         the creative "Canvas" content can operate independently of opened or closed panels.
         We are making the choice to not use css flex or grid for this layout so that we can
         completely decouple the content from the panels and control the experience. 
        */}
        <CreativeBuilderHeader
          name={creative.name}
          onViewSpecificErrorCallback={(settingId: string) => {
            setSettingToFocus(settingId);
          }}
          onBackButtonCallback={() => {
            navigate(-1); //TEMPORARY IMPLEMENTATION
          }}
          lifecycleStatus={creative.lifecycleStatus}
          template={creative.template}
          id={creative.id}
          isReviewed={creative.isReviewed}
          isValid={creative.isValid}
          medium={creative.medium}
          validationErrors={creative.validationErrors}
        />
        <CreativeBuilderLeftPanel
          FF_enableRemixFeature={false} //Connect to LD When Ready to Start Development
          settingIdToFocus={settingToFocus}
          onSettingFocused={(settingId) => {
            setFocusedSettingId(settingId);
          }}
          isDisabled={creative?.lifecycleStatus !== 'ACTIVE'}
          showPanel={showLeftPanel}
          includeTemplateGallery={
            creative?.template.medium !== TemplateMedium.GOOGLE_SEARCH
          }
          colorPalette={colorPalette}
          settingExpansionState={sectionExpansionState}
          onSectionExpansionToggle={toggleSectionExpansion}
          settingSections={
            creativeBuilderSettingSections != null
              ? creativeBuilderSettingSections.getOrganizedSections()
              : []
          }
          panelWidth={leftPanelOpenWidth}
          pageHeaderHeight={HEADER_HEIGHT_IN_PX}
          handleFieldUpdated={() => console.log('Field Updated')}
          handleFieldLockToggle={(_field: any) => console.log('Lock Toggle')}
          handleFieldRemix={(_value: any) => {
            console.log('Remix');
          }}
          onClickRemixSectionCallback={(sectionId: string) => {
            console.log('Remix Section', sectionId);
          }}
        />
      </section>
      <footer></footer>
    </main>
  );
});
