import { ObCustomerStatus } from '@outbound/types';
import { cva } from 'class-variance-authority';

export const determineColorForCustomerStatus = (status: ObCustomerStatus) => {
  switch (status) {
    case 'customer-action-required':
      return 'warning';
    case 'internal-action-required':
      return 'primaryV2';
    case 'suggestion':
      return 'secondary';
    case 'nominal':
      return 'positive';
    default:
      return 'primary';
  }
};

export const containerStyles = cva('border', {
  variants: {
    healthStatus: {
      ['not-applicable']: [''],
      nominal: [
        'bg-dark/background/subtle-positive',
        'border-dark/border/subtle-positive',
      ],
      'internal-action-required': [
        'border-dark/border/default/normal',
        'bg-dark/background/subtle-primary',
      ],
      'customer-action-required': [
        ' border-dark/border/subtle-warning bg-dark/background/subtle-warning',
      ],
      suggestion: [''],
    },
  },
});
