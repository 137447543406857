import { FormDefinition, FormFieldType } from '@outbound/design-system';

export type SupportFormSchema = {
  phone: string;
  subject: string;
  message: string;
};

export const SUPPORT_FORM_DEF: FormDefinition = {
  id: 'create-service-mini-form',
  sections: [
    {
      id: '',
      heading: '',
      subHeading: '',
      fields: [
        {
          id: 'phone',
          autofocus: true,
          label: 'Phone Number',
          helperText: '',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: true,
          },
        },
        {
          id: 'subject',
          autofocus: true,
          label: 'Subject',
          helperText: '',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: true,
          },
        },
        {
          id: 'message',
          autofocus: true,
          label: 'Message',
          helperText: '',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT_AREA,
          validationSchema: {
            isRequired: true,
          },
        },
      ],
    },
  ],
  heading: '',
  subHeading: '',
};
