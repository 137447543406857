import { ObDivider, ObTypography } from '@outbound/design-system';

export interface PlaybookPageSectionHeadingProps {
  title: string;
  controls?: React.ReactNode;
}

export const PlaybookPageSectionHeading = ({
  title,
  controls,
}: PlaybookPageSectionHeadingProps) => {
  return (
    <div className='flex flex-col gap-3'>
      <div className='flex flex-row justify-between items-center'>
        <ObTypography
          className='hidden lg:block'
          variant='h3'
        >
          {title}
        </ObTypography>
        <div>{controls}</div>
      </div>

      <ObDivider className='my-3' />
    </div>
  );
};
