import { ObCustomerStatus } from '@outbound/types';
import { cx } from 'class-variance-authority';
import { ObButton } from '../../../../components/elements/ob-button/ob-button';
import { ObTypography } from '../../../../components/elements/ob-typography/ob-typography';
import { containerStyles, determineColorForCustomerStatus } from './color-util';

interface CampaignHealthTodoCtaProps {
  actionLabel?: string;
  onActionButtonClicked?: () => void;
  documentationHref?: string;
}

export interface CampaignHealthTodoProps extends CampaignHealthTodoCtaProps {
  healthStatus: ObCustomerStatus;
  completionStatus: 'TODO' | 'DONE' | 'BLOCKED';
  title: string;
  description: string;
  index: number;
}
export const CampaignHealthTodo = ({
  healthStatus,
  completionStatus,
  index,
  title,
  description,
  actionLabel,
  onActionButtonClicked,
  documentationHref,
}: CampaignHealthTodoProps) => {
  const color = determineColorForCustomerStatus(healthStatus);
  return (
    <div className='flex gap-2 flex-col @container/campaign-health-todo'>
      <div className='flex gap-4 flex-row items-center'>
        <div
          className={cx(
            ' justify-center items-center w-10 h-10 min-h-10 min-w-10 border rounded-full  hidden @sm/campaign-health-alert:flex flex-shrink-0',
            containerStyles({ healthStatus })
          )}
        >
          <ObTypography
            variant='h4'
            as='span'
            className='text-center'
          >
            {index + 1}
          </ObTypography>
        </div>
        <div className='flex flex-col'>
          <div className='flex gap-2 items-center'>
            <div className='flex @sm/campaign-health-alert:hidden'>
              <ObTypography
                variant='h4'
                as='span'
                color={color}
                className='text-center'
              >
                {index + 1}.
              </ObTypography>
            </div>
            <ObTypography
              variant='h4'
              className={completionStatus === 'DONE' ? 'line-through' : ''}
            >
              {title}
            </ObTypography>
          </div>
          <ObTypography
            variant='body1'
            color='secondary'
            className={cx(
              'ml-5 @sm/campaign-health-alert:ml-0',
              completionStatus === 'DONE' ? 'line-through' : ''
            )}
          >
            {description}
          </ObTypography>
        </div>
      </div>
      <div className='flex gap-2 ml-4 @sm/campaign-health-alert:ml-[56px]'>
        {completionStatus != 'DONE' && documentationHref && (
          <div className='flex @sm/campaign-health-alert:flex-0'>
            <ObButton
              label='Learn More'
              onClick={() => {
                window.open(documentationHref, '_blank', 'noopener');
              }}
              size='medium'
              variant='secondary'
              fullWidth='mobile'
            />
          </div>
        )}
        {completionStatus != 'DONE' && actionLabel && onActionButtonClicked && (
          <div className='flex @sm/campaign-health-alert:flex-0'>
            <ObButton
              label={actionLabel}
              onClick={onActionButtonClicked}
              size='medium'
              variant='secondary'
              fullWidth='mobile'
            />
          </div>
        )}
      </div>
    </div>
  );
};
