import {
  BookATimeToLearnMore,
  useDialogService,
} from '@outbound/design-system';
import { AppFeatures, FeatureIdentifier } from './feature-list';

export const useFeatureUpSell = () => {
  const { openDialog } = useDialogService();

  const showFeatureUpSellDialog = (featureId: FeatureIdentifier) => {
    const feature = AppFeatures[featureId];

    /**
     * We are currently only supporting the book a time call to action type. Once we have Stripe fully enabled and have launched
     * a paid plan we will also support the in-app-upgrade call to action type.
     */
    if (feature.getFeatureCallToActionType === 'book-a-time') {
      if (
        feature.getFeatureBookATimeMeetingOwner === null ||
        feature.getFeatureBookATimeMeetingName === null
      ) {
        console.error(
          'Feature is missing a meeting owner and / or a meeting id'
        );
        return;
      }
      return openDialog({
        hideTitle: true,
        hideDefaultActions: true,

        dialogContent: (
          <BookATimeToLearnMore
            onTimeBookedCallback={function (): void {
              throw new Error('Function not implemented.');
            }}
            id={feature.id}
            name={feature.name}
            getFeatureCallToAction={feature.getFeatureCallToAction}
            getFeatureCallToActionPitch={feature.getFeatureCallToActionPitch}
            availableOnPlans={feature.availableOnPlans}
            meetingOwner={feature.getFeatureBookATimeMeetingOwner}
            meetingName={feature.getFeatureBookATimeMeetingName}
          />
        ),
        title: 'Learn More',
      });
    }
  };

  return {
    showFeatureUpSellDialog,
  };
};
