import { ReactNode, useEffect, useRef, useState } from 'react';
import { ObChangeManagementBar } from '../../../components/molecules/ob-change-management-bar/ob-change-management-bar';
import { ChangeStatusType } from '../../../components/molecules/ob-change-management-bar/ob-change-management-bar.content';

export interface ObChangeManagementBarTemplateProps {
  /**
   * The content of the drawer body
   */
  children?: ReactNode;
  /**
   * Indicates if the form is dirty
   */
  isDirty: boolean;
  /**
   * Indicates if campaign has been launched
   */
  isLaunched: boolean;
  /**
   * Indicates if changes were rejected
   */
  isRejected: boolean;

  isReadyToPublish: boolean;

  isAiGenerationInProgress: boolean;

  onViewCreativeCallback?: () => any;
  onViewIssuesCallback?: () => any;
  onPublishCallback?: () => any;
}

const determineStatus = (
  isLaunched: boolean,
  isDirty: boolean,
  isRejected: boolean,
  isReadyToPublish: boolean,
  isAiGenerationInProgress: boolean
): ChangeStatusType => {
  if (!isLaunched) {
    if (isAiGenerationInProgress) {
      return 'publishing-blocked-due-ai-generation';
    }
    if (isReadyToPublish) {
      return 'ready-to-launch';
    } else {
      return 'publishing-blocked-due-to-issues';
    }
  }
  if (isDirty) {
    if (!isLaunched) {
      return 'ready-to-launch';
    }
    return 'unpublished';
  }

  if (isLaunched) {
    if (isRejected) {
      return 'rejected';
    }
  }

  return 'unpublished';
};

export const ObChangeManagementBarTemplate = ({
  children,
  isDirty = true,
  isLaunched = false,
  isRejected = false,
  isReadyToPublish,
  isAiGenerationInProgress,
  // Does onSubmitCallback handle multiple actions that is determined at a higher level? ex: Launch, Submit, View issues etc. or do we create a callback for each action
  onPublishCallback,
  onViewCreativeCallback,
  onViewIssuesCallback,
}: ObChangeManagementBarTemplateProps) => {
  /**
   * Make the component resilient to re-renders due to parent renders.
   * This is to prevent the status bar from animating in and out on every render.
   * We only want to animate the status bar in and out when the status changes or when the component first renders.
   **/
  const isFirstRender = useRef(true);
  /**
   * Used to trigger the animation in and out of the status bar
   */
  const [visible, setVisible] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<ChangeStatusType>(
    determineStatus(
      isLaunched,
      isDirty,
      isRejected,
      isReadyToPublish,
      isAiGenerationInProgress
    )
  );

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  /**
   * When isDirty, isLaunched, isInReview, or isRejected is updated, animate the bar in and out, so there isn't a drastic switch/jump between status bar changes
   */
  useEffect(() => {
    setVisible(false);
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 50); // Adds small delay, so the animation can transition off screen between status types
    return () => clearTimeout(timeout);
  }, [currentStatus]);

  useEffect(() => {
    setCurrentStatus(
      determineStatus(
        isLaunched,
        isDirty,
        isRejected,
        isReadyToPublish,
        isAiGenerationInProgress
      )
    );
  }, [
    isDirty,
    isLaunched,
    isRejected,
    isReadyToPublish,
    isAiGenerationInProgress,
  ]);

  const shouldShowFooter = !(
    isDirty === false &&
    isLaunched === true &&
    isRejected === false
  );

  const determineCallbacks = () => {
    switch (currentStatus) {
      case 'ready-to-launch':
        return {
          primary: onPublishCallback,
        };
      case 'publishing-blocked-due-ai-generation':
        return {
          primary: onViewCreativeCallback,
        };
      case 'publishing-blocked-due-to-issues':
      case 'rejected':
      case 'unpublished':
      default:
        return {
          primary: onViewIssuesCallback,
        };
    }
  };

  const { primary: primaryButtonCallback } = determineCallbacks();

  return (
    <aside className='min-h-screen relative'>
      <div className={`pb-[100px] min-h-screen transition-all duration-300`}>
        {children}
      </div>
      <div
        data-testid='change-management-bar'
        className={`sticky bottom-0  w-full p-6 transition-all duration-300 ease-linear ${
          shouldShowFooter && visible
            ? 'opacity-100 visible'
            : 'opacity-0 invisible'
        }`}
        style={{
          maxHeight: shouldShowFooter && visible ? '150px' : '0',
        }}
      >
        <ObChangeManagementBar
          primaryButtonCallback={primaryButtonCallback}
          changeStatus={currentStatus}
        />
      </div>
    </aside>
  );
};
