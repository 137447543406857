import { FC } from 'react';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObButton } from '../../elements/ob-button/ob-button';
import { ObTypography } from '../../elements/ob-typography/ob-typography';
import {
  ChangeStatusType,
  changeManagementBarContent,
} from './ob-change-management-bar.content';

export interface ObChangeManagementBarProps {
  /**
   * Status of Changes
   */
  changeStatus: ChangeStatusType;

  primaryButtonCallback?: () => any;
  secondaryButtonCallback?: () => any;
}

export const ObChangeManagementBar: FC<ObChangeManagementBarProps> = ({
  changeStatus,
  primaryButtonCallback,
  secondaryButtonCallback,
}: ObChangeManagementBarProps) => {
  const {
    title,
    subtitle,
    icon,
    iconColor,
    primaryButtonText,
    secondaryButtonText,
  } = changeManagementBarContent[changeStatus];

  return (
    <div
      data-testid='change-management-bar-container'
      className='flex-1 p-3 rounded-lg lg:rounded-full items-center flex flex-col	lg:flex-row pr-4 lg:pr-7 bg-dark/background/surface+1 max-w-screen-xl m-auto'
    >
      <div className='flex justify-start flex-grow lg:pl-2 items-center'>
        <ObIcon
          icon={icon}
          size='medium'
          color={iconColor}
          data-testid='change-management-bar-icon'
        />
        <div className='pl-3'>
          <ObTypography variant='h4'>{title}</ObTypography>
          <ObTypography
            variant='body3'
            color='secondary'
          >
            {subtitle}
          </ObTypography>
        </div>
      </div>

      <div className='flex flex-col-reverse	lg:flex-row justify-end'>
        {secondaryButtonText && (
          <ObButton
            buttonType='text'
            label={secondaryButtonText}
            size='medium'
            variant='ghost'
            className='lg:mr-3'
            style={{ color: '#fff' }}
            data-testid='change-management-bar-secondary-button'
            onClick={secondaryButtonCallback}
          />
        )}
        <ObButton
          buttonType='text'
          label={primaryButtonText}
          size='medium'
          variant='outline'
          className='my-3 lg:my-0'
          style={{ backgroundColor: 'transparent' }}
          data-testid='change-management-bar-primary-button'
          onClick={primaryButtonCallback}
        />
      </div>
    </div>
  );
};
