import { ObCustomerStatus } from '@outbound/types';
import { FC } from 'react';
import { ObIcon, ObIconProps } from '../../../tokens/icons/ob-icon/ob-icon';

export interface ObCxStatusProps extends Pick<ObIconProps, 'size'> {
  status: ObCustomerStatus;
}

export const ObCxStatus: FC<ObCxStatusProps> = ({
  status,
  size = 'medium',
}: ObCxStatusProps) => {
  const lookupIconPropsForStatus = (
    status: ObCustomerStatus
  ): Pick<ObIconProps, 'icon' | 'color'> => {
    switch (status) {
      case 'nominal':
        return {
          icon: 'nominal',
          color: 'positive',
        };
      case 'internal-action-required':
        return {
          icon: 'internalActionRequired',
          color: 'primary',
        };
      case 'customer-action-required':
        return {
          icon: 'errorMessage',
          color: 'warning',
        };
      case 'suggestion':
        return {
          icon: 'suggestion',
          color: 'lime',
        };
      case 'not-applicable':
        return {
          icon: 'close',
          color: 'primary',
        };
    }
  };

  return (
    <ObIcon
      size={size}
      {...lookupIconPropsForStatus(status)}
    />
  );
};
