import { parseObrn } from '@otbnd/utils';
import { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const OnboardingQuestions = {
  industry: 'industry',
  businessName: 'business-name',
  productOrService: 'product-or-service',
  advertisingGoal: 'advertising-goal',
  locations: 'locations',
} as const;

export type OnboardingQuestionParamValues =
  (typeof OnboardingQuestions)[keyof typeof OnboardingQuestions];

export const useAppNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { workspaceSlug } = useParams();

  const getWorkspaceSlug = (providedWorkspaceSlug?: string) => {
    let finalWorkspaceSlugToNavigateTo = providedWorkspaceSlug;
    if (
      finalWorkspaceSlugToNavigateTo == null ||
      finalWorkspaceSlugToNavigateTo.trim() === ''
    ) {
      //Check for a workspace slug in the URL and navigate to that if it exists
      finalWorkspaceSlugToNavigateTo = workspaceSlug;
    }

    if (
      finalWorkspaceSlugToNavigateTo == null ||
      finalWorkspaceSlugToNavigateTo.trim() === ''
    ) {
      //If no workspace was provided and slug is in the url, navigate to the workspace picker
      navigate('/my-workspaces');
    }

    return finalWorkspaceSlugToNavigateTo;
  };

  const navigateToWorkspacePicker = () => {
    navigate('/my-workspaces');
  };

  /**
   * Navigate to the workspace home page
   * @param workspaceSlugToNavigateTo Optional workspace slug to navigate to. If not provided, will navigate to the current workspace or the workspace picker if no workspace is active
   */
  const navigateToWorkspaceHome = (workspaceSlugToNavigateTo?: string) => {
    navigate(`/${getWorkspaceSlug(workspaceSlugToNavigateTo)}/dashboard`);
  };

  const navigateToCreativeBuilder = (
    campaignId: string,
    config?: { settingIdToFocus: string } | undefined
  ) => {
    navigate(
      `/creative-builder/${campaignId}${
        config?.settingIdToFocus ? `?setting-id=${config.settingIdToFocus}` : ''
      }`
    );
  };

  const navigateToCampaignsList = (workspaceSlug?: string) => {
    navigate(`/${getWorkspaceSlug(workspaceSlug)}/campaigns`);
  };

  const navigateToCampaignById = (
    campaignId: string,
    workspaceSlug?: string,
    hideNav: boolean = false
  ) => {
    const finalUrl = `/${getWorkspaceSlug(
      workspaceSlug
    )}/campaigns/${campaignId}/details${hideNav ? '?hide-side-nav' : ''}`;
    navigate(finalUrl);
  };

  const navigateToCampaignByObrn = (
    campaignObrn: string,
    hideNav: boolean = false
  ) => {
    const {
      scope,
      localPathId: campaignId,
      objectType,
    } = parseObrn(campaignObrn);
    if (objectType !== 'campaign') {
      throw new Error('OBRN is not a campaign');
    }
    const finalUrl = `/${scope}/campaigns/${campaignId}/details${
      hideNav ? '?hide-side-nav' : ''
    }`;
    navigate(finalUrl);
  };

  const navigateToFirstCampaignForm = useCallback(
    (workspaceSlug: string) => {
      navigate(`/onboarding/${workspaceSlug}/first-campaign`);
    },
    [navigate]
  );

  const navigateToTheMarketingPlan = useCallback(
    ({ showLoadingIndicator }: { showLoadingIndicator: boolean }) => {
      if (showLoadingIndicator) {
        navigate(`/onboarding/building-your-plan`);
      } else {
        navigate(`/onboarding/your-marketing-plan`);
      }
    },
    [navigate]
  );

  const navigateToService = (serviceId: string, workspaceSlug?: string) => {
    navigate(
      `/${getWorkspaceSlug(
        workspaceSlug
      )}/playbook/products-and-services?service-id=${serviceId}`
    );
  };

  const navigateToServiceWithObrn = (serviceOfferingObrn: string) => {
    const { localPathId } = parseObrn(serviceOfferingObrn);
    navigateToService(localPathId);
  };

  const navigateToServiceOfferingWithObrn = (serviceOfferingObrn: string) => {
    const { localPathId } = parseObrn(serviceOfferingObrn);
    const [serviceId, serviceOfferingId] = localPathId.split('/');
    navigateToServiceOffering(serviceId, serviceOfferingId);
  };

  const navigateToServiceOffering = (
    serviceId: string,
    serviceOfferingId: string,
    workspaceSlug?: string
  ) => {
    navigate(
      `/${getWorkspaceSlug(
        workspaceSlug
      )}/playbook/products-and-services?service-id=${serviceId}&service-offering-id=${serviceOfferingId}`
    );
  };

  const navigateToCustomerProfileWithObrn = (
    customerProfileObrn: string,
    workspaceSlug?: string
  ) => {
    const { localPathId } = parseObrn(customerProfileObrn);
    navigate(
      `/${getWorkspaceSlug(
        workspaceSlug
      )}/playbook/customer-personas?customer-profile-id=${localPathId}`
    );
  };

  const navigateToPickSubscriptionPage = () => {
    navigate('/onboarding/pick-subscription');
  };

  const navigateToOnboardingQuestionnaire = (
    question?: OnboardingQuestionParamValues
  ) => {
    navigate(
      `/onboarding/questionnaire${question ? `?question=${question}` : ``}`
    );
  };

  const navigateToLandingPageWithObrn = (landingPageObrn: string) => {
    const { localPathId, scope } = parseObrn(landingPageObrn);
    navigate(`/${scope}/landing-pages/${localPathId}`);
  };

  const navigateToLocationWithObrn = (locationObrn: string) => {
    const { localPathId, workspaceId } = parseObrn(locationObrn);
    navigate(`/${workspaceId}/playbook/locations?location-id=${localPathId}`);
  };

  const removeQueryParamWithoutImpactingHistory = (paramToRemove: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(paramToRemove);
    // Replace the current entry in the history stack
    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  };

  const navigateToIntegrationConfigurationBySlug = (
    integrationSlug: string,
    withReturnTo?: boolean,
    returnToLabel?: string
  ) => {
    let destinationPath = `/${workspaceSlug}/settings/workspace/integrations/${integrationSlug}`;
    if (withReturnTo) {
      const currentPath = location.pathname;
      destinationPath += `?return-to=${encodeURIComponent(currentPath)}`;
      if (returnToLabel) {
        destinationPath += `&return-to-label=${encodeURIComponent(
          returnToLabel
        )}`;
      }
    }
    navigate(destinationPath);
  };

  const navigateToPlanSettings = () => {
    navigate(`/${workspaceSlug}/settings/workspace/plan`);
  };

  return {
    navigateToIntegrationConfigurationBySlug,
    navigateToWorkspacePicker,
    navigateToCreativeBuilder,
    navigateToCampaignsList,
    navigateToCampaignById,
    navigateToCampaignByObrn,
    navigateToTheMarketingPlan,
    navigateToFirstCampaignForm,
    navigateToPickSubscriptionPage,
    navigateToOnboardingQuestionnaire,
    removeQueryParamWithoutImpactingHistory,
    navigateToService,
    navigateToServiceWithObrn,
    navigateToServiceOffering,
    navigateToServiceOfferingWithObrn,
    navigateToLocationWithObrn,
    navigateToCustomerProfileWithObrn,
    navigateToWorkspaceHome,
    navigateToLandingPageWithObrn,
    navigateToPlanSettings,
  };
};
