import { PersonaResource } from '@outbound/types';
import { BaseStore } from '../../base-store';
import { RootStore } from '../../root-store';
import CustomerProfile from './customer-profile';
import CustomerProfileTransformer from './customer-profile-transformer';

class CustomerProfileStore extends BaseStore<CustomerProfile> {
  private _customerProfileTransformer: CustomerProfileTransformer;

  constructor(rootStore: RootStore) {
    super(rootStore, 'customer-profile');
    this._customerProfileTransformer = new CustomerProfileTransformer(
      rootStore
    );
    this.rootStore.transport.customerProfileTransport.registerServerUpdateCallbackHandler(
      this.handleServerUpdate.bind(this)
    );
  }

  protected requestLoadModelFromServer(id: string): void {
    this.rootStore.transport.customerProfileTransport.requestResourceById(id);
  }

  private handleServerUpdate = (
    id: string,
    resource: PersonaResource | null
  ): void => {
    this.handleServerUpdateGeneric(
      id,
      resource,
      this._customerProfileTransformer
    );
  };
}

export default CustomerProfileStore;
