import { ObCustomerStatus } from '@outbound/types';
import { cx } from 'class-variance-authority';
import { ReactNode } from 'react';
import { ObCxStatus } from '../../../../components/elements/ob-cx-status/ob-cx-status';
import { ObTypography } from '../../../../components/elements/ob-typography/ob-typography';
import { containerStyles, determineColorForCustomerStatus } from './color-util';

export interface CampaignHealthAlertProps {
  healthStatus: ObCustomerStatus;
  currentHealthTitle: string;
  currentHealthDescription: string;
  healthTodoListNode: ReactNode;
  /**
   * The number of items in the healthToDoList
   */
  healthToDoListCount: number;
}

/**
 * An Alert that can be used on a page to indicate that a specific feature is not available on the customers current plan.
 */
export const CampaignHealthAlert = ({
  healthStatus,
  currentHealthTitle,
  currentHealthDescription,
  healthTodoListNode,
  healthToDoListCount,
}: CampaignHealthAlertProps) => {
  const color = determineColorForCustomerStatus(healthStatus);

  return (
    <div className='@container/campaign-health-alert'>
      <div
        className={cx(
          ' p-4 flex  rounded-md flex-col flex-1 gap-5',
          containerStyles({ healthStatus })
        )}
      >
        <div className='flex gap-4 items-start'>
          <div className='hidden @sm/campaign-health-alert:block pt-1'>
            <ObCxStatus
              status={healthStatus}
              size={'large'}
            />
          </div>
          <div>
            <ObTypography
              variant='h3'
              color={color}
            >
              {currentHealthTitle}
            </ObTypography>
            <ObTypography
              variant='body1'
              color='secondary'
            >
              {currentHealthDescription}
            </ObTypography>
          </div>
        </div>
        {/* ONLY RENDER THE TODO LIST IF THERE IS AT LEAST ONE ITEM */}
        {healthToDoListCount > 0 && (
          <>
            <ObTypography
              variant='h4'
              color='primary'
            >
              {healthStatus === 'customer-action-required'
                ? 'Complete the following to start running your Ads:'
                : 'No action required at this time. Once the following are complete your Ads will start running:'}
            </ObTypography>
            <div className='flex flex-col gap-6'>{healthTodoListNode}</div>
          </>
        )}
      </div>
    </div>
  );
};
