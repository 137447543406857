import {
  ObActionMenu,
  ObActionMenuItem,
  ObAvatar,
  ObDivider,
  ObIcon,
  ObTypography,
} from '@outbound/design-system';
import { cx } from 'class-variance-authority';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from '../../../../hooks/use-window-dimensions';
import {
  PROFILE_ACCOUNT_SETTINGS_ROUTE,
  RoutePaths,
  SETTINGS_ROUTE,
} from '../../../navigation/NavigationConstants';
import { ToggleExpandButtonMobile } from '../toggle-expand-button-mobile/toggle-expand-button-mobile';
import { DrawerProps } from '../types';

import { CloseButton } from '@headlessui/react';
import { useAuth0Extended } from '../../../../auth/use-auth0-extended';
import { useFetchMyWorkspaces } from '../../../../query/my-workspaces/use-my-workspaces-endpoints';
import { useFetchWorkspaceDetails } from '../../../../query/workspace/use-workspace-endpoints';

const LoadingState = () => {
  return (
    <div className='flex flex-col items-center gap-4'>
      <ObTypography variant='body2'>...</ObTypography>
    </div>
  );
};

export const MainDrawerHeader = ({
  isDrawerExpanded,
  toggleDrawerExpansion,
}: DrawerProps) => {
  const navigate = useNavigate();

  const { logout, isLoading, switchOrganization } = useAuth0Extended();

  const { isSmallOrLargerViewport: isDesktopView } = useWindowDimensions();

  const { data: myWorkspaces, isLoading: myWorkspacesIsLoading } =
    useFetchMyWorkspaces();

  const { data: myCurrentWorkspace, isLoading: myCurrentWorkspaceIsLoading } =
    useFetchWorkspaceDetails();

  const isFetching =
    isLoading || myWorkspacesIsLoading || myCurrentWorkspaceIsLoading;

  const actionMenuItemCallback = useCallback(
    (route: string) => {
      navigate(route);
    },
    [navigate]
  );

  const MyWorkspaces = useCallback(() => {
    return (
      <div className='max-h-[190px] overflow-y-auto scroll-smooth p-[4px]'>
        {myWorkspaces?.items.map((ws: any) => {
          const isCurrentTenant = myCurrentWorkspace?.id === ws.id;
          return (
            <ObActionMenuItem
              key={ws.name}
              isDisabled={isCurrentTenant}
              ariaLabel={`Workspace ${ws.name}`}
              profileImageUrl={ws?.iconPublicUrl}
              fallbackText={ws?.name}
              title={ws.name}
              className={` flex-1 ${
                isCurrentTenant
                  ? 'dark:bg-dark/action/primary/normal'
                  : 'bg-transparent'
              }`}
              rightIcon={isCurrentTenant ? 'complete' : undefined}
              onClickCallback={() =>
                switchOrganization(ws.auth0OrganizationId, ws.id)
              }
            />
          );
        })}
      </div>
    );
  }, [myCurrentWorkspace?.id, myWorkspaces?.items, switchOrganization]);

  return (
    <div
      data-testid='navigation-drawer-header'
      data-drawer-expanded={isDrawerExpanded}
      data-desktop-view={isDesktopView}
      className='flex justify-between items-center h-[64px]  mx-3 rounded z-50 relative'
    >
      <ObActionMenu
        data-testid='navigation-switcher-menu'
        menuOpenButton={
          <button
            aria-label='Navigation Switcher'
            data-drawer-expanded={isDrawerExpanded}
            data-desktop-view={isDesktopView}
            className='flex items-center p-2 data-[desktop-view=true]:flex-1  box-border rounded hover:dark:bg-dark/action/primary/on-subtle focus:outline-none focus-visible:shadow-interactive transition-all duration-300 ease-in-out'
          >
            <ObAvatar
              size='small'
              shape='rounded'
              profileImageUrl={myCurrentWorkspace?.iconPublicUrl}
              fallbackText={myCurrentWorkspace?.name}
            />
            {/* A 186px width limit here ensures the label text is truncated when the tenant name is too long */}
            <div
              data-desktop-view={isDesktopView}
              className='max-w-[186px] flex-grow'
            >
              {isFetching ? (
                <LoadingState />
              ) : (
                <div
                  data-desktop-view={isDesktopView}
                  className={cx(
                    'flex gap-x-1 data-[desktop-view=true]:justify-between items-center transition-all duration-300 ease-in-out',
                    !isDrawerExpanded && isDesktopView
                      ? 'translate-x-full opacity-0 w-0 pl-0'
                      : 'w-[186px] pl-[12px]'
                  )}
                >
                  <ObTypography truncate>
                    {myCurrentWorkspace?.name}
                  </ObTypography>
                  <ObIcon
                    icon='down'
                    size='small'
                  />
                </div>
              )}
            </div>
          </button>
        }
        data-drawer-expanded={isDrawerExpanded}
        data-desktop-view={isDesktopView}
      >
        <div className={cx('flex flex-col', 'opacity-100')}>
          {isFetching ? <LoadingState /> : <MyWorkspaces />}
          <div className='p-[4px]'>
            <ObActionMenuItem
              ariaLabel='Add a new workspace'
              icon='plus'
              title='Add new workspace'
              onClickCallback={() =>
                navigate(RoutePaths.WORKSPACE_CREATION_PAGE)
              }
            />

            <ObDivider />

            <CloseButton className='flex w-full'>
              <ObActionMenuItem
                ariaLabel='Access Account Settings'
                icon='profile'
                title='Personal Settings'
                onClickCallback={() => {
                  actionMenuItemCallback(PROFILE_ACCOUNT_SETTINGS_ROUTE);
                }}
              />
            </CloseButton>

            <CloseButton className='flex w-full'>
              <ObActionMenuItem
                ariaLabel='Access Workspace Settings'
                icon='building'
                title='Workspace Settings'
                onClickCallback={() => {
                  actionMenuItemCallback(SETTINGS_ROUTE);
                }}
              />
            </CloseButton>

            <ObDivider />
            <ObActionMenuItem
              ariaLabel='Logout of your Outbound account'
              icon='logOut'
              title='Logout'
              onClickCallback={logout}
            />
          </div>
        </div>
      </ObActionMenu>
      {!isDesktopView && (
        <div className='m-3'>
          <ToggleExpandButtonMobile
            isDrawerExpanded={isDrawerExpanded}
            toggleDrawerExpansion={toggleDrawerExpansion}
          />
        </div>
      )}
    </div>
  );
};
