import ProtectedWorkspaceRoute from '../../components/authentication/ProtectedWorkspaceRoute';
import { NavItem } from '../../components/navigation/dashboard-route.type';
import { campaignRoute } from './campaigns/routes';
import DashboardLayout from './dashboard-layout';
import { homeRoute } from './dashboard/routes';
import { landingPageRoutes } from './landing-pages/routes';
import { playbookRoutes } from './playbookv2/routes';
import { settingRoutes } from './settings/routes';

export const dashboardRoute: NavItem = {
  title: 'Dashboard',
  route: ':workspaceSlug',
  showInNavMenu: [],
  component: () => (
    <ProtectedWorkspaceRoute component={() => <DashboardLayout />} />
  ),
  childRoutes: [
    campaignRoute,
    homeRoute,
    playbookRoutes,
    landingPageRoutes,
    settingRoutes,
  ],
};
