import { ServerDelta } from '@outbound/types';
import { makeObservable } from 'mobx';
import { BaseModel } from '../../base-model';
import CustomerProfile from '../../business-context/customer-profile/customer-profile';
import { RootStore } from '../../root-store';

/**
 * This class was originally created with the intention that there may be attributes
 * on the relationship between a campaign and a customer profile.
 * So far this is not the case and be able to be refactored to a direct attribute on the campaign.
 *
 * Currently our answer to changing the customer profile on a campaign is to create a new campaign so this
 * relationship is immutable.
 *
 * This does leave open questions about what to do if a customer profile is deleted...
 *
 */
export class CampaignCustomerProfile extends BaseModel {
  static readonly paths = {
    ...BaseModel.paths,
  };

  constructor(rootStore: RootStore, id: string, workspaceId: string) {
    super(
      rootStore,
      'campaign/campaign-customer-profile',
      '1',
      id,
      workspaceId
    );
  }

  applyPatch(_patch: ServerDelta[]): void {
    //This relationship is not mutable until we implement version updates
  }

  public toJson(): Record<string, any> {
    return {
      //Not Extending Base JSON here
      [CampaignCustomerProfile.paths.id]: this._id,
    };
  }

  protected makeObservableInternal(): void {
    makeObservable(this, {
      /**
       * This causes infinite re-rendering in the suspense boundary of campaign
       * detail pages intermittently. This only occurs when the customer-profile is not
       * pre-loaded and throws a promise while lazy loading.
       *
       * Since this happens in a suspense boundary and inside of the MobX store it was pretty
       * ugly to track down. I spent ~6hrs to get to the point of identifying the root cause.
       *
       * I'm leaving this here so if for some reason someone else thinks we need a computed property
       * here they can see the issue and understand why it was removed.
       *
       */
      // customerProfile: computed,
    } as any);
  }

  get customerProfile(): CustomerProfile | null {
    return this._rootStore.customerProfileStore.getById(this._id);
  }
}
