export class Deferred<T> {
  private _status: 'PENDING' | 'RESOLVED' | 'REJECTED' = 'PENDING';
  private _resolve!: (value: T | PromiseLike<T>) => void;
  private _reject!: (reason?: any) => void;
  private _promise: Promise<T>;

  constructor() {
    this._promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  resolve(value: T) {
    if (this._status !== 'PENDING') {
      return;
    }
    this._status = 'RESOLVED';
    this._resolve(value);
  }

  reject(reason?: any) {
    if (this._status !== 'PENDING') {
      return;
    }
    this._status = 'REJECTED';
    this._reject(reason);
  }

  get status() {
    return this._status;
  }

  get promise() {
    return this._promise;
  }
}
