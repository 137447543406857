import { CustomerProfileCard } from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { useAppNavigation } from '../../hooks/use-app-navigation';
import CustomerProfile from '../../state/mobx-experiment/business-context/customer-profile/customer-profile';

export interface CustomerProfileCardObserverProps {
  customerProfile: CustomerProfile;
}

export const CustomerProfileCardObserver = observer(
  ({ customerProfile }: CustomerProfileCardObserverProps) => {
    const { navigateToCustomerProfileWithObrn } = useAppNavigation();
    return (
      <CustomerProfileCard
        id={customerProfile.id}
        description={customerProfile.description}
        variant={'list'}
        onNavigateCallback={() =>
          navigateToCustomerProfileWithObrn(customerProfile.obrn)
        }
        obrn={customerProfile.obrn}
        name={customerProfile.role}
      />
    );
  }
);
