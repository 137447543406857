import { ServerDelta } from '@outbound/types';
import { FeatureIdentifier } from '../../../../in-app-marketing/feature-list';
import { BaseTransformer } from '../../base-transformer';
import { RootStore } from '../../root-store';
import Integration from './integration';
import { IntegrationResource } from './integration-transport';

export class IntegrationTransformer extends BaseTransformer<
  IntegrationResource,
  Integration
> {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  fromApiResource(resource: IntegrationResource): Integration {
    return new Integration(
      this._rootStore,
      resource.slug,
      resource.slug,
      resource.name,
      resource.shortDescription,
      resource.iconImageUrl,
      resource.categories[0],
      resource.integrationWebsiteUrl,
      resource.documentationUrl,
      resource.overview,
      resource.configurationType,
      resource.minimumRequiredPlan,
      resource.featureIdToDisplayWhenMinimumPlanNotMet as FeatureIdentifier
    );
  }

  createPatchForCurrentModelAndIncomingResource(
    _model: Integration,
    _resource: IntegrationResource
  ): ServerDelta[] {
    throw new Error('Method not implemented.');
  }
}
