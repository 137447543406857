import { NavItem } from '../../../../../components/navigation/dashboard-route.type';
import { AccountNotifications } from './account-notifications';

export const notificationSettingsRoute: NavItem = {
  title: 'Notifications',
  route: 'notifications',
  featureFlag: 'notifications',
  component: AccountNotifications,
  showInNavMenu: ['my-account-settings-nav'],
  icon: 'notification',
};
