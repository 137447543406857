import { ObTypography } from '@outbound/design-system';
import { cx } from 'class-variance-authority';

export interface PlaybookPageHeadingProps {
  title: string;
  controls?: React.ReactNode;
}
export const PlaybookPageHeading = ({
  title,
  controls,
}: PlaybookPageHeadingProps) => {
  return (
    <header
      className={cx(
        'flex flex-row justify-between items-center min-h-[40px]',
        controls == null ? 'hidden lg:flex' : ''
      )}
    >
      <ObTypography
        as='h1'
        variant='h3'
        className='hidden lg:block'
      >
        {title}
      </ObTypography>
      <div>{controls}</div>
    </header>
  );
};
