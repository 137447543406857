import { NavItem } from '../../../../../components/navigation/dashboard-route.type';
import WorkspaceDetails from './workspace-details';

export const generalSettingsRoute: NavItem = {
  index: true,
  title: 'General',
  route: 'general',
  component: WorkspaceDetails,
  showInNavMenu: ['workspace-settings-nav'],
  icon: 'settings',
};
