import { FC } from 'react';
import { DisabledProps } from '../../base-component-props.type';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { ObSliderBase } from '../components/ob-budget-slider-base/ob-slider-base';
import { ObBudgetInput } from './components/ob-budget-input/ob-budget-input';
import { ObMetricCard } from './components/ob-metric-card/ob-metric-card';

export interface EstimatedMetric {
  label: string;
  value: string;
}
export interface ObCampaignBudgetSliderProps extends DisabledProps {
  dailyBudget: number;
  minBudget?: number;
  maxBudget: number;
  onDailyBudgetChange: (value: number) => void;
  isLoadingEstimatedMetrics?: boolean;
  estimatedMetrics: Array<EstimatedMetric>;
  isLoading?: boolean;
}

/**
 * A slider for setting the budget of a campaign. This slider also will provide feedback on the estimated ROI for the campaign
 */
export const ObCampaignBudgetSlider: FC<ObCampaignBudgetSliderProps> = ({
  isDisabled = false,
  dailyBudget,
  minBudget = 0,
  maxBudget,
  onDailyBudgetChange,
  isLoadingEstimatedMetrics: isLoadingEstimatedROI = false,
  estimatedMetrics = [],
  isLoading = false,
}) => {
  return (
    <section className='flex flex-1 flex-row  gap-4 relative self-stretch'>
      <div className='flex flex-col gap-12 flex-1  '>
        {estimatedMetrics != null && estimatedMetrics.length > 0 && (
          <div className='flex flex-col gap-3'>
            <div>
              <ObTypography variant='h3'>
                Here is an idea of how far your budget could go.
              </ObTypography>
              <ObTypography
                variant='body2'
                color='secondary'
              >
                * Leads per Month are rough estimates are not guaranteed. These
                estimates are based on current available data and cary vary
                greatly based on how the Campaign is managed. We will work to
                improve these estimates over time as we gather more data.
              </ObTypography>
            </div>
            <div className='flex flex-row gap-4 self-stretch'>
              {estimatedMetrics.map((metric) => (
                <ObMetricCard
                  key={metric.label}
                  isLoading={isLoadingEstimatedROI}
                  label={metric.label}
                  value={metric.value ?? '--'}
                />
              ))}
            </div>
          </div>
        )}
        <div className='flex flex-col gap-3'>
          <div className=''>
            <ObTypography variant='h3'>Set your Daily Budget</ObTypography>
            <ObTypography
              variant='body2'
              color='secondary'
            >
              The max budget is set to the top of the recommended range for this
              campaign based on market data.
            </ObTypography>
          </div>
          <ObSliderBase
            value={dailyBudget}
            onChangeCallback={onDailyBudgetChange}
            minValue={minBudget}
            maxValue={maxBudget}
            isLoading={isLoading}
            isDisabled={isDisabled}
          />
          <div>
            <ObBudgetInput
              isDisabled={isDisabled}
              minValue={minBudget}
              maxValue={maxBudget}
              value={dailyBudget}
              onValueChangedCallback={onDailyBudgetChange}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
