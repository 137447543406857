import { ReactNode } from 'react';
import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';
import { BaseSection } from './components/base-section';

export interface CustomerProfileSectionProps {
  /**
   * Customer Profile not present
   */
  customerProfileMissing: boolean;
  /**
   * Black Box Slot for the Customer Profile Card
   */
  customerProfileCardNode: ReactNode;
}

/**
 * Customer Profile Section for the Campaign Detail Landing Page
 * @returns
 */
export const CustomerProfileSection = ({
  customerProfileMissing,
  customerProfileCardNode,
}: CustomerProfileSectionProps) => {
  return (
    <BaseSection
      heading='Customer Profile'
      subheading='The Customer Profile that this campaign should target'
    >
      {customerProfileMissing ? (
        <>
          <div className='flex justify-center items-center w-full h-48'>
            <ObTypography
              className='text-center'
              variant='body2'
              color='secondary'
            >
              No Customer Profile has been added to this campaign yet.
            </ObTypography>
          </div>
        </>
      ) : (
        <div className='w-full'>{customerProfileCardNode}</div>
      )}
    </BaseSection>
  );
};
