import {
  CampaignDetailPage,
  CampaignHealthAlert,
  CreativeCard,
  FeatureAlert,
  LocationCard,
  ObTypography,
  ServiceCard,
  ServiceOfferingCard,
  useDialogService,
} from '@outbound/design-system';

import { motion } from 'motion/react';

import { parseObrn } from '@otbnd/utils';
import { ObLink } from '@outbound/design-system/src/components/elements/ob-link/ob-link';
import { SettingCardRowWithButton } from '@outbound/design-system/src/components/organisms/ob-setting-card-with-button/setting-card-row-with-button';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppNavigation } from '../../../../hooks/use-app-navigation';
import {
  AppFeatures,
  Feature,
} from '../../../../in-app-marketing/feature-list';
import { CampaignDeploymentModelCard } from '../../../../object-card/campaign-deployment/campaign-deployment';
import { CustomerProfileCardObserver } from '../../../../object-card/customer-profile/customer-profile-card-observer';
import { IntegrationXConfigurationModelCard } from '../../../../object-card/integration-x-configuration/integration-x-configuration';
import { LandingPageCardObserver } from '../../../../object-card/landing-page/landing-page-card-observer';
import { Service } from '../../../../state/mobx-experiment/business-context/service/service';
import ServiceOffering from '../../../../state/mobx-experiment/business-context/service/service-offering/service-offering';
import Campaign from '../../../../state/mobx-experiment/campaign/campaign';
import CampaignHighlight from '../../../../state/mobx-experiment/campaign/campaign-highlight/campaign-highlight';
import { useRootStore } from '../../../../state/mobx-experiment/use-root-store';
import Workspace from '../../../../state/mobx-experiment/workspace/workspace';
import { useLandingPageCta } from '../../landing-pages/call-to-actions/use-landing-page-cta';
import { tryToPublishCampaign } from '../campaign-publish-cta';
import { useCampaignCreativeLightbox } from '../hooks/use-campaign-creative-lightbox';
import { useCampaignRoiEstimate } from '../hooks/use-campaign-roi-estimate';
import { CampaignHealthCheckTodoObserver } from './campaign-health-todo-observer';

type RouteParams = {
  campaignId: string;
  workspaceSlug: string;
};

const renderHighlightedObjectForHighlight = (
  highlight: CampaignHighlight,
  navigateToServiceWithObrn: (serviceObrn: string) => void,
  navigateToServiceOfferingWithObrn: (serviceOfferingObrn: string) => void
) => {
  switch (highlight.highlightType) {
    case 'service-offering': {
      const serviceOffering = highlight.highlightedObject as ServiceOffering;
      return (
        <ServiceOfferingCard
          obrn={serviceOffering?.obrn}
          onNavigateCallback={(serviceOfferingOrbn: string) => {
            navigateToServiceOfferingWithObrn(serviceOfferingOrbn);
          }}
          variant={'mini'}
          name={serviceOffering?.name}
          serviceName={serviceOffering.service?.name ?? 'Unknown'}
          keyPhotoPublicUrl={serviceOffering.keyPhoto?.publicUrl}
          description={serviceOffering.keyPhoto?.altText ?? ''}
        />
      );
    }
    case 'service': {
      const service = highlight.highlightedObject as Service;
      return (
        <ServiceCard
          obrn={service?.obrn}
          onNavigateCallback={(serviceOrbn: string) => {
            navigateToServiceWithObrn(serviceOrbn);
          }}
          variant='mini'
          name={service?.name}
          description={service?.description}
        />
      );
    }
    default:
      return (
        <div>
          <ObTypography color='negative'>Node Not Implemented</ObTypography>
        </div>
      );
  }
};

/**
 * Campaign Detail Page that shows "What" the campaign is advertising to the user.
 * This page communicates this using the playbook objects that have been defined
 * in the customers playbook.
 */
const CampaignWhatPageComponent = observer(() => {
  const { campaignId, workspaceSlug } = useParams<RouteParams>();

  const [searchParams, setSearchParams] = useSearchParams();

  const dialogService = useDialogService();

  const { campaignStore, workspaceStore, landingPageStore } = useRootStore();

  const { startAddLandingPageWorkflow } = useLandingPageCta();

  const campaign: Campaign | null = campaignStore.getById(campaignId!);

  const workspace: Workspace | null = workspaceStore.getById(workspaceSlug!);

  const [view, setView] = useState<'status' | 'overview'>(
    (searchParams.get('view') as 'status' | 'overview') ?? 'overview'
  );

  const {
    navigateToCreativeBuilder,
    navigateToServiceOfferingWithObrn,
    navigateToServiceWithObrn,
    navigateToLocationWithObrn,
  } = useAppNavigation();
  const { openCampaignCreativeLightbox } = useCampaignCreativeLightbox(
    campaignId!
  );

  const navigate = useNavigate();

  const {
    getLatestRoiMetricByMethodology,
    isPendingRoiUpdate,
    setWorkingBudget,
  } = useCampaignRoiEstimate(campaignId);

  useEffect(() => {
    if (campaign?.dailyBudget != null) {
      setWorkingBudget(campaign.dailyBudget);
    }
  }, [campaign?.dailyBudget, setWorkingBudget]);

  /**
   * Side Effect to Update the View of the Campaign;
   * Trigger by based on the "view" query parameter in the URL.
   */
  useEffect(() => {
    if (
      searchParams != null &&
      searchParams.get('view') != null &&
      searchParams.get('view') !== view
    ) {
      setView(searchParams.get('view') as 'status' | 'overview');
    }
  }, [searchParams, view]);

  const onSelfHostedLandingPageAddedToHighlight = useCallback(
    (url: string, workspaceId: string, highlight: CampaignHighlight) => {
      const landingPage = landingPageStore.createSelfHosted(url, workspaceId);

      highlight.addExistingLandingPageByObrn(landingPage.obrn);

      //Add the Landing Page to the Campaign
    },
    [landingPageStore]
  );

  const handleDeleteCampaign = useCallback(() => {
    if (campaign == null) {
      return;
    }
    dialogService
      .openConfirmation({
        title: 'Delete Campaign',
        body: 'Are you sure you want to delete this campaign?',
      })
      .then(() => {
        campaign.delete();
        navigate('../..');
      });
  }, [campaign, dialogService, navigate]);

  if (campaign == null) {
    //Mostly here for Typescript. This case is handled by the CampaignLoader component so this should never be hit.
    return <></>;
  }

  const searchMaintenance: Feature = AppFeatures.GoogleSearchAdMaintenance;

  return (
    <>
      <div className='max-w-screen-lg m-auto px-4 pt-4'>
        <ObLink
          iconLeft='chevronLeft'
          label='All Campaigns'
          onClick={() => {
            navigate('../..');
          }}
          variant={'primary'}
        />
      </div>
      <CampaignDetailPage
        activeView={view}
        campaignCreativeMessage={campaign.creativeStatusMessage}
        onActiveViewUpdatedCallback={(activeView) => {
          setSearchParams((current) => {
            return new URLSearchParams({ ...current, view: activeView });
          });
        }}
        alertSection={
          <>
            {campaign.health.status !== 'nominal' && (
              <motion.div
                transition={{ duration: 0.5 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <CampaignHealthAlert
                  healthStatus={campaign.health.status}
                  currentHealthTitle={campaign.health.title}
                  currentHealthDescription={campaign.health.description}
                  healthTodoListNode={campaign.health.todos.map(
                    (todo, index) => (
                      <CampaignHealthCheckTodoObserver
                        key={todo.title}
                        campaign={campaign}
                        todo={todo}
                        index={index}
                        workspace={workspace!}
                      />
                    )
                  )}
                  healthToDoListCount={campaign.health.todos.length}
                />
              </motion.div>
            )}
            {campaign.latestDeployment != null &&
              workspace?.plan != 'ENTERPRISE' && (
                <motion.div
                  transition={{ duration: 0.5 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <FeatureAlert
                    id={searchMaintenance.id}
                    name={searchMaintenance.name}
                    getFeatureCallToAction={
                      searchMaintenance.getFeatureCallToAction
                    }
                    getFeatureCallToActionPitch={
                      searchMaintenance.getFeatureCallToActionPitch
                    }
                    availableOnPlans={searchMaintenance.availableOnPlans}
                    onGetFeatureCallback={() => {
                      window.open(
                        `https://meetings.hubspot.com/${searchMaintenance.getFeatureBookATimeMeetingOwner}/${searchMaintenance.getFeatureBookATimeMeetingName}?utm_source=ob-web-app&utm_medium=campaign-detail&utm_campaign=upgrade`,
                        '_blank'
                      );
                    }}
                  />
                </motion.div>
              )}
          </>
        }
        campaignCardDetailHeader={{
          obrn: campaign.obrn,
          goal: campaign.goal,
          onUserPausedStateSetToNotPaused: () => campaign.unPauseCampaign(),
          onUserPausedStateSetToPaused: () => campaign.pauseCampaign(),
          onDeployCampaignCallback: async () => {
            tryToPublishCampaign(
              campaign,
              searchParams,
              dialogService,
              setSearchParams
            );
          },
          onDeleteCampaignCallback: () => handleDeleteCampaign(),
          isLaunched: campaign.latestDeployment != null,
          latestCampaignDeploymentSlot:
            campaign.latestDeployment != null ? (
              <CampaignDeploymentModelCard
                variant='micro'
                campaignDeployment={campaign.latestDeployment}
                onNavigateCallback={() => {
                  setSearchParams((current) => {
                    return new URLSearchParams({ ...current, view: 'status' });
                  });
                }}
              />
            ) : null,
          integrationXConfigurationSlot: (
            <IntegrationXConfigurationModelCard
              integrationXWorkspace={campaign.primaryIntegrationConfiguration!}
            />
          ),
          onNavigateCallback: (_obrn: string) => {
            //Noop for Detail Card
          },
          name: campaign.name,
          channel: campaign.channel,
          servingState: campaign.servingState,
          servingStateReason: campaign.servingStateReason,
          servingStateLastCalculatedAtTimestamp:
            campaign.servingStateLastCalculatedAtTimestamp,
          pausedByUserState: campaign.pausedByUserState,
          onSaveNameClickedCallback: (newName: string) => {
            campaign.name = newName;
            campaign.save(['name']);
          },
        }}
        campaignLatestDeploymentSection={{
          latestCampaignDeploymentNode:
            campaign.latestDeployment != null ? (
              <CampaignDeploymentModelCard
                variant='mini'
                campaignDeployment={campaign.latestDeployment}
              />
            ) : null,
        }}
        budgetSectionProps={{
          isBudgetDirty: campaign.isBudgetDirty,
          onSaveBudgetClickedCallback: () => {
            campaign.save(['budget']);
          },
          onDiscardBudgetChangesClickedCallback: () => {
            campaign.discardUnsavedChanges(['budget']);
          },
          budgetSlider: {
            dailyBudget: campaign.dailyBudget,
            maxBudget: 200,
            onDailyBudgetChange: (budget: number) => {
              campaign.dailyBudget = budget;
            },
            isLoadingEstimatedMetrics: isPendingRoiUpdate,
            estimatedMetrics: [
              {
                label: 'Leads Per Month - Low *',
                value:
                  getLatestRoiMetricByMethodology(
                    'most-expensive-seed-keyword'
                  )?.primarySuccessMetric?.toString() ?? '--',
              },
              {
                label: 'Leads Per Month - High *',
                value:
                  getLatestRoiMetricByMethodology(
                    'keyword-top-of-page-cost-weighted-by-impressions'
                  )?.primarySuccessMetric?.toString() ?? '--',
              },
            ],
            isLoading: false,
          },
        }}
        customerProfileCardNode={
          campaign.campaignCustomerProfile != null &&
          campaign.campaignCustomerProfile.customerProfile != null ? (
            <CustomerProfileCardObserver
              customerProfile={campaign.campaignCustomerProfile.customerProfile}
            />
          ) : (
            <></>
          )
        }
        customerProfileMissing={
          campaign.campaignCustomerProfile == null ||
          campaign.campaignCustomerProfile.customerProfile == null
        }
        campaignLocationsSection={{
          campaignLocations: campaign.campaignLocations
            .filter((l) => l.isEnabled)
            .map((campaignLocation) => ({
              isEnabled: campaignLocation.isEnabled,
              locationId: campaignLocation.id,
              onIsEnabledChanged: (isEnabled: boolean) => {
                campaignLocation.isEnabled = isEnabled;
              },
              onRemoveLocationFromCampaignClicked: () => {
                console.log('Remove Location Clicked');
              },
              locationNode: (
                <LocationCard
                  variant={'grid'}
                  onNavigateCallback={(locationObrn: string) => {
                    navigateToLocationWithObrn(locationObrn);
                  }}
                  obrn={campaignLocation.obrn}
                  id={campaignLocation.location?.id || 'Unknown'}
                  name={campaignLocation.location?.name || 'Unknown'}
                  description={
                    campaignLocation.location?.geographicDescription ||
                    'Unknown'
                  }
                  type={campaignLocation.location?.locationType ?? 'PHYSICAL'}
                  locationImageForDarkBackgroundPublicUrl={
                    campaignLocation.location?.mapImageForDarkBackground
                      ?.publicUrl
                  }
                  locationImageForLightBackgroundPublicUrl={
                    campaignLocation.location?.mapImageForLightBackground
                      ?.publicUrl
                  }
                  locationImageStatus={
                    campaignLocation.location?.mapImageGenerationStatus ??
                    'WAITING_FOR_MAP_IMAGE_GENERATION'
                  }
                />
              ),
            })),
        }}
        campaignHighlightsSection={{
          campaignHighlights: campaign.campaignHighlights.map((highlight) => ({
            isEnabled: highlight.isEnabled,
            associatedLandingPagesSlot:
              highlight.landingPage != null ? (
                <LandingPageCardObserver
                  landingPage={highlight.landingPage}
                  variant={'grid'}
                />
              ) : undefined,
            addLandingPageToHighlightClicked: () => {
              startAddLandingPageWorkflow({
                mode: 'ADD_OR_SELECT',
                availableLandingPages:
                  workspace?.landingPages.map(
                    (lp): { landingPageObrn: string } => {
                      return {
                        landingPageObrn: lp.obrn,
                      };
                    }
                  ) ?? [],
                landingPageRenderFunction: (
                  landingPageObrn,
                  onClickCallbackFunction
                ) => {
                  return (
                    <LandingPageCardObserver
                      variant='grid'
                      onCardClickedCallback={onClickCallbackFunction}
                      landingPage={
                        landingPageStore.getById(
                          parseObrn(landingPageObrn!).localPathId
                        )!
                      }
                    />
                  );
                },
                onSelfHostedLandingPageUrlProvided: (url: string) => {
                  onSelfHostedLandingPageAddedToHighlight(
                    url,
                    workspace!.id,
                    highlight
                  );
                },

                onExistingLandingPageSelected: (obrn) => {
                  highlight.addExistingLandingPageByObrn(obrn);
                },
                currentSubscriptionPlan: 'FREE', //TODO Grab from Workspace
              });
            },
            highlightedObjectNode: (
              <>
                {renderHighlightedObjectForHighlight(
                  highlight,
                  navigateToServiceWithObrn,
                  navigateToServiceOfferingWithObrn
                )}
              </>
            ),
            associatedCreativesSlot: (
              <>
                {highlight.creatives.map((creative) => (
                  <CreativeCard
                    key={creative.id}
                    variant={'mini'}
                    onViewSpecificErrorCallback={(errorId: string) => {
                      navigateToCreativeBuilder(creative.id, {
                        settingIdToFocus: errorId,
                      });
                    }}
                    onNavigateToEditorCallback={(id: string) => {
                      navigateToCreativeBuilder(id);
                    }}
                    onNavigateToPreviewCallback={(id: string) => {
                      openCampaignCreativeLightbox({ openToCreativeId: id });
                    }}
                    template={{
                      id: creative.template?.id || 'Unknown',
                      name: creative.template?.name || 'Unknown',
                      renderComponent:
                        creative.template?.renderComponent || 'Unknown',
                    }}
                    isReviewed={creative.isReviewed}
                    isValid={creative.isValid}
                    validationErrors={creative.validationErrors}
                    name={creative.name}
                    id={creative.id}
                    medium={creative.medium}
                    lifecycleStatus={creative.lifecycleStatus}
                  />
                ))}
              </>
            ),
            onIsEnabledChanged: (isEnabled: boolean) => {
              highlight.isEnabled = isEnabled;
            },
            onRemoveHighlightFromCampaignClicked: () => {
              console.log('Remove Highlight Clicked');
            },
            highlightType: highlight.highlightType,

            highlightedObjectId: highlight.highlightedObjectId,
            highlightNodeData: highlight.highlightedObjectData,
          })),
        }}
      >
        <div className='flex flex-col gap-4'>
          <ObTypography variant='h2'>Campaign Settings</ObTypography>
          <SettingCardRowWithButton
            title='Delete Campaign'
            body='Deleting a Campaign will permanently remove it from Outbound. It will not be deleted from the Ad Channel. This action cannot be undone.'
            buttonLabel='Delete Campaign'
            handleButtonClickCallback={() => handleDeleteCampaign()}
            color={'error'}
            loading={false}
          />
        </div>
      </CampaignDetailPage>
    </>
  );
});

CampaignWhatPageComponent.displayName = 'CampaignWhatPage';

export const CampaignWhatPage = CampaignWhatPageComponent;
