import { NavItem } from '../../../components/navigation/dashboard-route.type';
import { DashboardPage } from './dashboard-page';

export const homeRoute: NavItem = {
  title: 'Home',
  icon: 'barLineChart',
  route: 'dashboard',
  index: false,
  component: () => <DashboardPage />,
  showInNavMenu: ['workspace-side-nav'],
  featureFlag: 'navDashboard',
};
