import { Plan } from '@outbound/types';

export const featureIdValues: Array<string> = ['MetaAdManagement'] as const;

export type FeatureId = (typeof featureIdValues)[number];

export interface Feature {
  /**
   * An internal unique identifier for the feature
   * ex: 'MetaAdManagement'
   */
  id: FeatureId;
  /**
   * The name of the feature
   */
  name: string;
  /**
   * Which plans is this feature available on
   */
  availableOnPlans: Array<Plan>;
  /**
   * A marketing call to action to entice the user to get the feature
   */
  getFeatureCallToAction: string;
  /**
   * A marketing pitch to entice the user to get the feature
   */
  getFeatureCallToActionPitch: string;
  /**
   * The type of call to action that the user will be re-directed to when they click a book a time to learn more about this feature
   */
  getFeatureCallToActionType: 'book-a-time' | 'in-app-upgrade';
  /**
   * HubSpot Meeting Owner that of the meeting link that a user will be re-directed to when they click a book a time to learn more about this feature
   */
  getFeatureBookATimeMeetingOwner: string | null;
  /**
   * HubSpot Meeting Name that a user will be re-directed to when they click a book a time to learn more about this feature
   */
  getFeatureBookATimeMeetingName: string | null;
}

interface AppFeaturesType {
  NewBusinessWithoutWebSiteImplementation: Feature;
  MetaAdManagement: Feature;
  GoogleDisplayAdManagement: Feature;
  FacebookAdManagement: Feature;
  InstagramAdManagement: Feature;
  ECommerceAdManagement: Feature;
  LandingPageCustomization: Feature;
  GoogleSearchAdMaintenance: Feature;
}

export type FeatureIdentifier = keyof AppFeaturesType;

/**
 * Instances of FEATUREs that are available on one or more PLANs.
 */
export const AppFeatures: AppFeaturesType = {
  NewBusinessWithoutWebSiteImplementation: {
    id: 'NewBusinessWithoutWebSiteImplementation',
    name: 'New Business Without Website Consultation',
    availableOnPlans: ['ENTERPRISE'],
    getFeatureCallToActionType: 'book-a-time',
    getFeatureBookATimeMeetingName: 'no-website-business',
    getFeatureBookATimeMeetingOwner: 'jr-griggs',
    getFeatureCallToAction: 'No Website? No Problem!',
    getFeatureCallToActionPitch:
      'We can get your Ads running even if you do not have a website. Start advertising your business today.',
  },
  GoogleDisplayAdManagement: {
    id: 'GoogleDisplayAdManagement',
    name: 'Google Display Ad Management',
    availableOnPlans: ['ENTERPRISE'],
    getFeatureCallToActionType: 'book-a-time',
    getFeatureBookATimeMeetingName: 'platform-google-display',
    getFeatureBookATimeMeetingOwner: 'jr-griggs',
    getFeatureCallToAction: 'Advertise on Google Display',
    getFeatureCallToActionPitch:
      'Showcase your company and service on Google Display and start getting noticed by your ideal customer. Get started with Google Display today.',
  },
  MetaAdManagement: {
    id: 'MetaAdManagement',
    name: 'Meta Ad Management',
    availableOnPlans: ['ENTERPRISE'],
    getFeatureCallToActionType: 'book-a-time',
    getFeatureBookATimeMeetingName: 'platform-meta-request-clone',
    getFeatureBookATimeMeetingOwner: 'jr-griggs',
    getFeatureCallToAction: 'Advertise on Facebook and Instagram',
    getFeatureCallToActionPitch:
      'Market your business on Facebook and Instagram to reach your customers. Upgrade to Enterprise to get started with Meta today.',
  },
  FacebookAdManagement: {
    id: 'FacebookAdManagement',
    name: 'Facebook Ad Management',
    availableOnPlans: ['ENTERPRISE'],
    getFeatureCallToActionType: 'book-a-time',
    getFeatureBookATimeMeetingName: 'platform-facebook-request',
    getFeatureBookATimeMeetingOwner: 'jr-griggs',
    getFeatureCallToAction: 'Advertise on Facebook',
    getFeatureCallToActionPitch:
      'Showcase your company and service on Facebook and start getting noticed by your ideal customer. Get started with Facebook today.',
  },
  InstagramAdManagement: {
    id: 'InstagramAdManagement',
    name: 'Instagram Ad Management',
    availableOnPlans: ['ENTERPRISE'],
    getFeatureCallToActionType: 'book-a-time',
    getFeatureBookATimeMeetingName: 'platform-instgram-request',
    getFeatureBookATimeMeetingOwner: 'jr-griggs',
    getFeatureCallToAction: 'Advertise on Instagram',
    getFeatureCallToActionPitch:
      'Showcase your company and service on Instagram and start getting noticed by your ideal customer. Get started with Instagram today.',
  },
  ECommerceAdManagement: {
    id: 'ECommerceAdManagement',
    name: 'Product Business Ad Management',
    availableOnPlans: ['ENTERPRISE'],
    getFeatureCallToActionType: 'book-a-time',
    getFeatureBookATimeMeetingName: 'e-com-inquiry',
    getFeatureBookATimeMeetingOwner: 'jr-griggs',
    getFeatureCallToAction: 'Advertise Your Product Business',
    getFeatureCallToActionPitch:
      'Advertising a product business currently requires an Enterprise plan. We are here to help you get started today!',
  },
  LandingPageCustomization: {
    id: 'LandingPageCustomization',
    name: 'AI Landing Pages',
    availableOnPlans: ['ENTERPRISE'],
    getFeatureCallToActionType: 'book-a-time',
    getFeatureBookATimeMeetingName: 'landing-pages-platform',
    getFeatureBookATimeMeetingOwner: 'jr-griggs',
    getFeatureCallToAction: 'Automated Landing Page Customization',
    getFeatureCallToActionPitch:
      'Customize your landing page and start getting noticed by your ideal customer. Get started with Landing Page Customization today.',
  },
  GoogleSearchAdMaintenance: {
    id: 'GoogleSearchAdMaintenance',
    name: 'Automated Google Search Ad Maintenance & Optimization',
    availableOnPlans: ['ENTERPRISE'],
    getFeatureCallToActionType: 'book-a-time',
    getFeatureBookATimeMeetingName: 'outboundcom-enterprise-call', //Which one should this be?
    getFeatureBookATimeMeetingOwner: 'jr-griggs',
    getFeatureCallToAction: 'Maintain and Optimize Your Google Search Campaign',
    getFeatureCallToActionPitch:
      'High Performing and cost effective Google Search Campaigns require weekly maintenance to ensure the most efficient use of your budget and the best results. If you want to delegate this weekly task to us, book a time to learn more.',
  },
};
