import {
  AddLandingPageWorkflow,
  useDialogService,
} from '@outbound/design-system';
import { Plan } from '@outbound/types';
import { ReactNode } from 'react';
import { AppFeatures } from '../../../../in-app-marketing/feature-list';

export const useLandingPageCta = () => {
  const { openDialog, popDialog } = useDialogService();

  const startAddLandingPageWorkflow = ({
    mode = 'ADD_ONLY',
    onSelfHostedLandingPageUrlProvided,
    onExistingLandingPageSelected,
    currentSubscriptionPlan,
    availableLandingPages = [],
    landingPageRenderFunction,
  }: {
    mode?: 'ADD_ONLY' | 'ADD_OR_SELECT';
    onSelfHostedLandingPageUrlProvided: (url: string) => void;
    onExistingLandingPageSelected: (obrn: string) => void;
    currentSubscriptionPlan: Plan;
    availableLandingPages: { landingPageObrn: string }[];
    landingPageRenderFunction: (
      orbn: string,
      onClickCallbackFunction: () => void
    ) => ReactNode;
  }) => {
    openDialog({
      title: 'Add Landing Page',
      hideDefaultActions: true,
      hideTitle: true,
      dialogContent: (
        <AddLandingPageWorkflow
          mode={mode}
          onUpgradePlanClicked={() => {
            window.open(
              `https://meetings.hubspot.com/${AppFeatures.LandingPageCustomization.getFeatureBookATimeMeetingOwner}/${AppFeatures.LandingPageCustomization.getFeatureBookATimeMeetingName}`,
              '_blank'
            );
          }}
          onExistingLandingPageSelected={(obrn) => {
            onExistingLandingPageSelected?.(obrn);
            popDialog();
          }}
          onSelfHostedLandingPageUrlProvided={(url) => {
            onSelfHostedLandingPageUrlProvided(url);
            popDialog();
          }}
          onCanceled={function (): void {
            throw new Error('Function not implemented.');
          }}
          availableLandingPageCount={availableLandingPages.length}
          availableLandingPages={availableLandingPages.map(
            (landingPage) => landingPage.landingPageObrn
          )}
          mobxRenderFunction={landingPageRenderFunction}
          currentSubscriptionPlan={currentSubscriptionPlan}
        />
      ),
    });
  };

  return {
    startAddLandingPageWorkflow,
  };
};
