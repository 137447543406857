'use client';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { cx } from 'class-variance-authority';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import { ObTypography } from '../ob-typography/ob-typography';

export type ObTooltipProps = {
  children: React.ReactNode;
  className?: string;
  tooltipContent: string;
  wrapperClassName?: string;
};

export const ObTooltip = ({
  children,
  className,
  tooltipContent,
  wrapperClassName,
}: ObTooltipProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const popperInstance = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  });

  const handleEnter = (isOpen: boolean) => {
    !isOpen && referenceElement?.click();
    referenceElement?.blur();
  };

  const handleLeave = (isOpen: boolean) => {
    isOpen && referenceElement?.click();
    referenceElement?.blur();
  };

  return (
    <Popover className={wrapperClassName}>
      {({ open }) => (
        <div
          onMouseEnter={() => handleEnter(open)}
          onMouseLeave={() => handleLeave(open)}
        >
          <PopoverButton
            ref={(ref) => setReferenceElement(ref)}
            className={cx('cursor-default overflow-hidden', className)}
          >
            {children}
          </PopoverButton>
          <PopoverPanel
            ref={(ref) => setPopperElement(ref)}
            style={popperInstance?.styles.popper}
            {...(popperInstance?.attributes.popper ?? {})}
            className='z-10 mt-1'
          >
            <Transition
              appear
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <div className='bg-bgLightInverted rounded-md p-3'>
                <ObTypography
                  color='primary'
                  className='inline'
                >
                  {tooltipContent}
                </ObTypography>
              </div>
            </Transition>
          </PopoverPanel>
        </div>
      )}
    </Popover>
  );
};
