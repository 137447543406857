import { CampaignDeploymentCard } from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import CampaignDeployment from '../../state/mobx-experiment/campaign/campaign-deployment/campaign-deployment';

export interface CampaignDeploymentModelCardProps {
  campaignDeployment: CampaignDeployment;
  variant: 'micro' | 'mini';
  onNavigateCallback?: () => void;
}

/**
 * Wraps the CampaignDeploymentCard from the design system with a mapping to the Campaign Deployment mobx model
 * @returns
 */
export const CampaignDeploymentModelCard = observer(
  ({
    campaignDeployment,
    variant,
    onNavigateCallback,
  }: CampaignDeploymentModelCardProps) => {
    return (
      <CampaignDeploymentCard
        variant={variant}
        stage={campaignDeployment.stage}
        onNavigateCallback={onNavigateCallback}
        publishedAtTimestamp={campaignDeployment.publishedAtTimestamp}
        rejectionReason={campaignDeployment.rejectionReason}
      />
    );
  }
);
