import { useDialogService } from '@outbound/design-system';
import { useSendInvites } from 'src/query/workspace/use-workspace-invites-endpoints';
import {
  WORKSPACE_MEMBER_INVITE_FORM_DEF,
  WorkspaceMemberInviteFormDataSchema,
} from './invite-form.def';

export const useInviteForm = () => {
  const { openFormDialog } = useDialogService();
  const { mutateAsync: inviteToWorkspace } = useSendInvites();

  const openInviteFormDialog = () => {
    openFormDialog({
      formDefinition: WORKSPACE_MEMBER_INVITE_FORM_DEF,
      defaultValues: { inviteByEmail: [] },
      onFormSubmitCallback: async (
        data: WorkspaceMemberInviteFormDataSchema
      ) => {
        return inviteToWorkspace({
          invitations: data.inviteByEmail.map((email: string) => ({ email })),
        });
      },
      title: 'Invite Owner to Workspace',
    });
  };

  return {
    openInviteFormDialog,
  };
};
