import { LocationType, ServerDelta } from '@outbound/types';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from '../../base-model';
import Location from '../../business-context/location/location';

import { parseObrn } from '@otbnd/utils';
import { RootStore } from '../../root-store';

interface CampaignLocationData {
  isEnabled: boolean;
  type: LocationType;
}

class CampaignLocation extends BaseModel {
  static readonly paths = {
    ...BaseModel.paths,
    isEnabled: '/isEnabled',
    type: '/type',
  };

  private _locationType: LocationType; //Immutable
  private _locationId: string; //Immutable
  private _isEnabled: boolean;

  constructor(
    rootStore: RootStore,
    id: string,
    obrn: string,
    data: CampaignLocationData
  ) {
    const { scope } = parseObrn(obrn);
    super(rootStore, 'campaign/location', '1', id, scope, obrn);
    this._locationType = data.type;
    this._locationId = id;
    this._isEnabled = data.isEnabled;
  }

  protected makeObservableInternal(): void {
    makeObservable(this, {
      applyPatch: action,
      /**
       * This causes infinite re-rendering in the suspense boundary of campaign
       * detail pages intermittently. This only occurs when the customer-profile is not
       * pre-loaded and throws a promise while lazy loading.
       *
       * Since this happens in a suspense boundary and inside of the MobX store it was pretty
       * ugly to track down. I spent ~6hrs to get to the point of identifying the root cause.
       *
       * I'm leaving this here so if for some reason someone else thinks we need a computed property
       * here they can see the issue and understand why it was removed.
       *
       */
      // location: computed,
      _isObservable: observable,
    } as any);
  }

  public applyPatch(patch: Array<ServerDelta>) {
    runInAction(() => {
      for (const delta of patch) {
        if (delta.path === '/isEnabled') {
          const clientDelta = this._clientDeltas.get('/isEnabled');
          const applyChange = this.shouldDeltaBeApplied(clientDelta, delta);
          if (applyChange) {
            switch (delta.op) {
              case 'replace': {
                this._isEnabled = delta.value as boolean;

                break;
              }
            }
          }
        }
      }
    });
  }

  public toJson(): Record<string, any> {
    const base = super.toJson();
    return {
      ...base,
      [CampaignLocation.paths.type]: this._locationType,
      [CampaignLocation.paths.id]: this._locationId,
      [CampaignLocation.paths.isEnabled]: this._isEnabled,
    };
  }

  get location(): Location | null {
    return this._rootStore.locationStore.getById(this._locationId);
  }

  /**
   * Indicates that the location attached to this campaign has been deleted from the playbook.
   * We will give the user a call to action to remove the location from the campaign.
   */
  get isPlaybookLocationDeleted(): boolean {
    const playbookLocation = this._rootStore.locationStore.getById(
      this._locationId
    );
    return playbookLocation == null;
  }

  /**
   * Indicates that the location should be advertised to in the campaign
   */
  get isEnabled(): boolean {
    return this._isEnabled;
  }

  set isEnabled(value: boolean) {
    this._isEnabled = value;
  }
}

export default CampaignLocation;
