import { AnimatePresence, motion } from 'motion/react';

import { FeatureCard, FeatureCardProps } from '../../feature-card';

export interface BookATimeToLearnMoreProps
  extends Omit<FeatureCardProps, 'variant' | 'onGetFeatureCallback'> {
  onTimeBookedCallback: () => void;
  meetingName: string;
  meetingOwner: string;
}

const variants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};

/**
 * Workflow to introduce a user to a feature and offer them a time to learn more about it / upgrade to a higher plan
 * @returns
 */
export const BookATimeToLearnMore = ({
  meetingName,
  meetingOwner,
  ...featureCardProps
}: BookATimeToLearnMoreProps) => {
  /**
   * This may be expanded in the future to included embedded booking.
   * During development this was explored but due to issues with HubSpot's sizing and responsiveness
   * of their embeddable booking widget, it was decided to open the booking page in a new tab.
   *
   * Progress with the HubSpot Booking Component has been saved in a separate branch pat/hubspot-meeting-component
   */
  const step: string = 'PRESENT_FEATURE_OVERVIEW';

  return (
    <AnimatePresence mode='wait'>
      {step === 'PRESENT_FEATURE_OVERVIEW' && (
        <motion.div
          key='present_feature_overview'
          initial='initial'
          animate='animate'
          exit='exit'
          variants={variants}
          transition={{ duration: 0.5 }}
        >
          <div className='h-full flex justify-center items-center'>
            <FeatureCard
              {...featureCardProps}
              variant={'grid'}
              onGetFeatureCallback={() => {
                window.open(
                  `https://meetings.hubspot.com/${meetingOwner}/${meetingName}`,
                  '_blank'
                );
              }}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
