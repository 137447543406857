import { ObTypography } from '@outbound/design-system';
import { FC } from 'react';
import WorkspaceMembersDataGrid from './workspace-members-data-grid';

const WorkspaceMembers: FC<{}> = () => {
  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-col gap-2'>
        <ObTypography variant='h2'>Members</ObTypography>
        <ObTypography
          variant='body2'
          color='secondary'
        >
          Collaborate with your team. Manage who can access your workspace.
        </ObTypography>
      </div>
      <WorkspaceMembersDataGrid />
    </div>
  );
};

export default WorkspaceMembers;
