import { Deferred } from '@otbnd/utils';
import { ObrnObject } from '@outbound/types';
import { action, computed, observable, ObservableMap } from 'mobx';
import { BaseModel } from './base-model';
import { BaseStoreModelSyncMeta } from './base-store-model-sync-meta';
import { BaseTransformer } from './base-transformer';
import { BaseTransport } from './base-transport';
import { RootStore } from './root-store';

export abstract class BaseStore<T extends BaseModel> {
  protected _baseTransport: BaseTransport<any> | null = null;
  /**
   * Holds the Models in the Store.
   */
  @observable
  protected modelMap: ObservableMap<string, T> = observable.map();

  /**
   * Holds the Models that are marked for deletion.
   * In the case of an optimistic delete the model will be removed from the modelMap and added to this map.
   * If the server responds with a 200 status code the model will be removed from this map.
   * If the server responds with a 400 status code the model will be moved back to the modelMap.
   */
  protected optimisticDeleteModelMap: Map<string, T> = new Map<string, T>();

  /**
   * Holds the Sync Meta for the Models in the Store.
   */
  protected _modelSyncMeta: Map<string, BaseStoreModelSyncMeta<T>> = new Map();

  /**
   * The Root Store that this and all Stores belongs to.
   */
  protected rootStore: RootStore;

  protected _object: ObrnObject;

  constructor(
    rootStore: RootStore,
    object: ObrnObject,
    baseTransport?: BaseTransport<any>
  ) {
    this.rootStore = rootStore;
    this._object = object;
    this._baseTransport = baseTransport ?? null;
  }

  /**
   * Gets a Model from the Store by its ID.
   * This must be implemented by the Subclass.
   * @param id The Unique Identifier of the Model we Want to Return
   * @throws {Promise} If the Model needs to be lazy loaded a Promise is thrown
   *          which will be resolved once the Model is fetched.
   **/
  public getById(id: string): T | null {
    const model = this.getModelFromStoreOrLazyLoadFromServerById(id);
    //Make the model observable before returning it to the UI
    model?.makeObservable();
    return model;
  }

  /**
   * @param compareFn A function that defines the sort order of the Models
   * @returns A list of all Models in the Store sorted by ID (So that the order is predictable)
   */

  public list(
    compareFn: (a: T, b: T) => number = (a, b) => a.id.localeCompare(b.id)
  ): Array<T> {
    if (this._baseTransport != null) {
      const { status, promise } = this._baseTransport.bootstrapStatus();
      if (status === 'PENDING') {
        console.log(`MobX | Suspense:Trigger | ${this._object} | list`);

        promise.then(() => {
          console.log(`MobX | Suspense:Resolve | ${this._object} | list`);
        });

        throw promise; //Transport is Still Bootstrapping
      }
    }

    const allModels = Array.from(this.modelMap.values()).sort(compareFn);

    allModels.forEach((model) => {
      model.makeObservable();
    });

    return allModels;
  }

  @computed
  get listWithDefaultSort(): Array<T> {
    return this.list();
  }

  /**
   * Access the Model directly from the Store without lazy loading.
   * @param id
   * @returns
   */
  protected getModelFromStore(id: string): T | null {
    return this.modelMap.get(id) ?? null;
  }

  protected abstract requestLoadModelFromServer(id: string): void;

  /**
   * Update Sync Meta state to reflect that the model has been loaded from the server
   * @param id
   * @param model
   */
  protected updateSyncMetaForLoadedModel(id: string, model: T): void {
    const syncMeta = this.getModelSyncMeta(id);
    syncMeta.lastFetchedAtTimestamp = new Date().toISOString();
    syncMeta.isFetching = false;
    syncMeta.isNotFound = false;
    syncMeta.model = model;
    /**
     * If lazyDeferred is null it means no one is waiting for the model to be fetched.
     * It may of been fetched as part of bootstrapping or another call may of provided it
     * as an embedded resource.
     */
    if (syncMeta.lazyDeferred && syncMeta.lazyDeferred.status === 'PENDING') {
      console.log(`MobX | Suspense:Resolve | ${this._object} | ${id}`);
      syncMeta.lazyDeferred?.resolve();
    }
    syncMeta.isLoading = false;
  }

  /**
   * Called when the frontend instantiates a new model. We optimistically add the model to the store
   * @param id
   * @param model
   */
  protected createOptimisticSyncMetaForNewModel(id: string, model: T): void {
    const modelSyncMeta: BaseStoreModelSyncMeta<T> = {
      id,
      model: model,
      lazyDeferred: undefined,
      isError: false,
      isLoading: false,
      isFetching: false,
      isNotFound: false,
      isOptimisticCreate: true,
      isOptimisticallyDeleted: false,
      lastFetchedAtTimestamp: new Date().toISOString(),
    };
    this._modelSyncMeta.set(id, modelSyncMeta);
  }

  protected updateSyncMetaForOptimisticallyDeletedModel(id: string): void {
    const syncMeta = this.getModelSyncMeta(id);
    syncMeta.isOptimisticallyDeleted = true;
  }

  protected undoOptimisticDeleteSyncMetaForModel(id: string): void {
    console.log('Undoing Optimistic Delete Sync Meta for', id);
    const syncMeta = this.getModelSyncMeta(id);
    syncMeta.isOptimisticallyDeleted = false;
  }

  protected updateSyncMetaForUpdatedModel(id: string, model: T): void {
    const syncMeta = this.getModelSyncMeta(id);
    syncMeta.lastFetchedAtTimestamp = new Date().toISOString();
    syncMeta.isFetching = false;
    syncMeta.isLoading = false;
    syncMeta.isNotFound = false;
    syncMeta.model = model;
    if (syncMeta.lazyDeferred && syncMeta.lazyDeferred.status === 'PENDING') {
      console.log(`MobX | Suspense:Resolve | ${this._object} | ${id}`);
      syncMeta.lazyDeferred.resolve();
    }
  }

  protected updateSyncMetaForNotFoundModel(id: string): void {
    const syncMeta = this.getModelSyncMeta(id);
    syncMeta.lastFetchedAtTimestamp = new Date().toISOString();
    syncMeta.isFetching = false;
    syncMeta.isLoading = false;
    syncMeta.isNotFound = true;
    syncMeta.isOptimisticallyDeleted = false;
    if (syncMeta.lazyDeferred && syncMeta.lazyDeferred.status === 'PENDING') {
      console.log(
        `MobX | Suspense:Resolve (Not Found) | ${this._object} | ${id}`
      );
      syncMeta.lazyDeferred?.resolve();
    }
    syncMeta.model = null;
  }

  protected getModelFromStoreOrLazyLoadFromServerById(id: string): T | null {
    const modelSyncMeta = this.getModelSyncMeta(id);
    if (modelSyncMeta.isNotFound) {
      /**
       * Since the model is not found we will return null. Our contract with the UI is that null values are used
       * To indicate that a model with the given ID does not exist.
       *
       * TODO Think though if there are any cases where something is currently not found but can "Become" found?
       * - Eventual Consistency being the main issue?
       */
      return null;
    }
    if (modelSyncMeta.lastFetchedAtTimestamp == null) {
      /**
       * Since the last fetched timestamp is null it means that we have never loaded this model from the server.
       * At this point we need to lazy load it. Before we do we want to make sure that we are not already loading it.
       */
      if (!modelSyncMeta.isLoading) {
        /**
         * Since we have validated that we are not already lazy loading the model we can now set the isLoading flag to true.
         * This will prevent any other calls to this function from trying to load the model again.
         */
        modelSyncMeta.isLoading = true;
        modelSyncMeta.lazyDeferred = new Deferred();
        this.requestLoadModelFromServer(id);
      }

      if (modelSyncMeta.lazyDeferred === undefined) {
        /**
         * This is an invalid state that should never happen. If it does it means that we have a bug in our code.
         * When the model is being fetched the lazyDeferred should always be defined.
         */
        throw new Error('Invalid Store State. Lazy Deferred is not defined');
      }
      /**
       * Trigger the nearest Suspense boundary to wait for the model to be fetched.
       * The promise will be resolved once the model is received from the server at which point in
       * time the lastFetchedAtTimestamp will be set and the model will be returned.
       */
      console.log(`MobX | Suspense:Trigger | ${this._object} | ${id}`);

      throw modelSyncMeta.lazyDeferred.promise;
    } else {
      console.log(`MobX | Get Model (Cache Hit) | ${this._object} | ${id}`);

      return this.getModelFromStore(id);
    }
  }

  private initSyncMetaForUnFetchedModel(id: string): BaseStoreModelSyncMeta<T> {
    const modelSyncMeta: BaseStoreModelSyncMeta<T> = {
      id,
      model: null,
      lazyDeferred: undefined,
      isError: false,
      isLoading: false,
      isFetching: false,
      isNotFound: false,
      isOptimisticCreate: false,
      isOptimisticallyDeleted: false,
      lastFetchedAtTimestamp: null,
    };
    this._modelSyncMeta.set(id, modelSyncMeta);
    return modelSyncMeta;
  }

  /**
   * Returns the model sync meta for a given model id.
   * if we have no record of the model id, we will create a new sync meta for it
   * which indicates that the model is not fetched yet.
   * @param id
   * @returns
   */
  protected getModelSyncMeta(id: string): BaseStoreModelSyncMeta<T> {
    return (
      this._modelSyncMeta.get(id) ?? this.initSyncMetaForUnFetchedModel(id)
    );
  }

  /**
   * This should eventually become the default way to handle server updates.
   * This is labeled generic to help migrate from the initial implementation
   * that saw each store handle server updates in a custom way. I realized that
   * after implementing several stores that the logic was the same and could be
   * abstracted into a single method. We need to go back and convert all the stores
   * to use this method and than we can just update the method signature to remove the generic
   * @param id
   * @param resource
   * @param transformer
   */
  protected handleServerUpdateGeneric = (
    id: string,
    resource: any,
    transformer: BaseTransformer<any, any>
  ): void => {
    const clientCachedModel = this.modelMap.get(id);
    if (resource == null) {
      this.updateSyncMetaForNotFoundModel(id);
      this.modelMap.delete(id);
    } else if (clientCachedModel) {
      const patch = transformer.createPatchForCurrentModelAndIncomingResource(
        clientCachedModel,
        resource
      );
      clientCachedModel.applyPatch(patch);
      this.updateSyncMetaForUpdatedModel(id, clientCachedModel);
    } else {
      const model = transformer.fromApiResource(resource);
      this.modelMap.set(id, model);
      this.updateSyncMetaForLoadedModel(id, model);
    }
  };

  @action
  protected optimisticDeleteModel(id: string): void {
    const model = this.modelMap.get(id);
    if (model) {
      console.log('Optimistically Deleting Model', id);
      this.optimisticDeleteModelMap.set(id, model);
      this.modelMap.delete(id);

      this.updateSyncMetaForOptimisticallyDeletedModel(id);
    }
  }

  @action
  protected undoOptimisticDeleteModel(id: string): void {
    const model = this.optimisticDeleteModelMap.get(id);
    if (model) {
      console.log('Undoing Optimistic Delete', id);
      this.modelMap.set(id, model);
      this.optimisticDeleteModelMap.delete(id);
      this.undoOptimisticDeleteSyncMetaForModel(id);
    }
  }
}
