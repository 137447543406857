import { FC } from 'react';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';

export const socaialMediaTypes = [
  'facebook',
  'twitter',
  'linkedin',
  'instagram',
] as const;
export type SocialMedia = (typeof socaialMediaTypes)[number];

/**
 * Useful for type checking dynamic data fetched from API or CRM
 * @param maybeSocial
 * @returns
 */
export const isSocialMedia = (
  maybeSocial: unknown
): maybeSocial is SocialMedia => {
  return (
    typeof maybeSocial === 'string' &&
    socaialMediaTypes.includes(maybeSocial as any)
  );
};

export interface ObSocialLinkProps {
  socialMedia: SocialMedia;
  handle: string;
  size?: 'small' | 'medium';
  className?: string;
}

export const getSocialMediaIcon = (type: SocialMedia): IconSystemKeys => {
  let iconName: IconSystemKeys;

  switch (type) {
    case 'facebook':
      iconName = 'socialFacebook';
      break;
    case 'twitter':
      iconName = 'socialX';
      break;
    case 'linkedin':
      iconName = 'socialLinkedIn';
      break;
    case 'instagram':
      iconName = 'socialInstagram';
      break;
    default:
      throw new Error(`Invalid social media type: ${type}`);
  }

  return iconName;
};

export const generateProfileURL = (
  type: SocialMedia,
  handle: string
): string => {
  let baseURL = '';

  switch (type) {
    case 'facebook':
      baseURL = 'https://www.facebook.com/';
      break;
    case 'twitter':
      baseURL = 'https://www.twitter.com/';
      break;
    case 'linkedin':
      baseURL = 'https://www.linkedin.com/in/';
      break;
    case 'instagram':
      baseURL = 'https://www.instagram.com/';
      break;
    default:
      throw new Error(`Invalid social media type: ${type}`);
  }

  return baseURL + handle;
};

export const ObSocialLink: FC<ObSocialLinkProps> = ({
  socialMedia: type,
  handle,
  size = 'small',
  className,
}: ObSocialLinkProps) => {
  return (
    <a
      target='_blank' //We don't want to be removed from our apps, open page in new tab
      href={generateProfileURL(type, handle)}
      title={type}
      rel='noreferrer'
      className={className}
    >
      <ObIcon
        classNames='dark:text-contentTertiaryDark text-contentTertiaryLight'
        icon={getSocialMediaIcon(type)}
        size={size}
      />
      <span className='sr-only'>{type}</span>
    </a>
  );
};
