import {
  FeatureAlert,
  GoogleAdManagerIntegrationConfiguration,
  HealthCheckItemCard,
  IntegrationDetail,
  ObTypography,
  UserCard,
} from '@outbound/design-system';
import { ObLink } from '@outbound/design-system/src/components/elements/ob-link/ob-link';
import { IntegrationConfigHealthCheckStatus } from '@outbound/types';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FeatureIdentifier } from '../../../../../in-app-marketing/feature-list';
import { useFeatureUpSell } from '../../../../../in-app-marketing/use-feature-up-sell';
import { useRootStore } from '../../../../../state/mobx-experiment/use-root-store';
import User from '../../../../../state/mobx-experiment/user/user';
import IntegrationConfigGoogleAds from '../../../../../state/mobx-experiment/workspace/integration-configuration/extended/integration-configuration-google-ads';
import HealthCheckItemEvaluation from '../../../../../state/mobx-experiment/workspace/integration-configuration/health-check-item-execution/health-check-item-execution';
import IntegrationConfiguration from '../../../../../state/mobx-experiment/workspace/integration-configuration/integration-configuration';
import { IntegrationXWorkspace } from '../../../../../state/mobx-experiment/workspace/types/integration-x-workspace.type';
import Workspace from '../../../../../state/mobx-experiment/workspace/workspace';
import { FullPageErrorMessage } from '../../../campaigns/components/full-page-error-message';
import { useSetupIntegrationDialog } from './use-setup-integration-dialog';

const healthCheckStatusSortOrder: Record<
  IntegrationConfigHealthCheckStatus,
  number
> = {
  FATAL: 0,
  TIMEOUT: 1,
  FAIL: 2,
  WARNING: 3,
  PASS: 4,
  ABSTAIN: 5,
};

const renderOverview = (overview: any) => {
  if (overview == null || overview.content == null) {
    return <ObTypography>{overview}</ObTypography>;
  }

  const { intro, conclusion, features } = overview.content;

  return (
    <div className='flex flex-col gap-8'>
      <ObTypography variant='body2'>{intro}</ObTypography>
      <ul className='flex flex-col gap-4'>
        {features.map((feature: any, index: number) => (
          <li key={index}>
            <ObTypography
              variant='h5'
              color='secondary'
            >
              {feature.title}
            </ObTypography>
            <ObTypography variant='body2'>{feature.description}</ObTypography>
          </li>
        ))}
      </ul>
      <ObTypography variant='body2'>{conclusion}</ObTypography>
    </div>
  );
};

const renderUserNode = (user: User | null) => {
  if (user == null) {
    return <></>;
  }

  return (
    <div className='pt-1'>
      <UserCard
        variant='mini'
        name={user.fullName}
        emailAddress={user.email}
      />
    </div>
  );
};

const renderIntegrationConfigNode = (
  integrationConfiguration: IntegrationConfiguration
) => {
  switch (integrationConfiguration.type) {
    case 'GOOGLE_AD_MANAGER': {
      const googleAdManagerConfig =
        integrationConfiguration as IntegrationConfigGoogleAds;
      return (
        <GoogleAdManagerIntegrationConfiguration
          customerId={googleAdManagerConfig.customerNumber}
          connectExistingAccount={googleAdManagerConfig.connectExistingAccount}
          timeZone={googleAdManagerConfig.timeZone}
          isGoogleAdsTestAccount={googleAdManagerConfig.isGoogleAdsTestAccount}
          googleAdsAccountStatus={googleAdManagerConfig.googleAdsAccountStatus}
          descriptiveName={googleAdManagerConfig.descriptiveName}
          currencyCode={googleAdManagerConfig.currencyCode}
        />
      );
    }
    default:
      return <></>;
  }
};

export const IntegrationDetailPage: FC<{}> = observer(() => {
  const { workspaceSlug, integrationSlug } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { showFeatureUpSellDialog } = useFeatureUpSell();

  const { initiateConfigureIntegrationUserFlow } = useSetupIntegrationDialog();

  const { workspaceStore } = useRootStore();
  const workspace: Workspace | null = workspaceStore.getById(workspaceSlug!);

  if (workspace == null) {
    return <></>;
  }

  const integrationConfiguration: IntegrationXWorkspace | null =
    workspace.getIntegrationWithConfigurationBySlug(integrationSlug!);

  /**
   * Handle the case where the integration does not exist (Most likely due to a bad URL)
   */
  if (integrationConfiguration == null) {
    return (
      <FullPageErrorMessage
        heading={'Integration Not Found'}
        message={
          'It does not appear that the integration you are looking for exists.'
        }
        actionLabel={'Back to Integrations'}
        actionOnClick={() => {
          navigate('..');
        }}
      />
    );
  }

  const { integration, configuration } = integrationConfiguration ?? {};

  const minimumPlan = integration.minimumRequiredPlan ?? 'FREE';

  const isIntegrationAvailableOnCurrentPlan =
    workspace.isWorkspacesCurrentPlanIncludedInGivenPlan(minimumPlan);

  const returnToUriEncoded = searchParams.get('return-to');
  const returnTo = returnToUriEncoded
    ? decodeURIComponent(returnToUriEncoded)
    : null;

  const returnToLabelUriEncoded = searchParams.get('return-to-label');
  const returnToLabel = returnToLabelUriEncoded
    ? decodeURIComponent(returnToLabelUriEncoded)
    : null;

  return (
    <div className='flex flex-col gap-8'>
      {!returnTo && (
        <ObLink
          variant={'primary'}
          label='Integrations'
          iconLeft='chevronLeft'
          iconSize='small'
          onClick={() => navigate('..')} //Go Up One Level
        />
      )}

      {returnTo && (
        <ObLink
          variant='primary'
          label={returnToLabel ? returnToLabel : 'Back'}
          iconLeft='chevronLeft'
          iconSize='small'
          onClick={() => navigate(returnTo)}
        />
      )}

      {!isIntegrationAvailableOnCurrentPlan && (
        <FeatureAlert
          id={integration.featureToDisplayWhenMinimumPlanNotMet!.id}
          name={integration.featureToDisplayWhenMinimumPlanNotMet!.name}
          getFeatureCallToAction={
            integration.featureToDisplayWhenMinimumPlanNotMet!
              .getFeatureCallToAction
          }
          getFeatureCallToActionPitch={
            integration.featureToDisplayWhenMinimumPlanNotMet!
              .getFeatureCallToActionPitch
          }
          availableOnPlans={
            integration.featureToDisplayWhenMinimumPlanNotMet!.availableOnPlans
          }
          onGetFeatureCallback={() => {
            //Navigate to HubSpot Appointment Page
            window.open(
              `https://meetings.hubspot.com/${
                integration.featureToDisplayWhenMinimumPlanNotMet!
                  .getFeatureBookATimeMeetingOwner
              }/${
                integration.featureToDisplayWhenMinimumPlanNotMet!
                  .getFeatureBookATimeMeetingName
              }`,
              '_blank'
            );
          }}
        />
      )}
      <IntegrationDetail
        onRemoveIntegrationCallback={() => {
          configuration?.delete();
          navigate('..');
        }}
        isHealthCheckInProgress={configuration?.isHealthCheckInProgress!}
        lastHealthCheckCompletedAt={
          configuration?.healthCheckLastCompletedAtTimestamp
        }
        onCheckHealthCallback={() => {
          configuration?.runHealthChecks();
        }}
        healthCheckItemsNode={
          <>
            {configuration?.latestHealthCheckItemEvaluations
              .filter((hcEval) => !hcEval.isSkipped)
              .toSorted(
                (a, b) =>
                  healthCheckStatusSortOrder[a.outcome!.status!] -
                  healthCheckStatusSortOrder[b.outcome!.status!]
              )
              .map((hcEval: HealthCheckItemEvaluation) => {
                return (
                  <HealthCheckItemCard
                    key={hcEval.obrn}
                    status={hcEval.outcome?.status ?? 'ABSTAIN'}
                    name={hcEval.healthCheck.name}
                    message={hcEval.outcome?.message ?? ''}
                    ranAtTimestamp={hcEval.ranAtTimestamp ?? ''}
                    skippedDueToDependencies={hcEval.isSkipped}
                    variant={'table'}
                  />
                );
              })}
          </>
        }
        overviewContent={<>{renderOverview(integration.overview)}</>}
        websiteUrl={integration.website}
        docsUrl={integration.docsLink}
        enabledByUserNode={renderUserNode(
          configuration?.configuredByUser ?? null
        )}
        enabledAtTimestamp={configuration?.configuredAtTimestamp ?? ''}
        lifecycleStatus={configuration?.lifecycleStatus}
        operationalStatus={
          configuration == null
            ? 'NOT_CONFIGURED'
            : configuration.operationalStatus
        }
        name={integration.name}
        integrationIconUrl={integration.iconImagePublicUrl!}
        shortDescription={integration.shortDescription ?? ''}
        integrationConfigNode={
          configuration == null
            ? null
            : renderIntegrationConfigNode(configuration)
        }
        category={'AD_CHANNEL'}
        onEnableIntegrationCallback={() => {
          if (
            !isIntegrationAvailableOnCurrentPlan &&
            integration.featureToDisplayWhenMinimumPlanNotMet!.id != null
          ) {
            showFeatureUpSellDialog(
              integration.featureToDisplayWhenMinimumPlanNotMet!
                .id as FeatureIdentifier
            );
          }
          if (isIntegrationAvailableOnCurrentPlan) {
            initiateConfigureIntegrationUserFlow(integration, workspace);
          }
        }}
      />
    </div>
  );
});
