import { DialogServiceContextValue } from '@outbound/design-system';
import { NavigateOptions, URLSearchParamsInit } from 'react-router-dom';
import Campaign from '../../../state/mobx-experiment/campaign/campaign';

export const tryToPublishCampaign = async (
  campaign: Campaign,
  searchParams: URLSearchParams,
  dialogService: DialogServiceContextValue,
  setSearchParams: (
    nextInit?:
      | URLSearchParamsInit
      | ((prev: URLSearchParams) => URLSearchParamsInit)
      | undefined,
    navigateOpts?: NavigateOptions | undefined
  ) => void
) => {
  try {
    await campaign.deployCampaign();
  } catch (error) {
    if (
      error instanceof Error &&
      error.message === 'Creative is Not Ready to Publish'
    ) {
      if (searchParams.get('view') !== 'status') {
        setSearchParams((current) => {
          return new URLSearchParams({
            ...current,
            view: 'status',
          });
        });
      } else {
        dialogService.openAlert({
          title: 'Creative is Not Ready to Publish',
          body: 'Please fix the issues with the creative before deploying the campaign.',
        });
      }
    }
  }
};
