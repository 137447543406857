import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  userIsAMemberOfExactlyOneWorkspace,
  userIsNotAMemberOfAnyWorkspace,
} from '../../../services/TenantService';
import { WorkspacePickerItem } from '../../molecules/workspace-picker-item/workspace-picker-item';
import { RoutePaths } from '../../navigation/NavigationConstants';

import {
  ObButton,
  ObDivider,
  ObProfile,
  ObTypography,
  useDialogService,
} from '@outbound/design-system';
import { WorkspaceResource } from '@outbound/types';
import { usePollForMyWorkspaces } from '../../../query/my-workspaces/use-my-workspaces-endpoints';

import { useAuth0Extended } from '../../../auth/use-auth0-extended';
import { FullPageErrorMessage } from '../../../pages/dashboard/campaigns/components/full-page-error-message';
import { useDeleteMyUserMutation } from '../../../query/my-user/use-my-user-endpoints';
import { BasePageLayout } from '../../layouts/base-page';

export const ACCOUNT_DELETION_BODY_TEXT = `You're about to permanently delete your account and all associated data. This action is irreversible. If you're sure, click 'Confirm'. Otherwise, click 'Cancel'.`;
export const ACCOUNT_DELETION_ALERT_TEXT =
  'We are sorry to see you go, your account and its associated data have been removed from our system. If you wish to use outbound in the future you can sign up for a new account at outbound.com;';

const WorkspacePicker = () => {
  const { openAlert, openConfirmation } = useDialogService();
  const { isLoading, isAuthenticated, switchOrganization, logout, user } =
    useAuth0Extended();

  const navigate = useNavigate();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const [, setIsMounted] = useState(false);

  const [workspaceSwitchInProgress, setWorkspaceSwitchInProgress] =
    useState<boolean>(false);

  const {
    data: myWorkspaces,
    isLoading: isMyWorkspacesLoading,
    isError: isMyWorkspacesLoadingError,
  } = usePollForMyWorkspaces();

  const { mutateAsync: executeDeleteUserMutation } = useDeleteMyUserMutation();

  const loadingState = () => {
    return (
      <div className='flex flex-col items-center justify-center gap-4 self-stretch sm:min-w-[300px]'>
        <ObTypography variant={'body2'}>
          Fetching Existing Workspaces...
        </ObTypography>

        {[1, 2, 3].map((i) => (
          <WorkspacePickerItem
            key={i}
            workspaceName={''}
            workspaceSubTitle={''}
            onOpenWorkspaceCallback={() => Promise.resolve()}
            workspaceAuth0OrganizationId={''}
            workspaceAvatarImageUrl={''}
            loading={true}
          />
        ))}
      </div>
    );
  };

  const logoutUser = useCallback(
    (): Promise<void> =>
      new Promise((resolve) => {
        const redirectUrl = `${window.location.origin}`;
        logout({
          logoutParams: {
            returnTo: redirectUrl,
          },
        });
        resolve();
      }),
    [logout]
  );

  const handleOpenAccountDeleteAlertModal = useCallback(() => {
    openAlert({
      body: ACCOUNT_DELETION_ALERT_TEXT,
      title: 'Account Deleted',
    }).then(logoutUser);
  }, [logoutUser, openAlert]);

  const handleOpenAccountDeleteModal = useCallback(() => {
    openConfirmation({
      body: ACCOUNT_DELETION_BODY_TEXT,
      title: 'Confirm Account Deletion',
    })
      .then(async () => {
        await executeDeleteUserMutation({ email: user?.email || '' });
        handleOpenAccountDeleteAlertModal();
      })
      .catch(() => {
        //Empty
      });
    //Came up during workspace refactor. I don't have time to investigate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userIsAMemberOfExactlyOneWorkspace(myWorkspaces)) {
      console.log(
        'User Only Has One Workspace; Logging In to that Workspace and Navigating ',
        user,
        myWorkspaces?.items[0]
      );

      const workspace = myWorkspaces!.items[0];

      switchOrganization(workspace.auth0OrganizationId, workspace.id);
    }
  }, [
    isAuthenticated,
    isLoading,
    isMyWorkspacesLoading,
    myWorkspaces,
    switchOrganization,
    user,
  ]);

  if (isMyWorkspacesLoadingError) {
    return (
      <FullPageErrorMessage
        heading={'Unable to Load Workspaces'}
        message={
          'We were unable to connect to our servers to load your workspaces. Please check your connection and try again. If the issue persist please contact support.'
        }
        actionLabel={'Refresh Page'}
        actionOnClick={() => window.location.reload()}
      />
    );
  }
  if (!isLoading && !isAuthenticated) {
    return <></>;
  }

  if (
    !isLoading &&
    isAuthenticated &&
    !isMyWorkspacesLoading &&
    userIsNotAMemberOfAnyWorkspace(myWorkspaces)
  ) {
    return (
      <BasePageLayout
        contentPosition='center-top'
        background='scrollable-gradient-fixed'
        fullScreen={false}
      >
        <div className='flex flex-col gap-4 justify-center items-center max-w-lg'>
          <ObTypography
            variant={'body3'}
            color='secondary'
            className='text-center'
          >
            Currently logged in as
          </ObTypography>
          <div className='dark:bg-dark/background/surface border dark:border-dark/border/default/normal rounded-full flex-1 px-4 py-3'>
            <ObProfile
              variant={'individual'}
              title={user?.name || user?.email || user?.sub!}
              detail={user?.email || user?.sub!}
            />
          </div>
          <div>
            <ObTypography
              className='text-center'
              variant={'h2'}
            >
              You do not have any workspaces
            </ObTypography>
            <ObTypography
              variant={'body1'}
              color='secondary'
              className='text-center'
            >
              What would you like to do?
            </ObTypography>
          </div>
          <ObButton
            variant='primary'
            fullWidth='always'
            size='large'
            label={'Create a Workspace'}
            onClick={() => navigate(RoutePaths.WORKSPACE_CREATION_PAGE)}
          />
          <ObButton
            variant='outline'
            fullWidth='always'
            size='large'
            label={'Logout'}
            onClick={logoutUser}
          />
          <ObDivider label='or' />
          <ObButton
            variant='outline'
            size='large'
            fullWidth='always'
            label={'Delete Account'}
            onClick={handleOpenAccountDeleteModal}
          />
        </div>
      </BasePageLayout>
    );
  }

  if (workspaceSwitchInProgress) {
    return <></>;
  }

  return (
    <BasePageLayout
      contentPosition='center-top'
      background='scrollable-gradient-fixed'
    >
      {isLoading ||
      isMyWorkspacesLoading ||
      userIsAMemberOfExactlyOneWorkspace(myWorkspaces) ? (
        loadingState()
      ) : (
        <div className='flex flex-col justify-center items-center w-full gap-4 max-w-lg p-4'>
          <ObTypography
            className='text-center'
            variant={'h1'}
          >
            Your Workspaces
          </ObTypography>
          <ObTypography
            variant={'body1'}
            color='secondary'
            className='text-center'
          >
            Pick the Workspace you would like to open
          </ObTypography>

          <div className=' flex flex-col gap-2 '>
            {myWorkspaces?.items.map((ws: WorkspaceResource) => (
              <WorkspacePickerItem
                key={ws.id}
                workspaceName={ws.name}
                workspaceSubTitle={ws.id}
                onOpenWorkspaceCallback={async () => {
                  setWorkspaceSwitchInProgress(true);
                  await switchOrganization(ws.auth0OrganizationId, ws.id);
                }}
                workspaceAuth0OrganizationId={ws.auth0OrganizationId}
                workspaceAvatarImageUrl={ws.iconPublicUrl || ''}
                loading={isLoading}
              />
            ))}
          </div>

          <ObDivider label='or' />
          <ObButton
            variant='outline'
            fullWidth='always'
            size='large'
            label={'Create a Workspace'}
            onClick={() => navigate(RoutePaths.WORKSPACE_CREATION_PAGE)}
          />
          <ObButton
            variant='outline'
            fullWidth='always'
            size='large'
            label={'Logout'}
            onClick={logoutUser}
          />
        </div>
      )}
    </BasePageLayout>
  );
};
export default WorkspacePicker;
