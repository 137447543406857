import { NavItem } from '../../../../../components/navigation/dashboard-route.type';
import WorkspaceMembers from './workspace-members';

export const memberSettingsRoute: NavItem = {
  title: 'Members',
  route: 'members',
  component: WorkspaceMembers,
  showInNavMenu: ['workspace-settings-nav'],
  icon: 'users',
};
