import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';

import { ObTypography } from '../../../../components/elements/ob-typography/ob-typography';
import { ObIcon } from '../../../../tokens/icons/ob-icon/ob-icon';
import { CreativePreviewCreativeDetailProps } from '../side-bar/side-bar';

export interface CreativePreviewSideBarProps {
  creatives: Array<CreativePreviewCreativeDetailProps>;
  selectedCreativeId: string | null;
  onCreativeSelected: (creativeId: string | null) => void;
}

export const CreativeSelector = ({
  creatives,
  selectedCreativeId,
  onCreativeSelected,
}: CreativePreviewSideBarProps) => {
  return (
    <Listbox
      value={selectedCreativeId}
      onChange={(value) => {
        onCreativeSelected(value);
      }}
    >
      <ListboxButton
        className={
          'flex md:hidden border border-dark/border/default/normal rounded-lg h-14 p-4 m-2'
        }
      >
        <div className='flex flex-row items-center justify-between gap-2 flex-1'>
          <ObTypography
            truncate={true}
            className='flex-shrink'
          >
            {creatives.find((c) => c.id === selectedCreativeId)?.name}
          </ObTypography>
          <ObIcon
            icon='chevronDown'
            size={'medium'}
          />
        </div>
      </ListboxButton>
      <ListboxOptions
        anchor='bottom'
        portal={true}
        className='p-2 bg-dark/background/surface rounded-md'
      >
        {creatives.map((creative) => {
          return (
            <ListboxOption
              key={creative.id}
              value={creative.id}
              className='p-3 rounded-md data-[focus]:bg-dark/action/primary-v2/active hover:bg-dark/action/neutral/hover active:bg-dark/action/neutral/active'
            >
              <div
                key={creative.id}
                data-selected={selectedCreativeId === creative.id}
              >
                <ObTypography truncate={true}>{creative.name}</ObTypography>
              </div>
            </ListboxOption>
          );
        })}
      </ListboxOptions>
    </Listbox>
  );
};
