import { NavItem } from '../../../../../components/navigation/dashboard-route.type';
import { BillingSettingPage } from './billing-setting-page';

export const billingRoute: NavItem = {
  title: 'Billing',
  route: 'billing',
  component: BillingSettingPage,
  showInNavMenu: ['workspace-settings-nav'],
  featureFlag: 'navBilling',
};
