import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { NavItem } from '../../../components/navigation/dashboard-route.type';
import { CampaignDetailPage } from './campaign-detail-page';
import { CampaignsPage } from './campaigns-page';
import { CampaignWhatPage } from './components/campaigns-what-page';

/**
 * Routes for the Campaigns Module of the Outbound Platform
 */
export const campaignRoute: NavItem = {
  title: 'Campaigns',
  route: 'campaigns',
  icon: 'CampaignIcon',
  index: true,
  component: () => (
    <Suspense>
      <Outlet />
    </Suspense>
  ), //Handle the List or Detail View
  showInNavMenu: ['workspace-side-nav'],
  childRoutes: [
    {
      title: 'Campaigns',
      route: '',
      index: true,

      component: () => <CampaignsPage />,
    },
    {
      title: '',
      route: ':campaignId',
      component: () => <Outlet />,
      childRoutes: [
        {
          index: true,
          title: 'Campaign Detail',
          route: 'details',
          component: CampaignDetailPage,
          childRoutes: [
            {
              title: 'Campaign Detail',
              route: '',
              index: true,
              component: CampaignWhatPage,
            },
          ],
        },
      ],
    },
  ],
};
