import { FormDefinition, FormFieldType } from '@outbound/design-system';

export const GOOGLE_AD_MANGER_USER_INPUT_FORM: FormDefinition = {
  id: 'google-ad-manager-config-form',
  fullWidthSubmitButton: undefined,

  submitButtonLabel: undefined,
  heading: '',
  subHeading: '',
  sections: [
    {
      id: '',
      heading: '',
      subHeading: '',
      fields: [
        {
          type: FormFieldType.RADIO,
          id: 'useExistingOrNewAccount',
          label: 'How do you want to connect your Google Ad Manager Account?',
          helperText: '',
          size: 'small',
          validationSchema: {
            isRequired: true,
          },
          fieldTypeSettings: {
            options: [
              {
                label:
                  'I do not have one, Setup a New Google Ad Account for me.',
                value: 'CREATE_NEW_ACCOUNT',
              },
              {
                label: 'I want to use my existing Google Ad Account.',
                value: 'USE_EXISTING_ACCOUNT',
              },
            ],
          },
        },
        {
          id: 'googleAdsCustomerNumber',
          autofocus: true,
          label: 'Google Ads Account Number',
          type: FormFieldType.TEXT,
          conditionallyHideFieldFunction: (data: any) => {
            return data['useExistingOrNewAccount'] === 'CREATE_NEW_ACCOUNT';
          },
          helperText:
            'This is a 10 digit number that can be found in your Google Ad Manager account.',
          validationSchema: {
            isRequired: true,
          },
          fieldTypeSettings: {},
        },
      ],
    },
  ],
};
