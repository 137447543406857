import {
  CampaignChannel,
  CampaignDeploymentResource,
  CampaignLifecycleStatus,
  CampaignPausedByUserState,
  CampaignServingState,
  CampaignServingStateReason,
  ObCustomerStatus,
  OutboundCampaignGoal,
  ServerDelta,
} from '@outbound/types';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { BaseModel } from '../base-model';
import Creative from '../creative/creative';

import { Deferred, toObrn } from '@otbnd/utils';
import { formatCurrency } from '@outbound/design-system/src/utilities/format-utilities';
import { delegateDeltaToNestedLocalModel } from '../framework/patch/nested-model-map-patch';
import { catchSuspense } from '../framework/suspense-utils';
import { RootStore } from '../root-store';
import { IntegrationXWorkspace } from '../workspace/types/integration-x-workspace.type';
import { CampaignCustomerProfile } from './campaign-customer-profile/campaign-customer-profile';
import CampaignDeployment from './campaign-deployment/campaign-deployment';
import CampaignHighlight from './campaign-highlight/campaign-highlight';
import CampaignLocation from './campaign-location/campaign-location';
import CampaignOutboundGoalContext from './campaign-ob-goal-context/base-ob-goal-context';

export interface CampaignHealthTodo {
  title: string;
  description: string;
  completionStatus: 'TODO' | 'DONE';
  documentationHref: string;
  todoType:
    | 'activate-campaign'
    | 'publish-campaign'
    | 'review-creative-errors'
    | 'integration-health-check-item'
    | 'integration-not-configured';
}

const MANUALLY_SYNCED_ATTRIBUTES = [
  'budget',
  'name',
  'pausedByUserState',
] as const;

type ManuallySyncedAttributes = (typeof MANUALLY_SYNCED_ATTRIBUTES)[number];

/**
 * Mobx store for a single campaign
 * Server state synchronization code is in a first draft state. We should not consider this code the ideal
 * pattern.
 *
 * The goals are to only expose the data that the frontend needs to render the campaign and to make it's updates
 * while keeping the component code free of any API calls or synchronization logic.
 *
 * The page component should only be concerned with rendering the campaign and updating the properties of the campaign
 * that it needs to.
 *
 * The goal is to have 100% of the API calls and synchronization logic abstracted away from the UI components.
 */
class Campaign extends BaseModel {
  static readonly paths = {
    ...BaseModel.paths,
    name: '/name',
    servingState: '/servingState',
    servingStateReason: '/servingStateReason',
    servingStateLastCalculatedAtTimestamp:
      '/servingStateLastCalculatedAtTimestamp',
    pausedByUserState: '/pausedByUserState',
    dailyBudget: '/dailyBudget',
    channel: '/channel',
    goal: '/goal',
    lifecycleStatus: '/lifeCycleStatus',
    highlights: '/highlights',
    locations: '/campaignLocations',
    customerProfile: '/campaignCustomerProfile',
    latestDeployment: '/latestDeployment',
  };
  /**
   * Used to track server values of manually synced attributes.
   * This should probably be converted to a map with paths as keys.
   * vs a bunch of individual fields.
   */
  private _nameServerValue: string;
  private _dailyBudgetServerValue: number;
  private _pausedByUserStateServerValue: CampaignPausedByUserState;

  private readonly _channel: CampaignChannel;
  private _lifecycleStatus: CampaignLifecycleStatus;

  private _name: string;

  private _servingState: CampaignServingState;
  private _servingStateReason: CampaignServingStateReason;
  private _servingStateLastCalculatedAtTimestamp: string;
  private _pausedByUserState: CampaignPausedByUserState;

  private _dailyBudget: number;

  private readonly _outboundCampaignGoal: OutboundCampaignGoal;
  private readonly _obGoalContext: CampaignOutboundGoalContext;

  /**
   * We prefer maps over arrays for internal storage since we can be explicit about what we are patching
   * This avoids a bunch of ambiguity about array items moving around.
   * We still return arrays to the UI since that is what it expects but we can sort them however we like.
   **/
  private _campaignHighlights: Map<string, CampaignHighlight>;
  private _campaignLocations: Map<string, CampaignLocation>;
  private _campaignCustomerProfile: CampaignCustomerProfile | null = null; //Only Null on Initialization
  private _latestDeployment: CampaignDeployment | null = null;

  constructor(
    rootStore: RootStore,
    id: string,
    workspaceId: string,
    channel: CampaignChannel,
    name: string,
    outboundCampaignGoal: OutboundCampaignGoal,
    servingState: CampaignServingState,
    servingStateReason: CampaignServingStateReason,
    servingStateLastCalculatedAtTimestamp: string,
    pausedByUserState: CampaignPausedByUserState,
    lifecycleStatus: CampaignLifecycleStatus,
    dailyBudget: number,
    latestDeployment: CampaignDeployment | null,
    campaignHighlights: Array<CampaignHighlight>,
    campaignLocations: Array<CampaignLocation>,
    campaignCustomerProfile: CampaignCustomerProfile | null,
    obGoalContext: CampaignOutboundGoalContext
  ) {
    super(rootStore, 'campaign', '1', id, workspaceId);

    this._channel = channel;
    this._outboundCampaignGoal = outboundCampaignGoal;
    this._lifecycleStatus = lifecycleStatus;
    this._name = name;
    this._nameServerValue = name;

    this._servingState = servingState;
    this._servingStateReason = servingStateReason;
    this._servingStateLastCalculatedAtTimestamp =
      servingStateLastCalculatedAtTimestamp;
    this._pausedByUserState = pausedByUserState;
    this._pausedByUserStateServerValue = pausedByUserState;

    this._dailyBudget = dailyBudget;
    this._dailyBudgetServerValue = dailyBudget;

    this._campaignHighlights = new Map<string, CampaignHighlight>(
      campaignHighlights.map((i) => [i.obrn, i])
    );
    this._campaignLocations = new Map<string, CampaignLocation>(
      campaignLocations.map((i) => [i.obrn, i])
    );
    this._campaignCustomerProfile = campaignCustomerProfile;

    this._obGoalContext = obGoalContext;

    this._latestDeployment = latestDeployment;
  }

  protected makeObservableInternal() {
    this._campaignHighlights.forEach((h) => h.makeObservable());
    this._campaignLocations.forEach((l) => l.makeObservable());
    this._latestDeployment?.makeObservable();
    if (this._campaignCustomerProfile) {
      this._campaignCustomerProfile.makeObservable();
    }
    makeObservable(this, {
      _name: observable,
      _servingState: observable,
      _servingStateReason: observable,
      _servingStateLastCalculatedAtTimestamp: observable,
      _pausedByUserState: observable,
      _dailyBudgetServerValue: observable,
      _dailyBudget: observable, // Make the private field observable
      dailyBudget: computed, // Make the getter computed (ensures MobX tracks usage of the getter)
      _lifecycleStatus: observable,
      _latestDeployment: observable,
      _campaignHighlights: observable,
      _campaignLocations: observable,
      _campaignCustomerProfile: observable,
      _obGoalContext: observable,

      isAnyCreativeErrored: computed,
      isAnyCreativeStillGenerating: computed,
      isCreativeReadyToPublish: computed,
      creativeStatusMessage: computed,

      servingState: computed,
      servingStateReason: computed,
      pausedByUserState: computed,
      isDirty: computed,
      isBudgetDirty: computed,
      campaignHighlights: computed,
      campaignLocations: computed,
      campaignCustomerProfile: computed,
      allCreatives: computed,
      isLaunched: computed,
      pauseCampaign: action,
      unPauseCampaign: action,
    } as any);
  }

  public async deployCampaign() {
    if (!this.isCreativeReadyToPublish) {
      throw new Error('Creative is Not Ready to Publish');
    }
    await runInAction(async () => {
      //Temp Update API To Accept this Value
      const deploymentId = `optimistic-${Date.now()}`;

      //Optimistically Create a latest deployment
      this._latestDeployment = new CampaignDeployment(
        this.rootStore,
        deploymentId,
        toObrn({
          objectType: 'campaign/deployment',
          localPathId: `${this._id}/${deploymentId}`,
          scope: this.workspaceId,
        }),
        {
          tags: ['latest'],
          stage: 'INITIALIZING',
        }
      );

      this._latestDeployment.makeObservable();
      this._servingStateReason = 'FIRST_DEPLOYMENT_IN_PROGRESS'; //Optimistically Update the Serving State Reason

      const response =
        await this.rootStore.transport.campaignTransport.deploymentTransport.postCampaignDeployment(
          this.id
        );

      if (this.latestDeployment?.id === deploymentId) {
        const actualDeploymentId = response.data.id;
        this._latestDeployment = new CampaignDeployment(
          this.rootStore,
          actualDeploymentId,
          toObrn({
            objectType: 'campaign/deployment',
            localPathId: actualDeploymentId,
            scope: this.workspaceId,
          }),
          {
            tags: response.data.tags,
            stage: response.data.status,
          }
        );
        this._latestDeployment.makeObservable();
      }
    });
    //REMOVE ONCE WEBSOCKET IS IN PLACE
    this.rootStore.transport.campaignTransport.pollForDeploymentStatus(this.id);
  }

  /**
   * Very manual patching methodology.
   * This is a first take at this to POC out the State Sync Engine.
   * As we learn more about how we want to handle this, we will refactor this code into something more
   * maintainable and scalable.
   */
  public applyPatch(patch: Array<ServerDelta>) {
    console.log(`Applying Patch to Campaign: ${this.obrn}`, patch);
    /**
     * Indicate to MobX that we want all the changes to be applied in a single transaction.
     * This avoids the UI from updating multiple times for each change.
     */
    runInAction(() => {
      for (const operation of patch) {
        if (operation.path === Campaign.paths.dailyBudget) {
          this.patchDailyBudget(operation);
        }
        if (operation.path === Campaign.paths.servingState) {
          this.patchServingState(operation);
        }
        if (operation.path === Campaign.paths.servingStateReason) {
          this.patchServingStateReason(operation);
        }
        if (
          operation.path ===
          Campaign.paths.servingStateLastCalculatedAtTimestamp
        ) {
          this.patchServingStateLastCalculatedAtTimestamp(operation);
        }
        if (operation.path === Campaign.paths.pausedByUserState) {
          this.patchPausedByUserState(operation);
        }
        if (operation.path === Campaign.paths.name) {
          this.patchName(operation);
        }
        if (operation.path === Campaign.paths.lifecycleStatus) {
          this.patchLifecycleStatus(operation);
        }
        if (operation.path.startsWith(Campaign.paths.highlights)) {
          this.patchCampaignHighlights(operation);
        }
        if (operation.path === Campaign.paths.customerProfile) {
          this.patchCustomerProfile(operation);
        }
        if (operation.path.startsWith(Campaign.paths.locations)) {
          this.patchCampaignLocations(operation);
        }
        if (operation.path.startsWith(Campaign.paths.latestDeployment)) {
          this.patchLatestDeployment(operation);
        }
      }
    });
  }

  private patchServingState(operation: ServerDelta) {
    switch (operation.op) {
      case 'replace': {
        this._servingState = operation.value as CampaignServingState;
        break;
      }
    }
  }
  private patchServingStateReason(operation: ServerDelta) {
    switch (operation.op) {
      case 'replace': {
        this._servingStateReason =
          operation.value as CampaignServingStateReason;
        break;
      }
    }
  }
  private patchServingStateLastCalculatedAtTimestamp(operation: ServerDelta) {
    switch (operation.op) {
      case 'replace': {
        this._servingStateLastCalculatedAtTimestamp = operation.value as string;
        break;
      }
    }
  }

  private patchPausedByUserState(operation: ServerDelta) {
    switch (operation.op) {
      case 'replace': {
        this._pausedByUserState = operation.value as CampaignPausedByUserState;
        break;
      }
    }
  }

  private patchName(operation: ServerDelta) {
    switch (operation.op) {
      case 'replace': {
        this._name = operation.value as string;
        break;
      }
    }
  }

  private patchLifecycleStatus(operation: ServerDelta) {
    switch (operation.op) {
      case 'replace': {
        console.log('Updating Lifecycle Status', operation.value);
        this._lifecycleStatus = operation.value as CampaignLifecycleStatus;
        break;
      }
    }
  }

  private patchDailyBudget(operation: ServerDelta) {
    switch (operation.op) {
      case 'replace': {
        this._dailyBudgetServerValue = operation.value as number;
        this._dailyBudget = operation.value as number;
        break;
      }
    }
  }

  private patchCampaignLocations(delta: ServerDelta) {
    switch (delta.op) {
      case 'replace': {
        delegateDeltaToNestedLocalModel(this._campaignLocations, delta);
        break;
      }
      case 'add': {
        //Instantiate the Location
        const location = new CampaignLocation(
          this._rootStore,
          delta.value[CampaignLocation.paths.id],
          toObrn({
            objectType: 'campaign/location',
            localPathId: `${this._id}/${CampaignLocation.paths.type}/${
              delta.value[CampaignLocation.paths.id]
            }`,
            scope: this.workspaceId,
          }),
          {
            isEnabled: delta.value[CampaignLocation.paths.isEnabled],
            type: delta.value[CampaignLocation.paths.type],
          }
        );
        this._campaignLocations.set(location.obrn, location);
      }
      case 'remove': {
        this._campaignLocations.delete(
          delta.value[CampaignLocation.paths.obrn]
        );
      }
    }
  }

  /**
   * Can we add decorators to the class attribute and have it be aware of the path?
   * @PatchableMap('/highlights') and have this code be inside of it? Possibly extending a base class with some common features?
   * @param delta
   */
  private patchCampaignHighlights(delta: ServerDelta) {
    switch (delta.op) {
      case 'replace': {
        /**
         * Since this is a nested object we will delegate the updates to it's attributes
         * down to the nested object itself.
         */
        delegateDeltaToNestedLocalModel(this._campaignHighlights, delta);
        break;
      }
      case 'add': {
        //Construct a new Campaign Highlight
        const campaignHighlight = new CampaignHighlight(
          this._rootStore,
          delta.value[CampaignHighlight.paths.id],
          delta.value[CampaignHighlight.paths.obrn],
          {
            isEnabled: delta.value[CampaignHighlight.paths.isEnabled],
            associatedCreativeIds:
              delta.value[CampaignHighlight.paths.associatedCreativeIds],
            highlightType: delta.value[CampaignHighlight.paths.type],
            highlightedObjectObrn:
              delta.value[CampaignHighlight.paths.highlightedObjectObrn],
            highlightedObjectContext:
              delta.value[CampaignHighlight.paths.context],
            associatedLandingPageObrn:
              delta.value[CampaignHighlight.paths.associatedLandingPageObrn],
          }
        );

        //Ensure the new instance is observable
        campaignHighlight.makeObservable();

        //Add the Highlight to the Campaigns Map
        this._campaignHighlights.set(
          delta.value[CampaignHighlight.paths.obrn],
          campaignHighlight
        );
        break;
      }
      case 'remove': {
        //Remove the Highlight from the map
        this._campaignHighlights.delete(
          delta.value[CampaignHighlight.paths.obrn]
        );
        break;
      }
    }
  }

  private patchCustomerProfile(operation: ServerDelta) {
    if (!this._campaignCustomerProfile && operation.op === 'add') {
      const customerProfileId =
        operation.value[CampaignCustomerProfile.paths.id];

      this._campaignCustomerProfile = new CampaignCustomerProfile(
        this._rootStore,
        customerProfileId,
        this.workspaceId
      );
    } else if (this._campaignCustomerProfile && operation.op === 'replace') {
      this._campaignCustomerProfile.applyPatch([operation]);
    }
  }

  private patchLatestDeployment(operation: ServerDelta) {
    if (operation.op === 'replace') {
      //Case where we are "Replacing the entire Deployment"
      if (operation.path === Campaign.paths.latestDeployment) {
        this._latestDeployment = null;
        const resource = operation.value as CampaignDeploymentResource;
        this._latestDeployment = new CampaignDeployment(
          this._rootStore,
          resource.id,
          resource.obrn,
          {
            tags: ['latest', resource.versionTimestamp],
            stage: resource.status,
          }
        );
        // Ensure the new instance is observable
        this._latestDeployment.makeObservable();
      } else {
        const [, , ...childPath] = operation.path.split('/');
        console.log('Forward Patch to Latest Deployment', childPath);
        this._latestDeployment?.applyPatch([
          {
            ...operation,
            path: `/${childPath.join('/')}`,
          },
        ]);
      }
    }
  }

  public toJson(): Record<string, any> {
    const baseJson = super.toJson();

    /**
     * Create a JSON Object of OBRN to JSON for the Campaign Highlights
     */
    const campaignHighlights = Array.from(
      this._campaignHighlights.values()
    ).reduce<Record<string, any>>((acc, mapItem) => {
      acc[mapItem.obrn] = mapItem.toJson();
      return acc;
    }, {});

    /**
     * Create a JSON Object of OBRN to JSON for the Campaign Locations
     */
    const locations = Array.from(this._campaignLocations.values()).reduce<
      Record<string, any>
    >((acc, mapItem) => {
      acc[mapItem.obrn] = mapItem.toJson();
      return acc;
    }, {});

    return {
      ...baseJson,
      [Campaign.paths.channel]: this._channel,
      [Campaign.paths.name]: this._name,
      [Campaign.paths.goal]: this._outboundCampaignGoal,
      [Campaign.paths.servingState]: this._servingState,
      [Campaign.paths.servingStateReason]: this._servingStateReason,
      [Campaign.paths.servingStateLastCalculatedAtTimestamp]:
        this._servingStateLastCalculatedAtTimestamp,
      [Campaign.paths.pausedByUserState]: this._pausedByUserState,
      [Campaign.paths.dailyBudget]: this._dailyBudget,
      [Campaign.paths.lifecycleStatus]: this._lifecycleStatus,
      [Campaign.paths.latestDeployment]:
        this._latestDeployment == null ? null : this._latestDeployment.toJson(),
      [Campaign.paths.highlights]: {
        ...campaignHighlights,
      },
      [Campaign.paths.locations]: {
        ...locations,
      },
      [Campaign.paths.customerProfile]: this._campaignCustomerProfile?.toJson(),
    };
  }

  get channel(): CampaignChannel {
    return this._channel;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get servingState(): CampaignServingState {
    return this._servingState;
  }

  get servingStateReason(): CampaignServingStateReason {
    return this._servingStateReason;
  }

  get servingStateLastCalculatedAtTimestamp(): string {
    return this._servingStateLastCalculatedAtTimestamp;
  }

  get pausedByUserState(): CampaignPausedByUserState {
    return this._pausedByUserState;
  }

  get health(): {
    title: string;
    description: string;
    status: ObCustomerStatus;
    todos: Array<CampaignHealthTodo>;
  } {
    /**
     * When evaluating health we will look at the Campaigns Attributes as well as the Integration Configuration that the Campaign uses
     * to assess the overall "Health" of the Campaign.
     */
    const integrationTodos: Array<CampaignHealthTodo> = [];

    if (this.primaryIntegrationConfiguration?.configuration == null) {
      integrationTodos.push({
        title: 'Setup Google Ads Integration',
        description:
          'You have not setup your Google Ads Integration. Please setup your Google Ads Integration to publish your campaign.',
        completionStatus: 'TODO',
        documentationHref:
          'https://docs.outbound.com/integrations-google-ads-manager',
        todoType: 'integration-not-configured',
      });
    } else {
      const googleAdsAdminInviteHealthCheckNotPassed =
        this.primaryIntegrationConfiguration.configuration.latestHealthCheckItemEvaluations.find(
          (item) =>
            item.healthCheckItemId === 'c1449f7e-06c4-4ca2-a5a1-365141f4c13e' &&
            item.outcome?.status !== 'PASS'
        );

      const googleAdsPaymentMethodHealthCheckNotPassed =
        this.primaryIntegrationConfiguration.configuration.latestHealthCheckItemEvaluations.find(
          (item) =>
            item.healthCheckItemId === 'd842b98c-32a7-49f3-bd58-d52653ecb0cc' &&
            item.outcome?.status !== 'PASS'
        );

      if (googleAdsAdminInviteHealthCheckNotPassed) {
        integrationTodos.push({
          title: `Accept Google Ads Admin invite sent to ${this.primaryIntegrationConfiguration.configuration.configuredByUser.email}`,
          description:
            'You Have not accepted the invite to your Google Ads Account. You must accept this invite in order to add a payment method for your ad spend. If you have already done this click "Check Again".',
          completionStatus: 'TODO',
          documentationHref:
            'https://docs.outbound.com/Why-You-Received-a-Google-Ads-Admin-Invite-and-What-It-Means-185040e853e280b9ad16f71f3cfece73',
          todoType: 'integration-health-check-item',
        });
      }

      if (googleAdsPaymentMethodHealthCheckNotPassed) {
        integrationTodos.push({
          title: 'Add Payment Method to Google Ads Account',
          description:
            'You have not added a payment method to your Google Ads Account. Please add a payment method in order to run your ads.',
          completionStatus: 'TODO',
          documentationHref:
            'https://docs.outbound.com/why-do-i-need-to-pay-for-google-search-ads',
          todoType: 'integration-health-check-item',
        });
      }
    }

    switch (this._lifecycleStatus) {
      case 'ACTIVE':
        if (this._latestDeployment == null) {
          if (this.isAnyCreativeStillGenerating) {
            return {
              status: 'internal-action-required',
              title: 'Your Ads are Being Created',
              description:
                'We are currently creating your ads. This process can take a minute.',
              todos: [],
            };
          }
          if (this.isAnyCreativeErrored) {
            return {
              status: 'customer-action-required',
              title: 'Your Ad Creative Has Errors',
              description:
                'Your ads have errors. Please correct the errors before publishing.',
              todos: [
                {
                  title: 'Fix Creative Errors',
                  description:
                    'One or more of your ads has an error. Please correct the errors in order to publish.',
                  completionStatus: 'TODO',
                  documentationHref: '',
                  todoType: 'review-creative-errors',
                },
              ],
            };
          }
          return {
            status: 'customer-action-required',
            title: 'Your Ads Have Not Been Published.',
            description:
              "You are almost ready to go! Publish your campaign to upload your Ads to Google's Ad Manager.",
            todos: [
              {
                ...integrationTodos,
                title: 'Publish Your Campaign',
                description:
                  "You are almost ready to go! Publish your campaign to upload your Ads to Google's Ad Manager.",
                completionStatus: 'TODO',
                documentationHref:
                  'https://docs.outbound.com/Publishing-Outbound-Campaigns-to-Ad-Channel-Campaigns-185040e853e280d682ebc0b2d9b1cdbb?pvs=4',
                todoType: 'publish-campaign',
              },
            ],
          };
        }
        if (
          this._servingState === 'NOT_SERVING' &&
          this._latestDeployment != null &&
          this._latestDeployment.stage === 'SUCCEEDED'
        ) {
          if (this._pausedByUserState === 'CAMPAIGN_PAUSED_BY_USER') {
            return {
              status: 'customer-action-required',
              title: 'Your Ads Are Not Running!',
              description:
                'Your campaign has been published to Google Ad Manager but it is not yet running.',
              todos: [
                ...integrationTodos,
                {
                  title: 'Enable your Campaign',
                  description:
                    'Your campaign is currently paused. Enable your campaign to start serving ads.',
                  completionStatus: 'TODO',
                  documentationHref:
                    'https://docs.outbound.com/Why-and-When-to-Enable-or-Pause-Your-Ads-185040e853e280c698aed115f75cd1b1',
                  todoType: 'activate-campaign',
                },
              ],
            };
          }
        }

        /**
         * This case is here for when there are not problems with the campaign itself but there are issues with the integration
         * that the campaign uses.
         */
        if (integrationTodos.length > 0) {
          /**
           * Since the campaign is not running our messaging will reflect that.
           */
          if (this._servingState === 'NOT_SERVING') {
            return {
              status: 'customer-action-required',
              title: 'Your Ads Are Not Running!',
              description:
                'Your Google Ads Integration has issues that need to be resolved before your campaign can run.',
              todos: integrationTodos,
            };
          }

          /**
           * Serving State is 'SERVING' but there are still some issues with the integration that appear that the user needs to resolve.
           * Use messaging that reflects that the campaign is running but the integration has issues.
           */
          return {
            status: 'customer-action-required',
            title: 'Your Google Ads Integration Needs Attention',
            description:
              'Your Ads appear to be running but your Google Ads Integration has issues that need to be resolved.',
            todos: integrationTodos,
          };
        }
        /**
         * Default case for Active Ads.
         * We assume if the campaign is in a non-nominal state such logic above would of caught it.
         */
        return {
          status: 'nominal',
          title: 'Your Ads Are Running!',
          description:
            'Your Campaign is serving ads and no problems have been detected.',
          todos: [],
        };
      case 'INITIALIZING':
      case 'ARCHIVED':
      case 'INITIALIZATION_FAILED':
      default:
        return {
          status: 'not-applicable',
          title: 'Health Status Not Available',
          description: 'The health status of this campaign is not available.',
          todos: [],
        };
    }
  }

  pauseCampaign() {
    /**
     * TEMP Implementation.
     * The intent of the notification is to confirm to the user that the pause state has been successfully synced.
     * For the short term we are hard-coding this but ideally this would by synced via the websocket.
     * and look for the sync direction and timestamp of the pause by user state attributes in order to resolve
     * the promise.
     *
     * This is included here as a placeholder to begin to move us in the right direction and give the UI a way to
     * communicate with the user that a sync is in progress. The issue is that it is disconnected from the actual
     * sync process and will not catch failures and could give false positives.
     */
    let deferred = new Deferred();
    setTimeout(() => {
      deferred.resolve({});
    }, 3000);

    this._pausedByUserState = 'CAMPAIGN_PAUSED_BY_USER';
    this._servingState = 'NOT_SERVING';
    this._servingStateReason = 'CAMPAIGN_PAUSED_BY_USER';

    const title = `Pausing Campaign on ${this.primaryIntegrationConfiguration?.integration.name}`;
    this.rootStore.pushNotification({
      titleInProgress: title,
      titleResolved: title,
      titleRejected: title,
      bodyInProgress: `Pausing ${this.name} on ${this.primaryIntegrationConfiguration?.integration.name}`,
      bodyResolved: `${this.name} has been paused on ${this.primaryIntegrationConfiguration?.integration.name}`,
      bodyRejected: `We were unable to pause ${this.name} on ${this.primaryIntegrationConfiguration?.integration.name}`,
      progressPromise: deferred.promise,
    });

    const pausedByUserStateDelta = this.createDelta(
      'CAMPAIGN_PAUSED_BY_USER',
      Campaign.paths.pausedByUserState,
      'replace'
    );

    this._clientDeltas.set(
      Campaign.paths.pausedByUserState,
      pausedByUserStateDelta
    );
    this._rootStore.transport.campaignTransport.enqueue(pausedByUserStateDelta);
  }

  unPauseCampaign() {
    this._pausedByUserState = 'CAMPAIGN_NOT_PAUSED_BY_USER';
    this._servingState = 'SERVING';
    this._servingStateReason = 'UNKNOWN';
    /**
     * Same Note as on the pause campaign method.
     */
    let deferred = new Deferred();
    setTimeout(() => {
      deferred.resolve({});
    }, 3000);
    const title = `Resuming Campaign on ${this.primaryIntegrationConfiguration?.integration.name}`;
    this.rootStore.pushNotification({
      titleInProgress: title,
      titleResolved: title,
      titleRejected: title,
      bodyInProgress: `Resuming  Ads for ${this.name} on ${this.primaryIntegrationConfiguration?.integration.name}`,
      bodyResolved: `${this.name} has been resumes on ${this.primaryIntegrationConfiguration?.integration.name}`,
      bodyRejected: `We were unable to resume ads for ${this.name} on ${this.primaryIntegrationConfiguration?.integration.name}`,
      progressPromise: deferred.promise,
    });

    const pausedByUserStateDelta = this.createDelta(
      'CAMPAIGN_NOT_PAUSED_BY_USER',
      Campaign.paths.pausedByUserState,
      'replace'
    );

    this._clientDeltas.set(
      Campaign.paths.pausedByUserState,
      pausedByUserStateDelta
    );
    this._rootStore.transport.campaignTransport.enqueue(pausedByUserStateDelta);
  }

  get isBudgetDirty(): boolean {
    return this._dailyBudget !== this._dailyBudgetServerValue;
  }

  get dailyBudget(): number {
    return this._dailyBudget;
  }

  set dailyBudget(value: number) {
    this._dailyBudget = value;
  }

  get lifecycleStatus(): CampaignLifecycleStatus {
    return this._lifecycleStatus;
  }

  get rootStore(): RootStore {
    return this._rootStore;
  }

  get latestDeployment(): CampaignDeployment | null {
    return this._latestDeployment;
  }

  get campaignHighlights(): Array<CampaignHighlight> {
    return Array.from(this._campaignHighlights.values());
  }

  get campaignLocations(): Array<CampaignLocation> {
    return Array.from(this._campaignLocations.values());
  }

  get campaignCustomerProfile(): CampaignCustomerProfile | null {
    return this._campaignCustomerProfile;
  }

  get primaryIntegrationConfiguration(): IntegrationXWorkspace | null {
    return (
      this.rootStore.workspaceStore
        .getById(this.workspaceId)
        ?.getIntegrationWithConfigurationBySlug('google-ads-manager') ?? null
    );
  }

  get allCreatives(): Array<Creative> {
    const creatives = new Set<Creative>();
    for (const highlight of this.campaignHighlights) {
      for (const creative of highlight.creatives) {
        creatives.add(creative);
      }
    }
    return Array.from(creatives);
  }

  get isLaunched(): boolean {
    return this.latestDeployment != null;
  }

  get isDirty(): boolean {
    let hasUnpublishedChanges = false;
    Array.from(this._campaignHighlights.values()).forEach((highlight) => {
      if (highlight.hasUnpublishedChanges) {
        hasUnpublishedChanges = true;
      }
    });
    return hasUnpublishedChanges;
  }

  get isLatestDeploymentSuccess(): boolean {
    const latestDeployment = this.latestDeployment;
    if (latestDeployment == null) {
      return false;
    }
    return latestDeployment.stage === 'SUCCEEDED';
  }

  get isLatestDeploymentFailed(): boolean {
    const latestDeployment = this.latestDeployment;
    if (latestDeployment == null) {
      return false;
    }
    return latestDeployment.stage === 'FAILED';
  }

  /**
   * Helper method that inspects the highlights creative and checks if any of them are still generating.
   */
  get isAnyCreativeStillGenerating(): boolean {
    return catchSuspense(() => {
      const campaignHighlights = [...this._campaignHighlights.values()];
      return campaignHighlights.some((highlight) => {
        return highlight.creatives.some((creative) => {
          return creative.lifecycleStatus === 'PENDING_INITIALIZATION';
        });
      });
    });
  }

  /**
   * Helper method that inspects the highlights creative and checks if any of them have errors.
   */
  get isAnyCreativeErrored(): boolean {
    return catchSuspense(() => {
      console.log('Checking if any creative is errored');
      const campaignHighlights = [...this._campaignHighlights.values()];
      return campaignHighlights.some((highlight) => {
        return highlight.creatives.some((creative) => {
          return creative.validationErrors.length > 0;
        });
      });
    });
  }

  get isCreativeReadyToPublish(): boolean {
    console.log('Checking if any campaign is ready to publish');
    return !this.isAnyCreativeErrored && !this.isAnyCreativeStillGenerating;
  }

  get creativeStatusMessage(): string {
    if (this.isAnyCreativeStillGenerating) {
      return 'One or more Creative is Generating. Please wait till all creative have been generated to publish.';
    }

    if (this.isAnyCreativeErrored) {
      return 'One or more Creative has Errors. Please correct the errors before publishing.';
    }

    return 'Your creative is ready to publish!';
  }

  get goal(): OutboundCampaignGoal {
    return this._outboundCampaignGoal;
  }

  /**
   * Discards any unsaved changes to the campaign.
   * Currently the only values that are not realtime save are the daily budget and the name of the campaign.
   */
  discardUnsavedChanges(attributes?: Array<ManuallySyncedAttributes>): void {
    runInAction(() => {
      if (attributes == null) {
        attributes = [...MANUALLY_SYNCED_ATTRIBUTES];
      }

      if (attributes.includes('budget')) {
        /**
         * Restore the daily budget to the last known server value.
         */
        this._dailyBudget = this._dailyBudgetServerValue;
      }

      if (attributes.includes('name')) {
        /**
         * Restore the name to the last known server value.
         */
        this._name = this._nameServerValue;
      }
    });
  }

  /**
   * Sends any un-synced changes to the server.
   */
  save(attributes?: Array<ManuallySyncedAttributes>): void {
    runInAction(() => {
      /**
       * Idea here is let the developer specify what attributes they want to save.
       * In the case they are doing something like editing a name field or the budget
       * individually.
       *
       * If they don't specify any attributes then we will save all the attributes that
       * are not realtime synced and have been changed.
       */
      if (attributes == null) {
        attributes = [...MANUALLY_SYNCED_ATTRIBUTES];
      }

      /**
       * Initial implementation for non-realtime save attributes.
       * Once we have some more experience with this pattern we can refactor this into a more
       * reusable pattern in the base model.
       *
       * Some things I am ignoring here is rollback on failure.
       * I would think that would perhaps happen via the patching mechanism?
       *
       * We would also want to incorporate some sort of user feedback mechanism
       * at a global level to indicate that the save was successful or not
       * such as a toast message or snackbar.
       *
       * When we add this error handling it should happen at the root store level so we only need to implement it once.
       */
      if (
        attributes.includes('budget') &&
        this._dailyBudget !== this._dailyBudgetServerValue
      ) {
        const newValue = this._dailyBudget;
        const dailyBudgetDelta = this.createDelta(
          newValue,
          Campaign.paths.dailyBudget,
          'replace'
        );
        this._clientDeltas.set(Campaign.paths.dailyBudget, dailyBudgetDelta);
        this._rootStore.transport.campaignTransport.enqueue(dailyBudgetDelta);
        this._dailyBudgetServerValue = newValue;
        /**
         * Same Note as on the pause campaign method.
         */
        let deferred = new Deferred();
        setTimeout(() => {
          deferred.resolve({});
        }, 3000);
        /**
         * Adjust the string templates based on if the campaign has already been deployed or not.
         * If we haven't deployed that we are just "Saving" the budget, if we have deployed then we are "Syncing" the budget.
         */
        const title =
          this._latestDeployment != null
            ? `Syncing Budget to ${this.primaryIntegrationConfiguration?.integration.name}`
            : `Saving Budget`;
        this.rootStore.pushNotification({
          titleInProgress: title,
          titleResolved: title,
          titleRejected: title,
          bodyInProgress:
            this._latestDeployment != null
              ? `Update ${this.name} budget on ${this.primaryIntegrationConfiguration?.integration.name}`
              : `Saving Budget for ${this.name}`,
          bodyResolved: `${
            this.name
          } budget has been updated to ${formatCurrency(this._dailyBudget)}${
            this._latestDeployment != null
              ? ` on ${this.primaryIntegrationConfiguration?.integration.name}`
              : ''
          }`,
          bodyRejected: `Unable to update budget for ${this.name}${
            this._latestDeployment != null
              ? ` on ${this.primaryIntegrationConfiguration?.integration.name}`
              : ''
          }`,
          progressPromise: deferred.promise,
        });
      }
      if (attributes.includes('name') && this._name !== this._nameServerValue) {
        const newValue = this._name;
        const nameDelta = this.createDelta(
          newValue,
          Campaign.paths.name,
          'replace'
        );
        this._clientDeltas.set(Campaign.paths.name, nameDelta);
        this._rootStore.transport.campaignTransport.enqueue(nameDelta);
        this._nameServerValue = newValue;
      }
    });
  }

  /**
   * Delete this campaign. This action is not reversible.
   */
  public delete(): void {
    runInAction(() => {
      this._rootStore.campaignStore.delete(this._id);
    });
  }
}

export default Campaign;
